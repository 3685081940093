import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import ActionCreator from '../ActionCreator';
import {Color, FlexRow, FontSize, LineHeight, UnderlineButton} from './Widget';
import * as AppContext from '../Contexts/AppContext';
import {NavActions, Context} from '../Contexts/AppContext';
import * as L from '../Utils/Lang';
import * as SvgIcon from './SvgIcon';
import {StaticImage} from './StaticImage';
import Constants from '../constants';
import {MENU} from '../Templates/ProfilePage/MemberCenterMenu';
import {getPhoneValidator} from '../Utils/validateUtil';

function Footer(props) {
  const {extraStyle = '', showFooter, breakpoints} = props;
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(null);
  const app = useContext(Context);

  const onChange = e => {
    const {value} = e.target;
    const errorMsg = getPhoneValidator().passRequest(value);
    setError(errorMsg ? '請輸入有效的手機號碼' : null);
    setPhone(value);
  };

  if (!showFooter) {
    return null;
  }
  const onSubmit = () => {
    const errorMsg = getPhoneValidator().passRequest(phone);
    setError(errorMsg ? '請輸入有效的手機號碼' : null);
    if (error) {
      return;
    }
    NavActions.navigate(`/login?phone=${phone}`);
  };
  return (
    <Wrapper extraStyle={extraStyle} breakpoints={breakpoints}>
      <Info>
        <Content breakpoints={breakpoints}>
          {!app.currentUser && (
            <div className="become-member">
              <div className="text-highlight" style={{marginBottom: 4}}>
                成為 TUN 會員
              </div>
              <FlexRow style={{marginBottom: 24}}>
                <div className="text-info">享受消費折扣與</div>
                <UnderlineButton
                  onClick={() => {
                    NavActions.navigate(`/profile?active=${MENU.memberLevel}`);
                  }}
                  style={{
                    color: Color.mainWhite_70,
                    lineHeight: 0,
                    padding: 0,
                  }}>
                  會員專屬權益
                </UnderlineButton>
              </FlexRow>
              <div className="input-container-with-error">
                <div className={`input-container ${error ? 'error' : ''}`}>
                  <input
                    className="phone"
                    maxLength={10}
                    placeholder={`${L.s('footer__input_phone')}`}
                    value={phone}
                    onChange={onChange}
                  />
                  <div onClick={onSubmit} className="icon clickable">
                    <SvgIcon.ArrowRight size={32} color={Color.mainWhite_50} />
                  </div>
                </div>
                <div style={{color: Color.red, fontSize: 14}}>{error}</div>
              </div>
            </div>
          )}

          <div className="row">
            <div
              onClick={() => {
                NavActions.navigate('/stores');
              }}
              className="item text-highlight hover-greyout clickable">
              STORE LOCATOR
            </div>
            <div className="item social-icons">
              <div
                onClick={() => {
                  window.open('https://www.facebook.com/TUNGRP/');
                }}
                className="icon hover-greyout clickable">
                <StaticImage path="" name="icon-social-facebook.png" />
              </div>
              <div
                onClick={() => {
                  window.open('https://www.instagram.com/tun_grp/');
                }}
                className="icon hover-greyout clickable">
                <StaticImage path="" name="icon-social-instagram.png" />
              </div>
            </div>
            <div className="item text-highlight hover-greyout clickable">
              iOS App / Android App
            </div>
          </div>
        </Content>

        <Copyright>
          <div>© 2020 TUN Group. All Rights Reserved</div>
          {Constants.env === 'stg' && <div>version - {Constants.version}</div>}
          {/* <div>
              切換語系
                {SupportLangs.map(lang => (
              <div
                key={lang.key}
                onClick={() => appActions.setLang(lang.key)}>
                {lang.display}
              </div>
            ))}
          </div> */}
        </Copyright>
      </Info>
    </Wrapper>
  );
}

let Wrapper = styled.div`
  background-color: ${Color.mainDark};
  width: 100%;
  color: ${Color.mainWhite};

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
  }
  ${props => props.extraStyle};
`;

let Info = styled.div``;

let Content = styled.div`
  max-width: 1212px;
  padding: 56px 36px 36px;
  margin: 0 auto;

  & > .become-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .input-container-with-error {
      margin-bottom: 52px;
      & > .input-container {
        border: 1px solid ${Color.mainWhite};
        width: 320px;
        height: 52px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 4px;

        & > .icon {
          margin-right: 16px;
          cursor: pointer;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }

        & > input.phone {
          padding: 15px 3px 15px 16px;
          flex: 1;
          color: ${Color.mainWhite};
          font-size: ${FontSize.meta}px;
          line-height: ${LineHeight.meta}px;
          background-color: transparent;
          border: none;
          outline: none;
        }

        & > input.phone:focus {
          outline: none;
        }

        & > input.phone::placeholder {
          color: ${Color.mainWhite_50};
        }
      }
      & > .error {
        border: 1px solid ${Color.red};
      }
    }
  }

  & > .row {
    display: flex;

    & > .item {
      flex: 1;
      font-family: Gotham-Medium;
    }

    & > .item.social-icons {
      display: flex;
      justify-content: center;

      & > .icon {
        width: 32px;
        height: 32px;
      }

      & > .icon:first-child {
        margin-right: 16px;
      }
    }
  }

  & .text-highlight {
    color: ${Color.mainWhite};
    text-align: center;
    font-size: ${FontSize.paragraph}px;
    line-height: ${LineHeight.paragraph}px;
  }

  & .hover-greyout:hover {
    opacity: 0.7;
  }

  & .clickable {
    cursor: pointer;
  }

  & .text-info {
    color: ${Color.mainWhite_70};
    text-align: center;
    font-size: ${FontSize.info}px;
    line-height: ${LineHeight.info}px;
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    padding: 40px 20px 16px;
    & > .become-member {
      & > .input-container {
        width: 100%;
      }
    }
    & > .row {
      flex-direction: column;
      & > .item {
        margin-bottom: 20px;
      }
      & > .item.social-icons {
        order: -1;
      }
    }
  }
`;

let Copyright = styled.div`
  color: ${Color.mainWhite_50};
  padding: 20px 0px;
  text-align: center;
  font-size: ${FontSize.meta}px;
  line-height: ${LineHeight.meta}px;
  border-top: 1px solid ${Color.mainWhite_30};
`;

export default AppContext.withConsumer(connect(null, ActionCreator)(Footer));
