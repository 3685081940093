import {MEMBERSHIP_LEVEL, MEMBERSHIP_LEVEL_THRESHOLDS} from '../Domain/Member';
import _ from 'lodash';

function getPromoData({price, promotions = []}) {
  let _sortPromotions = [];
  let next,
    active,
    shouldShowNext = false,
    diffAmount = 0;

  if (promotions.length === 0) {
    next = null;
    diffAmount = 0;
    active = null;
    shouldShowNext = false;
  } else {
    _sortPromotions = promotions
      .slice()
      .sort((a, b) => b.threshold - a.threshold); // max -> min

    const promotionsWithDiffAmount = _sortPromotions.map(p => {
      return {
        ...p,
        diff:
          p.threshold - (price - Math.floor(price / p.threshold) * p.threshold),
      };
    });

    const isAllSame = !promotionsWithDiffAmount.find(
      d => !!promotionsWithDiffAmount.find(b => b.diff !== d.diff),
    );

    next = isAllSame
      ? promotionsWithDiffAmount?.[0]
      : _.minBy(promotionsWithDiffAmount, 'diff');

    diffAmount = next.diff;
    if (next.diff / next.threshold <= 0.2) {
      shouldShowNext = true;
    }
  }

  return {
    next,
    diffAmount,
    active,
    shouldShowNext,
  };
}

function getMemberLevelData({
  price,
  level = 'P',
  memberLevelThresholds = MEMBERSHIP_LEVEL_THRESHOLDS,
  grandTotal,
}) {
  const case1 = () => {
    let next,
      active,
      shouldShowNext = false,
      diffAmount = 0;

    let _sort = memberLevelThresholds
      .slice()
      .sort((a, b) => b.threshold - a.threshold); // max -> min

    let activeLevelIdx = _sort.findIndex(l => price >= l.threshold);

    active =
      activeLevelIdx > -1
        ? {
            ..._sort[activeLevelIdx],
            ...(_sort[activeLevelIdx].gold_rewards &&
            level === MEMBERSHIP_LEVEL.G
              ? {rewards: _sort[activeLevelIdx].gold_rewards}
              : {}),
          }
        : null;

    let nextLevelIdx = _sort?.[activeLevelIdx - 1] ? activeLevelIdx - 1 : null;

    if (nextLevelIdx !== null) {
      next = {
        ..._sort[nextLevelIdx],
        ...(_sort[nextLevelIdx].gold_rewards && level === MEMBERSHIP_LEVEL.G
          ? {rewards: _sort[nextLevelIdx].gold_rewards}
          : {}),
      };
    }

    const levels = Object.values(MEMBERSHIP_LEVEL);

    if (next) {
      if (levels.indexOf(level) >= levels.indexOf(next.key)) {
        shouldShowNext = false;
      } else {
        diffAmount = next.threshold - price;
        if (diffAmount / next.threshold <= 0.2) {
          shouldShowNext = true;
        }
      }
    }
    return {
      next,
      active,
      shouldShowNext,
      diffAmount,
    };
  };
  const case2 = () => {
    let next,
      active,
      shouldShowNext = false,
      diffAmount = 0;

    let _sort = memberLevelThresholds
      .slice()
      .sort(
        (a, b) => b.grandTotalLevelUpThreshold - a.grandTotalLevelUpThreshold,
      ); // max -> min

    let activeLevelIdx = _sort.findIndex(
      l => price + grandTotal >= l.grandTotalLevelUpThreshold,
    );

    active =
      activeLevelIdx > -1
        ? {
            ..._sort[activeLevelIdx],
            ...(_sort[activeLevelIdx].gold_rewards &&
            level === MEMBERSHIP_LEVEL.G
              ? {rewards: _sort[activeLevelIdx].gold_rewards}
              : {}),
          }
        : null;

    let nextLevelIdx = _sort?.[activeLevelIdx - 1] ? activeLevelIdx - 1 : null;

    if (nextLevelIdx !== null) {
      next = {
        ..._sort[nextLevelIdx],
        ...(_sort[nextLevelIdx].gold_rewards && level === MEMBERSHIP_LEVEL.G
          ? {rewards: _sort[nextLevelIdx].gold_rewards}
          : {}),
      };
    }

    const levels = Object.values(MEMBERSHIP_LEVEL);

    if (next) {
      if (levels.indexOf(level) >= levels.indexOf(next.key)) {
        shouldShowNext = false;
      } else {
        diffAmount = next.grandTotalLevelUpThreshold - (price + grandTotal);
        if (diffAmount / next.grandTotalLevelUpThreshold <= 0.2) {
          shouldShowNext = true;
        }
      }
    }
    return {
      next,
      active,
      shouldShowNext,
      diffAmount,
    };
  };

  const case1Data = case1();
  const case2Data = case2();
  if (case1Data.active && case2Data.active) {
    if (case1Data.diffAmount <= case2Data.diffAmount) {
      return case1Data;
    } else {
      return case2Data;
    }
  } else {
    return case2Data.active ? case2Data : case1Data;
  }
}

const getConsumptionRewards = (price, level) => {
  return (
    price * MEMBERSHIP_LEVEL_THRESHOLDS.find(c => c.key === level).reward_ratio
  );
};

export {getPromoData, getMemberLevelData, getConsumptionRewards};
