import styled from 'styled-components';
import {
  Color,
  FlexCol,
  FontSize,
  fstyles,
  SeparateLine,
  UnderlineButton,
} from './Widget';
import CartItem, {CART_ITEM_TYPE} from './cartItem/CartItem';
import RectButton, {BUTTON_SKIN} from './RectButton';
import React, {Fragment, useContext, useMemo, useEffect} from 'react';
import {Actions, Context, NavActions} from '../Contexts/AppContext';
import {getFormatPrice} from '../Utils/ProductUtil';
import {getPromoData} from '../Utils/PromotionsUtil';
import * as L from '../Utils/Lang';
import useClickOutside from '../Hooks/useClickOutside';

const ShadowLabel = styled.div`
  width: 100%;
  text-align: center;
  padding: 6px 0;
  color: #141414;
  ${fstyles.text12}
`;

const PriceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${fstyles.text12}
  & .field {
    color: ${Color.mainDark_70};
  }
  & .price {
    color: ${Color.mainDark};
    font-weight: bold;
  }
`;

const MiniCart = props => {
  const {cart} = props;
  const app = useContext(Context);
  const [promoData, setPromoData] = React.useState(null);
  const ref = React.useRef();
  const _onClickOutside = () => {
    app.showMiniCart && Actions.setShowMiniCart(false);
  };
  useClickOutside(ref.current, _onClickOutside);

  useEffect(() => {
    const _fetchData = async () => {
      let _promotions = await Actions.getPromotions();
      let data = getPromoData({
        price: cart.price_detail.subtotal,
        promotions: _promotions,
      });
      setPromoData(data);
    };
    if (cart) {
      _fetchData();
    }
  }, [cart, setPromoData]);

  const isLogin = !!app.currentUser;

  const totalUsedPoints = useMemo(() => {
    return app.isUsePoints
      ? app.points.useBirthGiftPoints + app.points.useRebatePoints
      : 0;
  }, [
    app.isUsePoints,
    app.points.useBirthGiftPoints,
    app.points.useRebatePoints,
  ]);

  if (!cart) {
    return null;
  }

  return (
    <Wrapper ref={ref} onClick={e => e.stopPropagation()}>
      <FlexCol
        style={{alignItems: 'center', padding: '20px', minWidth: '366px'}}>
        {cart.items.length <= 0 && (
          <Fragment>
            <div className="text" style={{margin: '40px 0'}}>
              <div>您的購物車中沒有商品{!isLogin && <span>，</span>}</div>
              {!isLogin && <div>登入後即可查看之前加入的商品！</div>}
            </div>
            {!isLogin && (
              <RectButton
                text="立即登入"
                skin={BUTTON_SKIN.PRIMARY}
                style={{margin: '12px 20px', width: '100%'}}
                onClick={e => {
                  e.stopPropagation();
                  NavActions.navigate('/login');
                }}
              />
            )}
          </Fragment>
        )}

        <Fragment>
          {cart.items.length > 0 && (
            <Fragment>
              <FlexCol
                style={{
                  width: '100%',
                  maxHeight: '404px',
                  overflowY: 'auto',
                  alignItems: 'center',
                }}>
                {!isLogin && (
                  <ShadowLabel
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.06)',
                      color: 'rgb(0,0,0)',
                    }}>
                    已經是會員？
                    {
                      <UnderlineButton
                        onClick={e => {
                          NavActions.navigate('/login');
                        }}
                        stlye={{fontSize: FontSize.mini}}>
                        立即登入
                      </UnderlineButton>
                    }
                    保存購物清單！
                  </ShadowLabel>
                )}
                {promoData?.next && promoData.shouldShowNext && (
                  <ShadowLabel
                    style={{
                      backgroundColor: 'rgba(204, 167, 92, 0.06)',
                      margin: '4px 0 8px 0',
                      color: 'rgb(204, 167, 92)',
                    }}>
                    差 {getFormatPrice(promoData.diffAmount)} 即符合{' '}
                    {L.d(promoData.next.title)}
                  </ShadowLabel>
                )}
                {cart.items.map((item, index) => (
                  <CartItem
                    style={{width: '100%', margin: '20px 0'}}
                    key={index}
                    type={CART_ITEM_TYPE.MINI}
                    item={item}
                  />
                ))}
              </FlexCol>
              <SeparateLine />
              <PriceInfo>
                <div className="field">小計</div>
                <div className="price">NT {getFormatPrice(cart.subtotal)}</div>
              </PriceInfo>
              {cart?.price_detail.target_price_discount_value !== 0 && (
                <PriceInfo>
                  <div className="field">
                    {L.d(cart?.price_detail.target_price_discount_title)}
                  </div>
                  <div className="price red">
                    - NT{' '}
                    {getFormatPrice(
                      cart?.price_detail.target_price_discount_value,
                    )}
                  </div>
                </PriceInfo>
              )}

              {totalUsedPoints > 0 && (
                <PriceInfo>
                  <div className="field">購物金折抵</div>
                  <div className="price red">
                    - NT {getFormatPrice(totalUsedPoints)}
                  </div>
                </PriceInfo>
              )}

              <SeparateLine />
              <PriceInfo style={{justifyContent: 'flex-end'}}>
                <div
                  style={{color: Color.mainDark, marginRight: 8}}
                  className="field">
                  總金額
                </div>
                <div style={{fontSize: FontSize.paragraph}} className="price">
                  NT {getFormatPrice(cart.price_detail.price)}
                </div>
              </PriceInfo>
              <RectButton
                text="結帳"
                skin={BUTTON_SKIN.PRIMARY}
                style={{margin: '12px 20px', width: '100%'}}
                onClick={() => {
                  NavActions.navigate('/cart');
                  app.showMiniCart && Actions.setShowMiniCart(false);
                }}
              />
            </Fragment>
          )}
        </Fragment>

        {cart.items.length > 0 && (
          <UnderlineButton
            style={{marginTop: 5}}
            onClick={() => {
              NavActions.navigate('/cart');
              app.showMiniCart && Actions.setShowMiniCart(false);
            }}>
            查看購物車
          </UnderlineButton>
        )}
      </FlexCol>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .text {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: ${Color.mainDark};
  }
  .red {
    color: #e22828;
  }
`;

export default MiniCart;
