import React, {useRef} from 'react';
import {Color} from './Widget';
import styled from 'styled-components';
import {ChevronLeft, ChevronRight} from './SvgIcon';

export const ProductItemShadow = props => {
  const {itemWidth, groupVariants} = props;
  const colors = groupVariants.colors.map(color => color.color);
  const sizes = groupVariants.sizes;

  return (
    <div className="shadow">
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <ColorChips colors={colors} />
        <div className="filler" style={{flex: 1}} />
        <SizeCarousel sizes={sizes} itemWidth={itemWidth} />
      </div>
    </div>
  );
};

const ColorChips = props => {
  const {colors} = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: 16,
        alignSelf: 'flex-start',
      }}>
      {colors.map((color, index) => (
        <div
          key={index}
          style={{
            backgroundColor: color,
            width: 16,
            height: 16,
            borderRadius: '100%',
            margin: '0 6px',
            border: `1px solid ${Color.mainDark_70}`,
          }}
        />
      ))}
    </div>
  );
};
const SizeCarousel = props => {
  const {itemWidth, sizes} = props;
  const isOneSize = sizes.length === 1;
  const sliderRef = useRef();
  const scrollUnit = 20;

  const next = () => {
    sliderRef.current.scrollLeft = sliderRef.current.scrollLeft + scrollUnit;
  };

  const previous = () => {
    sliderRef.current.scrollLeft = sliderRef.current.scrollLeft - scrollUnit;
  };

  return (
    <SizeCarouselWrapper
      isOneSize={isOneSize}
      onClick={e => {
        e.stopPropagation();
      }}
      itemWidth={itemWidth}>
      <ChevronLeft color={Color.mainWhite} className="btn" onClick={previous} />
      <div ref={sliderRef} className="slider">
        {sizes.map((size, index) => {
          return (
            <div className="size" key={index}>
              {size}
            </div>
          );
        })}
      </div>
      <ChevronRight color={Color.mainWhite} className="btn" onClick={next} />
    </SizeCarouselWrapper>
  );
};
const SizeCarouselWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 12px 0;
  width: ${props => props.itemWidth}px;
  color: ${Color.mainWhite};
  & > .btn {
    visibility: ${props => (props.isOneSize ? 'hidden' : 'normal')};
  }
  & > .slider {
    display: flex;
    align-items: center;
    max-width: ${props => props.itemWidth - 100}px;
    overflow-x: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    & > .size {
      margin: 0 14px;
      font-weight: 500;
      font-size: 14px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
`;
