import {useDimension} from '../../Hooks/AppHooks';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Actions, NavActions} from '../../Contexts/AppContext';
import useCalcProductGridLayout from '../../Utils/useCalcProductGridLayout';
import {brandsArr} from '../../Domain/Brand';
import {Color, FlexCol, FlexRowCenter, FontSize} from '../Widget';
import TextInput from '../../Templates/ProfilePage/TextInput';
import * as SvgIcon from '../SvgIcon';
import ProductGrid from '../ProductGrid';
import {SearchList} from './SearchList';
import styled from 'styled-components';
import _ from 'lodash';

export const SearchArea = () => {
  const {mobile} = useDimension();
  const [keyword, setKeyword] = useState('');
  const [searchData, setSearchData] = useState(null);

  const onChange = async e => {
    const {value} = e.target;
    setKeyword(value);
    const regExp = /^[\u4e00-\u9fa50-9a-zA-Z]+$/;

    if (value.trim() === '') {
      setSearchData(null);
      return;
    }
    if (!regExp.test(value)) {
      return;
    }

    onKeywordChange(value);
  };

  const onKeywordChange = useMemo(
    () =>
      _.debounce(async value => {
        const _searchData = await Actions.fetchSearchData(value);
        setSearchData(_searchData);
      }, 1000),
    [],
  );

  const onSubmit = async () => {
    NavActions.navigate(
      `/search?keyword=${keyword}&brand_id=0&collection_id=0`,
    );
    Actions.setGlobalSlideDownContent(null);
  };

  const isSearchEmpty = searchData?.brands.length <= 0 || !searchData;

  return (
    <Wrapper mobile={mobile}>
      <FlexRowCenter className="input-area">
        <TextInput
          type="search"
          styleCss={
            '& > .container { display: flex; align-items: center; width: 100%;}'
          }
          style={{
            flex: 1,
            alignItems: 'center',
            display: 'flex',
            maxWidth: mobile ? 'unset' : '656px',
            margin: mobile ? '14px 10px' : '0 10px',
          }}
          value={keyword}
          onChange={onChange}
          placeholder="在 TUN 搜尋"
          onKeyUp={e => {
            const isEnter = e.keyCode === 13;
            if (isEnter) {
              onSubmit();
            }
          }}
          icons={{
            left: <SvgIcon.Search style={{marginBottom: '12px'}} />,
            right: (
              <SvgIcon.ArrowRight
                onClick={onSubmit}
                style={{marginBottom: '12px', cursor: 'pointer'}}
              />
            ),
          }}
        />
        {mobile && (
          <span
            style={{marginBottom: '12px'}}
            onClick={() => {
              Actions.setGlobalSlideDownContent(null);
            }}>
            取消
          </span>
        )}
      </FlexRowCenter>
      {searchData?.brands.length > 0 && (
        <SearchResult keyword={keyword} searchData={searchData} />
      )}
      {isSearchEmpty && <SearchNoResultRecommendProducts />}
    </Wrapper>
  );
};

export const SearchResult = ({keyword, searchData}) => {
  const brandCategories =
    searchData?.brands.reduce((acc, cur) => {
      return acc.concat(
        cur.collections.map(c => ({
          ...c,
          brandTitle: cur.title,
          brandId: cur.id,
        })),
      );
    }, []) || [];

  return (
    <div
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <FlexCol>
        <div style={{fontWeight: 'bold'}}>品牌</div>
        <SearchList
          brand={{title: 'All Brands', id: 0}}
          keyword={keyword}
          amount={searchData.all_brands_products_count}
        />
        {searchData.brands.map((b, index) => {
          return (
            <SearchList
              key={index}
              brand={{
                title: b.title,
                id: b.id,
              }}
              keyword={keyword}
              amount={b.brand_products_count}
            />
          );
        })}
      </FlexCol>
      <FlexCol>
        <div style={{fontWeight: 'bold'}}>商品類別</div>
        {brandCategories.map((c, index) => {
          return (
            <SearchList
              key={index}
              brand={{
                title: c.brandTitle,
                id: c.brandId,
              }}
              collection={{title: c.title, id: c.id}}
              keyword={keyword}
              category={{title: c.category.title, id: c.category.id}}
              amount={c.collection_products_count}
            />
          );
        })}
      </FlexCol>
    </div>
  );
};

export const SearchNoResultRecommendProducts = () => {
  const productGridLayoutRef = useRef(null);
  const {itemWidth, itemPerRow} = useCalcProductGridLayout(
    productGridLayoutRef.current?.offsetWidth,
  );
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const _fetchRecommendProducts = async () => {
      const _products = await Promise.all(
        brandsArr.map(brand => {
          return Actions.fetchProducts({
            brand,
            offset: 0,
            collectionId: [],
            sort: '-created',
            filter: [],
            limit: 4,
          });
        }),
      );
      setProducts(_products);
    };
    _fetchRecommendProducts();
  }, []);

  if (products.length <= 0) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      ref={productGridLayoutRef}>
      <div
        style={{
          color: Color.mainDark_70,
          marginBottom: '80px',
        }}>
        無搜尋結果，請輸入其他關鍵字，或查看我們的推薦商品
      </div>
      <div
        style={{
          fontSize: FontSize.highlight,
          fontWeight: 'bold',
          marginBottom: '40px',
        }}>
        YOU MAY LIKE
      </div>
      {brandsArr.map((b, index) => {
        return (
          <ProductGrid
            key={index}
            extraStyle={{maxWidth: 1440}}
            itemWidth={itemWidth}
            itemPerRow={itemPerRow}
            products={products[index].products}
          />
        );
      })}
    </div>
  );
};
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  & > .input-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-bottom: 24px;
    & > .input {
      flex: 1;
      align-items: center;
      display: flex;
      max-width: ${props => (props.mobile ? 'unset' : '656px')};
      margin: ${props => (props.mobile ? '14px 10px' : '0 10px')};
    }
  }
`;
