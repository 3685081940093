const goTo = (linkTo, navActions) => {
  const {type, value} = linkTo;
  switch (type) {
    case 'collections':
      navActions.navigate(`/products?collection_id=${value}`);
      break;
    case 'products':
      navActions.navigate(`/product?id=${value}`);
      break;
    case 'series':
      navActions.navigate(`/series?id=${value}`);
      break;
    case 'event_pages':
      navActions.navigate(`/event?id=${value}`);
      break;
    case 'about':
      navActions.navigate(value);
      break;
    default:
      if (typeof value === 'string' && value.indexOf('https://') !== -1) {
        window.open(value);
      }
      break;
  }
};

export {goTo};
