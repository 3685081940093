const {RETURN_STATUS_TRANSFORM, RETURN_STATUS} = require('./Domain/Return');
const {MEMBERSHIP_LEVEL, MEMBER_SOURCE} = require('./Domain/Member');
const {brandNameToBrandId, brandIdToBrandName} = require('./Domain/Brand');
const creditCardType = require('credit-card-type');
const {
  INVOICE_TYPE,
  SUBORDER_SHIPPING_STATUS_TRANSFORM,
  ORDER_STATUS_TRANSFORM,
  SUBORDER_SHIPPING_STATUS,
  ORDER_STATUS_TRANSFORM_REVERSE,
  ORDER_STATUS,
  SHIPPING_TYPE,
} = require('./Domain/Order');
const ApiProxyFactory = require('./ApiProxy');
const constants = require('./constants');
const ApiProxy = new ApiProxyFactory({apiUrl: constants.apiUrl});
const _ = require('lodash');
const parser = require('fast-xml-parser');

const _mapVipLevelValue = level => {
  switch (level) {
    case 'D':
      return MEMBERSHIP_LEVEL.D;
    case 'G':
      return MEMBERSHIP_LEVEL.F;
    case 'F':
      return MEMBERSHIP_LEVEL.G;
    case 'M':
    default:
      return MEMBERSHIP_LEVEL.M;
  }
};

const parseXMLToJson = xml => {
  return !parser.validate(xml) ? null : parser.parse(xml);
};

class ApiAdapter {
  constructor({host}) {
    this.host = host;
  }

  async fetchDemo() {
    const json = await ApiProxy.get({path: `/api/list`});
    return json.data;
  }

  async getBrandHomeData(brand) {
    const data = await ApiProxy.get({
      path: `/v1/home_page?brand_id=${brandNameToBrandId[brand]}`,
    });

    const news = await ApiProxy.get({
      path: `/v1/information?brand_id=${brandNameToBrandId[brand]}&limit=5&offset=0`,
    });

    const {
      collection_background_image,
      carousels,
      top_banner,
      shop_the_look,
      banners,
      about_tun_image,
      about_tun_video,
      about_brand_image,
      about_brand_video,
      collection_background_video,
    } = data;

    return {
      promotion: top_banner || null,
      banners: carousels.map(item => ({
        ...item,
        title: item.title || 'NEW ARRIVAL',
        image: item.image.original,
        image_tablet: item.image.mobile,
        buttons: item.buttons.map(b => ({
          ...b,
          content: b.text,
          link_to: b.link_to,
        })),
        video: null, // item.video_url,
      })),
      shop_items: shop_the_look?.products.map(item => ({
        id: item.product.id,
        images: item.images,
        buttons: [
          {
            content: '前往探索',
            link_to: {value: item.product.id, type: 'products'},
          },
        ],
      })),
      categories: [], // will be append by AppContext
      sections: banners.map(b => ({
        ...b,
        id: b.id,
        title: b.title || 'default title',
        image: b.image.original,
        image_m: b.image.original,
        image_s: b.image.mobile,
        buttons: b.buttons.map(button => ({
          ...button,
          content: button.text,
          link_to: button.link_to,
        })),
        video: null, // b.video_url,
      })),
      collection: {
        image: collection_background_image.original,
        image_m: collection_background_image.original,
        image_s: collection_background_image.mobile,
        video: null, // collection_background_video,
      },
      news: news.informations.map(item => ({
        id: item.id,
        banner: item.image,
        title: item.title,
        description: item.content,
        date: item.start_date,
        buttons: item.buttons,
      })),
      about: {
        banners: [
          {
            title: 'ABOUT THE BRAND',
            image: about_brand_image.original,
            image_tablet: about_brand_image.mobile,
            video: null, // about_brand_video,
            buttons: [
              {
                content: '關於品牌',
                link_to: {
                  type: 'about',
                  value: '/aboutTheBrand',
                },
              },
            ],
          },
          {
            title: 'ABOUT TUN GROUP',
            image: about_tun_image.original,
            image_tablet: about_tun_image.mobile,
            video: null, // about_tun_video,
            buttons: [
              {
                content: '關於 TUN',
                link_to: {
                  type: 'about',
                  value: '/aboutTheTun',
                },
              },
            ],
          },
          // FIXME: @guychienll future feature comment first
          //{
          //title: 'ABOUT THE SERVICE',
          //image: data.service_image.original,
          //image_tablet: data.service_image.mobile,
          //buttons: [
          //{
          //content: '服務',
          //link_to: {
          //type: 'about',
          //value: '/aboutTheService',
          //},
          //},
          //],
          //},
        ],
      },
    };
  }

  async getBrandCategories(brand) {
    const brandId = brandNameToBrandId[brand];
    const data = await ApiProxy.get({
      path: `/v1/categories?brand_id=${brandId}`,
    });

    return data;
  }

  async getStores(brand) {
    const data = await ApiProxy.get({
      path: `/v1/stores?brand_id=${brandNameToBrandId[brand]}`,
    });
    const _convertStoresData = data => {
      return data.map(d => ({
        ...d,
        id: d.id,
        name: d.title,
        address: d.address,
        tel: d.phone,
        lat: d.lat,
        lng: d.lng,
        liveChatUrl: d.instant_messaging_token,
        district: d.area,
        openTime: d.opening_hours,
      }));
    };

    return _convertStoresData(data);
  }

  async fetchProductById(id) {
    const data = await ApiProxy.get({
      path: `/v1/products/${id}`,
    });

    const _convert = data => {
      const {variants, styled_with_products, recommend_products} = data;

      const _convertedStyleWithProducts = styled_with_products.map(p => ({
        ...p,
        image: p.images[0]?.url?.url,
      }));

      const _convertedRecommendProducts = recommend_products.map(p => {
        return {
          ...p,
          image: p.variants[0]?.images?.[0],
        };
      });

      return {
        ...data,
        images:
          variants.length > 0 && variants[0]
            ? variants[0].images.map(url => ({image: url}))
            : [],
        style_with_products: [..._convertedStyleWithProducts],
        recommend_products: [..._convertedRecommendProducts],
      };
    };

    return _convert(data);
  }

  async fetchProducts({
    brand,
    filter,
    sort,
    offset,
    limit,
    categoryIds,
    collectionId,
    subCollectionIds,
    seriesId,
    keyword,
  }) {
    const search = filter.reduce((acc, cur) => {
      const key = Object.keys(cur)[0];
      acc[key] = cur[key];
      return acc;
    }, {});

    const data = await ApiProxy.post({
      path: '/v1/products',
      data: {
        brand_id: brandNameToBrandId[brand],
        offset,
        limit,
        category_ids: categoryIds,
        collection_ids: collectionId,
        sub_collection_ids: subCollectionIds,
        sort_by: sort,
        search,
        series_id: seriesId,
        keyword,
      },
    });

    return {
      products: [
        ...data.products.map(product => {
          const specialVariant = product.variants.find(
            variant => variant.id === product.specified_variant_id,
          );
          return {
            ...product,
            name: product.title,
            image: specialVariant.images[0],
            collectionId: product.collection_id,
          };
        }),
      ],
      filters: [...data.filters],
      total_count: data.total_count,
    };
  }

  async fetchSearchData(keyword) {
    return await ApiProxy.get({
      path: `/v1/search?keyword=${keyword}`,
    });
  }

  async getCustomerExist(phone) {
    const data = await ApiProxy.get({
      path: `/v1/customers/exist?phone=${phone}`,
    });
    return data.exist;
  }

  async login(loginSet) {
    const {phone, password} = loginSet;
    const token = await ApiProxy.post({
      path: `/oauth/token`,
      data: {
        username: phone,
        password: password,
        grant_type: 'password',
        client_id: constants.login_client_id,
        client_secret: constants.login_client_secret,
        scope: 'read',
      },
    });
    const {token_type, access_token} = token;
    ApiProxy.setToken(`${token_type} ${access_token}`);
    window.localStorage.setItem('token', `${token_type} ${access_token}`);

    return token;
  }

  getToken() {
    const token = window.localStorage.getItem('token');
    ApiProxy.setToken(token);
    return token;
  }

  clearToken() {
    window.localStorage.removeItem('token');
    ApiProxy.setToken('');
    return '';
  }

  async genOtp(phone) {
    return await ApiProxy.post({
      path: '/v1/customers/otp',
      data: {
        phone,
      },
    });
  }

  async validateOtp({phone, otp}) {
    return await ApiProxy.post({
      path: '/v1/customers/validate_otp',
      data: {
        phone,
        otp,
      },
    });
  }

  async register(customerInfo) {
    const {username, password, birthdate, email, phone} = customerInfo;
    return await ApiProxy.post({
      path: '/v1/customers',
      data: {
        phone,
        email,
        password,
        birthdate,
        name: username,
        source: MEMBER_SOURCE.web,
      },
    });
  }

  async fetchMe() {
    let resp = await ApiProxy.get({
      path: '/v1/customers/me',
      secure: 'true',
    });

    return {
      ...resp,
      sex: resp.sex,
      gender: resp.sex, // FIXME: mostly our ui use 'gender'...
      vip_level: _mapVipLevelValue(resp.vip_level),
    };
  }

  async resetPassword({phone, password, otp}) {
    const res = await ApiProxy.post({
      path: '/v1/customers/reset_password',
      data: {
        phone,
        otp,
        new_password: password,
      },
    });

    return res;
  }

  logout() {
    window.localStorage.removeItem('token');
    ApiProxy.setToken(null);
  }

  async fetchCart({useBirthGift, useRebatePoints}) {
    const userToken = this.getToken();
    const anonymousCartToken = userToken
      ? null
      : window.localStorage.getItem('anonymousCartToken');
    const res = await ApiProxy.get({
      path: anonymousCartToken
        ? `/v1/carts?token=${anonymousCartToken}`
        : `/v1/carts?use_birth_gift=${useBirthGift}&use_rebate_points=${useRebatePoints}`,
      secure: true,
    });
    if (!userToken) {
      window.localStorage.setItem('anonymousCartToken', res.token);
    } else {
      window.localStorage.removeItem('anonymousCartToken');
    }
    return res;
  }

  async updateCart({items, useRebatePoints, useBirthGift}) {
    const userToken = this.getToken();
    const res = await ApiProxy.post({
      path: '/v1/carts',
      secure: true,
      data: {
        items,
        token: userToken
          ? null
          : window.localStorage.getItem('anonymousCartToken'),
        use_birth_gift: useBirthGift,
        use_rebate_points: useRebatePoints,
      },
    });

    if (!userToken) {
      window.localStorage.setItem('anonymousCartToken', res.token);
    } else {
      window.localStorage.removeItem('anonymousCartToken');
    }
    return res;
  }

  async fetchSeries({seriesId}) {
    const data = await ApiProxy.get({
      path: `/v1/series/${seriesId}`,
    });

    const {series_sections, image, title, subtitle} = data;
    const allFilterOpts = series_sections.map(section => section.filters);
    const filterOptsUnion = allFilterOpts.reduce(
      (acc, cur) => _.merge(acc, cur),
      [],
    );

    const collections = series_sections.map(section => ({
      ...section,
      description: section.subtitle,
      image: section.image.landscape,
      products: section.products.map(product => ({
        ...product,
        name: product.title,
        image: product.variants[0].images,
      })),
    }));

    return {
      brand: {
        image: image,
        description: subtitle,
        title,
      },
      filterOptions: filterOptsUnion,
      collections,
    };
  }

  async fetchAllSeries(brandId) {
    return await ApiProxy.get({
      path: `/v1/series?brand_id=${brandId}`,
    });
  }

  async fetchOrders({limit, offset, status}) {
    const reverse_status = ORDER_STATUS_TRANSFORM_REVERSE[status];
    const resp = await ApiProxy.get({
      path: `/v1/orders?offset=${offset}&limit=${limit}&status=${
        reverse_status ? reverse_status : ''
      }`,
      secure: true,
    });

    const orders = resp.orders.map(order => {
      return {
        ...order,
        id: order.number,
        order_no: order.number,
        created: order.created_at,
        status: ORDER_STATUS_TRANSFORM[order.status],
        config: {
          payment: {
            type: 'credit_card',
            first_6_digits: order.credit_card_first_six,
            last_4_digits: order.credit_card_last_four || '----',
            credit_card_type: order.credit_card_first_six
              ? creditCardType(order.credit_card_first_six)[0].type
              : null,
            installment: order.installment,
          },
          invoice: {
            type: order.e_gui_tax_number
              ? INVOICE_TYPE.company
              : INVOICE_TYPE.person,
            subtype: order.e_gui_type,
            invoice_carrier: order.e_gui_carrier,
            invoice_tax: order.e_gui_tax_number,
          },
          shipping: {
            receiver: order.receiver_name,
            receiver_phone: order.receiver_phone,
            pick_up_address: {
              type: order.shipping_type,
              city: order.city,
              cvs_number: order.cvs_number,
              name: order.cvs_store_name,
              cvs_store_type: order.cvs_store_type,
              district: order.district,
              address: order.address,
            },
          },
        },
        calculations: {
          total: order.price,
          subtotal: order.original_price,
          redeem_credit: order.used_rebate,
          reward: order.gained_rebat,
          shipping_cost: 0, //always 0
          extra_discounts: {
            title: order.target_price_discount_title,
            amount: order.target_price_discount_value,
          },
          membership_reward_percent: Number(order.membership_reward_percent),
          membership_level: _mapVipLevelValue(order.vip_level),
        },
        sub_orders: order.sub_orders.map(subOrder => ({
          ...subOrder,
          brand_name: brandIdToBrandName[subOrder.brand_id],
          shippingStatus:
            SUBORDER_SHIPPING_STATUS_TRANSFORM[subOrder.shipping_status],
          store: subOrder.store,
          logistic: {
            logistic_id: subOrder.tracking_number
              ? subOrder.tracking_number
              : '',
          },
          shippingStatusHistory: {
            [SUBORDER_SHIPPING_STATUS.preparing]: subOrder.created_at,
            [SUBORDER_SHIPPING_STATUS.received]: subOrder.received_at,
            [SUBORDER_SHIPPING_STATUS.delivered]: subOrder.arrived_at,
            [SUBORDER_SHIPPING_STATUS.shipped]: subOrder.shipped_at,
            [SUBORDER_SHIPPING_STATUS.failed]: subOrder.failed_at,
          },
          items: subOrder.order_items.map(item => ({
            ...item,
          })),
        })),
      };
    });

    const order_status_count = {
      [ORDER_STATUS.processing]: resp.order_status_count.processing ?? 0,
      [ORDER_STATUS.canceled]: resp.order_status_count.canceled ?? 0,
      [ORDER_STATUS.unpaid]: resp.order_status_count.unpaid ?? 0,
      [ORDER_STATUS.waiting]: resp.order_status_count.waiting ?? 0,
      [ORDER_STATUS.done]: resp.order_status_count.done ?? 0,
      [ORDER_STATUS.returned]: resp.order_status_count.returned ?? 0,
    };

    return {
      orders,
      order_status_count,
      offline_orders_count: resp.offline_orders || 0,
    };
  }

  async fetchOrder(id) {
    const order = await ApiProxy.get({
      path: `/v1/orders/${id}`,
      secure: true,
    });

    return {
      ...order,
      id: order.number,
      order_no: order.number,
      created: order.created_at,
      status: ORDER_STATUS_TRANSFORM[order.status],
      config: {
        payment: {
          type: 'credit_card',
          first_6_digits: order.credit_card_first_six,
          last_4_digits: order.credit_card_last_four || '----',
          credit_card_type: order.credit_card_first_six
            ? creditCardType(order.credit_card_first_six)[0].type
            : null,
          installment: order.installment,
        },
        invoice: {
          type: order.e_gui_tax_number
            ? INVOICE_TYPE.company
            : INVOICE_TYPE.person,
          subtype: order.e_gui_type,
          invoice_carrier: order.e_gui_carrier,
          invoice_tax: order.e_gui_tax_number,
        },
        shipping: {
          receiver: order.receiver_name,
          receiver_phone: order.receiver_phone,
          pick_up_address: {
            type: order.shipping_type,
            city: order.city,
            name: order.cvs_store_name,
            cvs_number: order.cvs_number,
            cvs_store_type: order.cvs_store_type,
            district: order.district,
            address: order.address,
          },
        },
      },
      calculations: {
        total: order.price,
        subtotal: order.original_price,
        redeem_credit: order.used_rebate + order.used_birth_gift,
        reward: order.gained_rebate,
        shipping_cost: 0, //always 0
        extra_discounts: {
          title: order.target_price_discount_title,
          amount: order.target_price_discount_value,
        },
        membership_reward_percent: Number(order.membership_reward_percent),
        membership_level: _mapVipLevelValue(order.vip_level),
      },
      sub_orders: order.sub_orders.map(subOrder => ({
        ...subOrder,
        brand_name: brandIdToBrandName[subOrder.brand_id],
        shippingStatus:
          SUBORDER_SHIPPING_STATUS_TRANSFORM[subOrder.shipping_status],
        store: subOrder.store,
        logistic: {
          logistic_id: subOrder.tracking_number ? subOrder.tracking_number : '',
        },
        shippingStatusHistory: {
          [SUBORDER_SHIPPING_STATUS.preparing]: subOrder.created_at,
          [SUBORDER_SHIPPING_STATUS.received]: subOrder.received_at,
          [SUBORDER_SHIPPING_STATUS.delivered]: subOrder.arrived_at,
          [SUBORDER_SHIPPING_STATUS.shipped]: subOrder.shipped_at,
          [SUBORDER_SHIPPING_STATUS.failed]: subOrder.failed_at,
        },
        items: subOrder.order_items.map(item => ({
          ...item,
        })),
      })),
    };
  }

  async getMyCreditCards() {
    return await ApiProxy.get({
      path: '/v1/customers/credit_cards',
    });
  }

  async checkout(data) {
    return await ApiProxy.post({
      path: '/v1/orders',
      data,
    });
  }

  async cancelOrder(id) {
    return await ApiProxy.post({
      path: `/v1/orders/${id}/cancel`,
      secure: true,
    });
  }

  async getBindCreditCardForm(path) {
    return await ApiProxy.post({
      path: `/v1/customers/credit_cards`,
      data: {
        client_back_url: `${constants.creditCardCallbackHost}${path}`,
      },
    });
  }

  async getAllCounties() {
    try {
      const resp = await fetch('https://api.nlsc.gov.tw/other/ListCounty', {
        method: 'GET',
      });
      const xml = await resp.text();
      const json = parseXMLToJson(xml);
      return json.countyItems.countyItem.map(item => ({
        county_name: item.countyname,
        county_code: item.countycode,
      }));
    } catch (err) {
      throw err;
    }
  }

  async getAllTowns(counties) {
    try {
      const promises = counties.map(async county =>
        (
          await fetch(`https://api.nlsc.gov.tw/other/ListTown/${county}`, {
            method: 'GET',
          })
        ).text(),
      );

      const resp = await Promise.all(promises);

      const json = resp.map(towns => parseXMLToJson(towns));

      const data = json.reduce((acc, cur) => {
        const townItem = cur.townItems.townItem;
        if (Array.isArray(townItem)) {
          acc[townItem[0].towncode[0]] = townItem;
        } else {
          acc[townItem.towncode[0]] = [townItem];
        }
        return acc;
      }, {});

      return data;
    } catch (err) {
      throw err;
    }
  }

  async validatePassword(password) {
    return await ApiProxy.post({
      path: `/v1/customers/validate_password`,
      data: {password},
      secure: true,
    });
  }

  async updateCustomer(customer) {
    return await ApiProxy.post({
      path: `/v1/customers/update`,
      data: {
        ...customer,
      },
      secure: true,
    });
  }

  async updatePassword({oldPass, newPass, confirmNewPass}) {
    return await ApiProxy.post({
      path: `/v1/customers/update_password`,
      data: {
        old_password: oldPass,
        new_password: newPass,
        new_password_confirmation: confirmNewPass,
      },
      secure: true,
    });
  }

  async fetchNewsList({brand, limit, offset}) {
    const resp = await ApiProxy.get({
      path: `/v1/information?brand_id=${brandNameToBrandId[brand]}&limit=${limit}&offset=${offset}`,
    });

    return {
      ...resp,
      informations: resp.informations.map(news => ({
        ...news,
        description: news.content,
      })),
    };
  }

  async fetchNews(id) {
    const resp = await ApiProxy.get({
      path: `/v1/information/${id}`,
    });

    return {
      ...resp,
      date: resp.start_date,
      description: resp.content,
    };
  }

  async fetchPointHistory() {
    return await ApiProxy.get({
      path: '/v1/customers/point_history',
      secure: true,
    });
  }

  async getPromotions() {
    return await ApiProxy.get({
      path: '/v1/target_price_discounts',
      secure: false,
    });
  }

  async setDefaultCard(id) {
    return await ApiProxy.post({
      path: '/v1/customers/credit_cards/set_primary',
      data: {card_id: id},
      secure: true,
    });
  }

  async deleteCard(id) {
    return await ApiProxy.delete({
      path: `/v1/customers/credit_cards/${id}`,
      secure: true,
    });
  }

  async fetchMyAddrs() {
    const _resp = await ApiProxy.get({
      path: '/v1/customer/addresses',
      secure: true,
    });

    return _resp.map(addr => ({
      id: addr.id,
      receiverName: addr.receiver_name,
      receiverPhone: addr.receiver_phone,
      city: addr.city,
      district: addr.district,
      detail: addr.detail,
      storeId: addr.store_id,
      storeName: addr.store_name,
      storeAddr: addr.address_type === SHIPPING_TYPE.cvs ? addr.detail : null,
      storeType:
        addr.address_type === SHIPPING_TYPE.cvs
          ? addr.store_type.toLocaleUpperCase()
          : null,
      shippingType: addr.address_type,
      primary: addr.default,
    }));
  }

  async createAddress(values) {
    const _resp = await ApiProxy.post({
      path: `/v1/customer/addresses`,
      data: values,
      secure: true,
    });
    return _resp.success;
  }

  async deleteAddress(id) {
    return await ApiProxy.delete({
      path: `/v1/customer/addresses/${id}`,
      secure: true,
    });
  }

  async updateAddress(values) {
    const _resp = await ApiProxy.put({
      path: `/v1/customer/addresses/${values.id}`,
      data: values,
      secure: true,
    });
    return _resp.success;
  }

  async fetchFavStores() {
    const promises = Object.values(brandNameToBrandId).map(id =>
      ApiProxy.get({
        path: `/v1/customers/store?brand_id=${id}`,
        secure: true,
      }),
    );

    const resp = await Promise.all(promises);

    return resp.reduce(
      (acc, cur, curIdx) => [
        ...acc,
        {
          brand: Object.keys(brandNameToBrandId)[curIdx],
          storeId: cur.store?.id,
          storeName: cur.store?.title,
          storeAddr: cur.store?.address,
        },
      ],
      [],
    );
  }

  async setFavStore(values) {
    const _resp = await ApiProxy.post({
      path: `/v1/customers/set_default_store`,
      data: values,
      secure: true,
    });
    return _resp.success;
  }

  async fetchEvent(id) {
    return await ApiProxy.get({
      path: `/v1/event_pages/${id}`,
      secure: true,
    });
  }

  async fetchScrollingTexts(brand) {
    return await ApiProxy.get({
      path: `/v1/scrolling_texts?brand_id=${brandNameToBrandId[brand]}`,
      secure: false,
    });
  }

  async reCheckout(values) {
    const _resp = await ApiProxy.post({
      path: `/v1/orders/${values.id}/checkout`,
      secure: true,
      data: {
        credit_card_id: values.selectedCreditCardId,
        installment: values.installment,
      },
    });
    return _resp.success;
  }

  async fetchSubOrder(id) {
    return await ApiProxy.get({
      path: `/v1/sub_orders/${id}`,
      secure: true,
    });
  }

  async returnPreCalculate({id, items}) {
    return await ApiProxy.post({
      path: `/v1/return_orders/pre_calculate/${id}`,
      secure: true,
      data: {items},
    });
  }

  async returnOrder({id, values}) {
    return await ApiProxy.post({
      path: `/v1/return_orders/${id}`,
      secure: true,
      data: values,
    });
  }

  async syncPos() {
    return await ApiProxy.post({
      path: `/v1/customers/sync_pos`,
      secure: true,
    });
  }

  async returnOrderDetail(id) {
    const resp = await ApiProxy.get({
      path: `/v1/return_orders/${id}`,
      secure: true,
    });

    return {
      ...resp,
      status: RETURN_STATUS_TRANSFORM[resp.status],
      status_history: {
        [RETURN_STATUS.applied]:
          resp.status_history[`${RETURN_STATUS.applied}_at`],
        [RETURN_STATUS.processing]:
          resp.status_history[`${RETURN_STATUS.processing}_at`],
        [RETURN_STATUS.refund_failed]:
          resp.status_history[`${RETURN_STATUS.refund_failed}_at`],
        [RETURN_STATUS.return_failed]:
          resp.status_history[`${RETURN_STATUS.return_failed}_at`],
        [RETURN_STATUS.done]: resp.status_history[`${RETURN_STATUS.done}_at`],
      },
    };
  }

  async fetchOrderHistories() {
    return await ApiProxy.get({
      path: '/v1/customers/pos_order_histories',
      secure: true,
    });
  }

  async queryStockByVariantIds(ids) {
    return await ApiProxy.get({
      path: `/v1/products/query_stock?variant_id=${ids.join(',')}`,
    });
  }

  async fetchGeometry(location) {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${constants.googleApiKey}`,
    );
    const json = await response.json();
    return json.results?.[0] || null;
  }

  async createMaintainOrder(values) {
    return await ApiProxy.post({
      path: '/v1/maintain_orders',
      data: values,
    });
  }

  async getMaintainOrders(values) {
    return await ApiProxy.get({
      path: `/v1/maintain_orders?limit=${values.limit}&offset=${values.offset}`,
    });
  }

  async getMaintainOrder(id) {
    return await ApiProxy.get({
      path: `/v1/maintain_orders/${id}`,
    });
  }

  async putMaintainOrderQuotation(values) {
    return await ApiProxy.post({
      path: `/v1/maintain_orders/${values.id}/quotation`,
      data: {
        action: values.agreement,
      },
    });
  }

  async cancelMaintainOrder(values) {
    return await ApiProxy.post({
      path: `/v1/maintain_orders/${values.id}/cancel`,
    });
  }
}
module.exports = ApiAdapter;
