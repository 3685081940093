import numeral from 'numeral';
import React, {Fragment} from 'react';

function aggregateProductVariants(variants) {
  const stocks = variants ? [...variants] : [];

  let result = {
    colors: [], // {display, color, texture}
    sizes: [],
    stocks: stocks,
    isValid: true,
  };

  for (let v of stocks) {
    if (
      result.colors.length === 0 ||
      result.colors.map(c => c.color).indexOf(v.color) === -1
    ) {
      const colorData = {
        display: v.colorDisplay,
        color: v.color_img || v.color,
        texture: v.color_img,
      };
      result.colors.push(colorData);
    }
    if (result.sizes.indexOf(v.size.en) === -1) {
      result.sizes.push(v.size.en);
    }
  }

  result.sizes = result.sizes.map(size => size?.toLocaleString().toUpperCase());

  let isStringSizeStyle = false;
  for (const s of ['XS', 'S', 'M', 'L', 'XL']) {
    if (result.sizes.includes(s)) {
      isStringSizeStyle = true;
      break;
    }
  }

  if (isStringSizeStyle) {
    result.sizes.sort(
      (a, b) =>
        ['XS', 'S', 'M', 'L', 'XL'].indexOf(a) -
        ['XS', 'S', 'M', 'L', 'XL'].indexOf(b),
    );
  } else {
    result.sizes.sort((a, b) => a - b);
  }

  if (
    (result.sizes.some(s => s === null) &&
      result.sizes.some(s => s !== null)) ||
    (result.colors.some(c => c.color === null) &&
      result.colors.some(c => c.color !== null))
  ) {
    result.isValid = false;
  }

  // if sizes or colors.color contain null value, this product is not required to choose size or color
  if (result.sizes.findIndex(s => s === null) !== -1) {
    result.sizes = [];
  }

  if (result.colors.findIndex(c => c.color === null) !== -1) {
    result.colors = [];
  }

  return result;
}

function isStockAvailable(stocks, options = {}) {
  let available = false;
  for (let s of stocks) {
    let target = true;
    for (let k in options) {
      if (k === 'size' && s[k].en.toLocaleUpperCase() !== options[k]) {
        // size: {tw, en}
        target = false;
        break;
      }
      if (
        k === 'color' &&
        s[k] !== options[k] &&
        s['color_img'] !== options[k]
      ) {
        // color or color_img
        target = false;
        break;
      }
    }
    if (target && s.stock > 0) {
      available = true;
    }
  }
  return available;
}

const getSummaryCartItems = cartItems => {
  return cartItems.reduce((acc, cur) => {
    const targetIdx = acc.findIndex(i => i.variant.id === cur.variant.id);
    if (targetIdx > -1) {
      acc[targetIdx].quantity += cur.quantity;
    } else {
      acc = [...acc, cur];
    }
    return acc;
  }, []);
};

const getFormatCartItems = unFormatCartItems =>
  unFormatCartItems.map(item => ({
    variant_id: item.variant.id,
    quantity: item.quantity,
  }));

const getPrice = (priceSet, isPromote = false, isMember = false) => {
  const {price, promote_price, member_price} = priceSet;

  if (!isMember && !isPromote) {
    return price;
  }
  if (isMember && isPromote) {
    return promote_price <= member_price ? promote_price : member_price;
  }
  if (isMember && !isPromote) {
    return member_price;
  }
  if (!isMember && isPromote) {
    return promote_price;
  }
};

const getPriceTag = ({
  priceSet,
  isPromote = false,
  isMember = false,
  hasPrefix = false,
  mobile,
}) => {
  const {price, promote_price, member_price} = priceSet;
  const showPromote = promote_price <= member_price;
  const withMemberDiscount = price > member_price;

  if (!isMember && !isPromote) {
    return (
      <Fragment>
        <div className="price">NT {getFormatPrice(price)}</div>
      </Fragment>
    );
  }

  if (isMember && isPromote) {
    return withMemberDiscount ? (
      <Fragment>
        <div className={`${showPromote ? 'deprecated' : ''} price`}>
          NT {getFormatPrice(price)}
        </div>
        <div className="price">
          {!mobile && !showPromote && <span> | </span>}
          {showPromote
            ? `NT ${getFormatPrice(promote_price)}`
            : `${hasPrefix ? '會員價 ' : ''}NT ${getFormatPrice(member_price)}`}
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className={`${showPromote ? 'deprecated' : ''} price`}>
          NT {getFormatPrice(price)}
        </div>
        <div className="price">
          {!mobile && !showPromote && <span> | </span>}
          {showPromote && `NT ${getFormatPrice(promote_price)}`}
        </div>
      </Fragment>
    );
  }

  if (isMember && !isPromote) {
    return withMemberDiscount ? (
      <Fragment>
        <div className="deprecated price">NT {getFormatPrice(price)}</div>
        <div className="price">
          {!mobile && <span style={{margin: '0 4px'}}> | </span>}
          {`${hasPrefix ? '會員價 ' : ''}NT ${getFormatPrice(member_price)}`}
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className="price">NT {getFormatPrice(price)}</div>
      </Fragment>
    );
  }

  if (!isMember && isPromote) {
    return (
      <Fragment>
        <div className="deprecated">NT {getFormatPrice(price)}</div>
        <div className="price">NT {getFormatPrice(promote_price)}</div>
      </Fragment>
    );
  }
};

const getFormatPrice = price => {
  return numeral(price).format('$0,0');
};

const getOriginPriceSubtotal = items => {
  return items.reduce((acc, cur) => {
    acc += cur.variant.price * cur.quantity;
    return acc;
  }, 0);
};

export {
  aggregateProductVariants,
  isStockAvailable,
  getSummaryCartItems,
  getFormatCartItems,
  getPrice,
  getFormatPrice,
  getPriceTag,
  getOriginPriceSubtotal,
};
