import {brandsObj as brands} from './Domain/Brand';

const HomeAboutSection = {
  [brands.kenzo]: {
    banners: [
      {
        title: 'ABOUT THE BRAND',
        // image: 'https://placeimg.com/1000/480/people', // comes from api
        // image_m: 'https://placeimg.com/1000/480/people', // comes from api
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'ABOUT TUNGRP',
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'SERVICE',
        buttons: [{content: '了解更多', url: ''}],
      },
    ],
  },
  [brands.selfPortrait]: {
    banners: [
      {
        title: 'ABOUT THE BRAND',
        // image: 'https://placeimg.com/1000/480/people', // comes from api
        // image_m: 'https://placeimg.com/1000/480/people', // comes from api
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'ABOUT TUNGRP',
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'SERVICE',
        buttons: [{content: '了解更多', url: ''}],
      },
    ],
  },
  [brands.agete]: {
    banners: [
      {
        title: 'ABOUT THE BRAND',
        // image: 'https://placeimg.com/1000/480/people', // comes from api
        // image_m: 'https://placeimg.com/1000/480/people', // comes from api
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'ABOUT TUNGRP',
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'SERVICE',
        buttons: [{content: '了解更多', url: ''}],
      },
    ],
  },
  [brands.isabelMarant]: {
    banners: [
      {
        title: 'ABOUT THE BRAND',
        // image: 'https://placeimg.com/1000/480/people', // comes from api
        // image_m: 'https://placeimg.com/1000/480/people', // comes from api
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'ABOUT TUNGRP',
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'SERVICE',
        buttons: [{content: '了解更多', url: ''}],
      },
    ],
  },
  [brands.lesNereides]: {
    banners: [
      {
        title: 'ABOUT THE BRAND',
        // image: 'https://placeimg.com/1000/480/people', // comes from api
        // image_m: 'https://placeimg.com/1000/480/people', // comes from api
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'ABOUT TUNGRP',
        buttons: [{content: '了解更多', url: ''}],
      },
      {
        title: 'SERVICE',
        buttons: [{content: '了解更多', url: ''}],
      },
    ],
  },
};

const ProductInfoSection = {
  [brands.kenzo]: {
    info_ship: {
      zh: '配送- 詳/ 退細換說貨明服務 - 說明說明說明 \n說明說明換行 \n下一個',
      en: 'en ene en ene n e',
    },
  },
  [brands.selfPortrait]: {
    info_ship: {
      zh: '配送- 詳/ 退細換說貨明服務 - 說明說明說明 \n說明說明換行 \n下一個',
      en: 'en ene en ene n e',
    },
  },
  [brands.agete]: {
    info_ship: {
      zh: '配送- 詳/ 退細換說貨明服務 - 說明說明說明 \n說明說明換行 \n下一個',
      en: 'en ene en ene n e',
    },
  },
  [brands.isabelMarant]: {
    info_ship: {
      zh: '配送- 詳/ 退細換說貨明服務 - 說明說明說明 \n說明說明換行 \n下一個',
      en: 'en ene en ene n e',
    },
  },
  [brands.lesNereides]: {
    info_ship: {
      zh: '配送- 詳/ 退細換說貨明服務 - 說明說明說明 \n說明說明換行 \n下一個',
      en: 'en ene en ene n e',
    },
  },
};

// const Template = {
//   [brands.kenzo]: {},
//   [brands.selfPortrait]: {},
//   [brands.agete]: {},
//   [brands.isabelMarant]: {},
//   [brands.lesNereides]: {}
// }

export {HomeAboutSection, ProductInfoSection};
