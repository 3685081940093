import {Color, FlexRow} from '../Widget';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../RectButton';
import {Actions} from '../../Contexts/AppContext';
import React, {useState} from 'react';
import {useDimension} from '../../Hooks/AppHooks';
import styled from 'styled-components';

const Confirm = props => {
  const {
    onClick,
    cancelLabel,
    confirmLabel,
    icon = null,
    title = null,
    subTitle = null,
    children,
  } = props;
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Wrapper smallDevice={smallDevice}>
      {icon}
      <div className="content">
        <div className="title">{title}</div>
        <div className="sub-title">{subTitle}</div>
      </div>
      {children}
      <FlexRow
        style={{width: '100%', justifyContent: 'space-between', marginTop: 40}}>
        <RectButton
          text={cancelLabel}
          skin={BUTTON_SKIN.DEFAULT}
          size={BUTTON_SIZE.LARGE}
          style={{width: '48%'}}
          onClick={() => {
            Actions.setGlobalModalContent(null);
          }}
        />
        <RectButton
          isLoading={isLoading}
          text={confirmLabel}
          skin={BUTTON_SKIN.PRIMARY}
          size={BUTTON_SIZE.LARGE}
          style={{width: '48%'}}
          onClick={async () => {
            setIsLoading(true);
            await onClick();
            setIsLoading(false);
          }}
        />
      </FlexRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
  box-sizing: border-box;
  width: ${props => (props.smallDevice ? '100%' : '430px')};
  & > .content {
    text-align: center;
    padding: 0 20px;
    & > .title {
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 20px;
      color: ${Color.mainDark};
    }
    & > .sub-title {
      font-weight: 300;
      font-size: 16px;
      color: ${Color.mainDark_70};
    }
  }
`;

export default Confirm;
