import {createStore, applyMiddleware, compose} from 'redux';
import reducers from './Reducers';
import constants from './constants';

if (!constants.debug || typeof window === 'undefined') {
  console.log = () => 0;
  console.group = () => 0;
  console.groupEnd = () => 0;
}

let logger = store => next => action => {
  if (!console.group) {
    return next(action);
  }

  console.group(action.type);
  console.log('payload', action.payload);
  console.log('state before', store.getState());
  let result = next(action);
  console.log('state after', store.getState());
  console.groupEnd(action.type);
  return result;
};

function configureStore(initialState = {}) {
  const middlewares = [logger];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(reducers, initialState, compose(...enhancers));

  return store;
}

// global store, only used in client-render
let store;

function getStore(initialState = {}) {
  if (!store) {
    console.log('initialize redux store');
    store = configureStore(initialState);
  }

  return store;
}

export {getStore};
