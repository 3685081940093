const {COUNTIES, TOWNS_OF_COUNTY} = require('./Domain/Checkout');
const constants = require('./constants');
const _ApiAdapter = require('./ApiAdapter');
const MockData = require('./MockData');
const {returnOrderDetail} = require('./MockData');
const {returnPreCalculateDetail} = require('./MockData');

const {
  subOrder,
  newsList,
  categories,
  homePage,
  stores,
  productList,
  news,
  pointHistory,
  cart,
  promotions,
  seriesList,
  series,
  me,
  myCreditCards,
  searchData,
  orderDetail,
  orderList,
  event,
  favStores,
  addrs,
} = require('./MockData');

// helpers
const _delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const ApiAdapter = new _ApiAdapter({host: constants.apiUrl});

const LIVE = !constants.useMock;

//in product list page
const getMockProducts = (offset, limit, sort) => ({
  products: Array.from({length: limit}).map((_, idx) => ({
    collection: {
      title: {
        zh: 'T-shirts',
        en: 'T-shirts',
      },
      id: 'collection-1',
    },
    id: offset + idx,
    name: {
      zh: `P${offset / limit} Product ${idx + 1} ${sort ? sort : ''}`,
      en: `P${offset / limit} Product ${idx + 1} ${sort ? sort : ''}`,
    },
    image: `https://dummyimage.com/512/aaa/fff&text=${offset + idx + 1}`,
    price: (idx + 1) * 100,
    promote_price: 300,
    member_price: 300,
    color: '#ffffff',
    collectionId: 1,
    info_detail: {zh: 'detail', en: 'detail'},
    published: true,
    size_img: {zh: 'aaa.jpg', en: 'aaa.jpg'},
    series: {zh: 'series', en: 'series'},
    special_variant_id: 'blue-1',
    variants: [
      {
        id: 'blue-1',
        color: 'blue',
        colorDisplay: {
          zh: '藍色',
          en: 'blue',
        },
        color_img: 'aaa.jpg',
        size: {zh: 's', en: 's'},
        images: [`https://dummyimage.com/512/aaa/fff&text=${offset + idx + 1}`],
        material: {zh: '', en: ''},
        stock: 100,
        promote_price: 1955,
        member_price: 1955,
        price: 2300,
      },
      {
        id: 'blue-2',
        color: 'blue',
        colorDisplay: {
          zh: '藍色',
          en: 'blue',
        },
        color_img: 'aaa.jpg',
        size: {zh: 's', en: 's'},
        images: [`https://dummyimage.com/512/aaa/fff&text=${offset + idx + 1}`],
        material: {zh: '', en: ''},
        stock: 100,
        promote_price: 1955,
        member_price: 1955,
        price: 2300,
      },
      {
        id: 'blue-2',
        color: 'blue',
        colorDisplay: {
          zh: '藍色',
          en: 'blue',
        },
        color_img: 'aaa.jpg',
        size: {zh: 's', en: 's'},
        images: [`https://dummyimage.com/512/aaa/fff&text=${offset + idx + 1}`],
        material: {zh: '', en: ''},
        stock: 100,
        promote_price: 1955,
        member_price: 1955,
        price: 2300,
      },
    ],
  })),
  filters: [
    {filter_size: ['S', 'M', 'L']},
    {filter_color: ['red', 'blue', 'green', 'white']},
  ],
});

module.exports = {
  echo: async msg => {
    return msg;
  },

  // tungrp api
  getBrandCategories: async brand => {
    if (LIVE) {
      try {
        return await ApiAdapter.getBrandCategories(brand);
      } catch (err) {
        // FIXME: at Dec.11 call failed every time
        console.error('missing data, fallback to mock data:', err);
      }
    }
    await _delay(200);
    return categories[brand];
  },

  getBrandHomeData: async brand => {
    if (LIVE) {
      return await ApiAdapter.getBrandHomeData(brand);
    }
    return homePage[brand];
  },

  getBrandStores: async brand => {
    if (LIVE) {
      try {
        return await ApiAdapter.getStores(brand);
      } catch (err) {
        console.error('missing data, fallback to mock data:', err);
      }
    }
    await _delay(200);
    return stores[brand];
  },

  fetchProductById: async (id, brand) => {
    if (LIVE) {
      return await ApiAdapter.fetchProductById(id);
    }
    return {...productList[0], id, brand};
  },

  fetchCart: async ({useRebatePoints, useBirthGift}) => {
    return await ApiAdapter.fetchCart({useRebatePoints, useBirthGift});
  },

  updateCart: async ({items, useRebatePoints, useBirthGift}) => {
    if (LIVE) {
      try {
        return await ApiAdapter.updateCart({
          items,
          useRebatePoints,
          useBirthGift,
        });
      } catch (err) {
        console.error('addItemToCart', err);
        throw err;
      }
    }

    await _delay(500);
    // FIXME: should do better mocking for item delete or edit
    cart.items.push({...cart.items[0], id: new Date().getTime()});
    return cart;
  },

  fetchProducts: async ({
    brand,
    collectionId,
    filter,
    sort,
    offset,
    limit,
    categoryIds,
    subCollectionIds,
    seriesId,
    keyword,
  }) => {
    if (LIVE) {
      return await ApiAdapter.fetchProducts({
        brand,
        filter,
        sort,
        offset,
        limit,
        categoryIds,
        collectionId,
        subCollectionIds,
        seriesId,
        keyword,
      });
    }
    return getMockProducts(offset, limit, sort);
  },

  getCustomerExist: async phone => {
    if (LIVE) {
      try {
        return await ApiAdapter.getCustomerExist(phone);
      } catch (err) {
        console.error(err);
      }
    }
    return true;
  },

  login: async loginSet => {
    if (LIVE) {
      try {
        return await ApiAdapter.login(loginSet);
      } catch (err) {
        return null;
      }
    }
    return true;
  },

  getToken: () => {
    return ApiAdapter.getToken();
  },

  clearToken: () => {
    return ApiAdapter.clearToken();
  },

  genOtp: async phone => {
    if (LIVE) {
      try {
        return await ApiAdapter.genOtp(phone);
      } catch (err) {
        console.error('genOtp', err);
        return false;
      }
    }
    return true;
  },

  validateOtp: async ({phone, otp}) => {
    return await ApiAdapter.validateOtp({phone, otp});
  },

  register: async customerInfo => {
    if (LIVE) {
      return await ApiAdapter.register(customerInfo);
    }
    return true;
  },

  fetchMe: async () => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchMe();
      } catch (err) {
        console.error('fetch me', err);
        return null;
      }
    }
    return me;
  },

  resetPassword: async ({phone, password, otp}) => {
    if (LIVE) {
      try {
        return await ApiAdapter.resetPassword({phone, password, otp});
      } catch (err) {
        console.error('customersUpdate', err);
        return false;
      }
    }
    return true;
  },

  logout: () => {
    ApiAdapter.logout();
  },

  fetchSearchData: async keyword => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchSearchData(keyword);
      } catch (err) {
        console.error(
          'fetchSearchList error plz check it , fallback to mock data',
          err,
        );
      }
    }
    return searchData;
  },

  fetchSeries: async ({seriesId}) => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchSeries({seriesId});
      } catch (err) {
        console.error('fetch series failed', err);
        return null;
      }
    }
    return series;
  },

  fetchAllSeries: async brandId => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchAllSeries(brandId);
      } catch (err) {
        return [];
      }
    }
    return seriesList;
  },

  fetchOrders: async ({limit, offset, status}) => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchOrders({limit, offset, status});
      } catch (err) {
        console.error('fetchAllOrders', err);
        return [];
      }
    }
    return orderList;
  },

  fetchOrder: async id => {
    return await ApiAdapter.fetchOrder(id);
  },

  cancelOrder: async id => {
    if (LIVE) {
      try {
        return await ApiAdapter.cancelOrder(id);
      } catch (err) {
        console.error('cancelOrder error', err);
      }
    }
    return false;
  },

  getMyCreditCards: async () => {
    if (LIVE) {
      try {
        return await ApiAdapter.getMyCreditCards();
      } catch (err) {
        console.error('get credit cards failed', err);
        return [];
      }
    }

    return myCreditCards;
  },

  checkout: async data => {
    if (LIVE) {
      return await ApiAdapter.checkout(data);
    }

    return {}; // FIXME: add mock checkout data
  },

  getBindCreditCardForm: async path => {
    if (LIVE) {
      try {
        return await ApiAdapter.getBindCreditCardForm(path);
      } catch (err) {
        //
        throw err;
      }
    }

    return {
      // NOTICE: CheckMacValue will change and invalid each time, for Mock version, it cannot trigger this
      form_html:
        "\n      <form name='ecpay' method='post' action='https://payment-stage.ecpay.com.tw/MerchantMember/TradeWithBindingCardID/V2'>\n        <input name='MerchantID' type='hidden' value='3002607'/><input name='MerchantMemberID' type='hidden' value='30026071'/><input name='ServerReplyURL' type='hidden' value='https://test-ec.tun-grp.com//tranzactions/ecpay_notify'/><input name='MerchantTradeNo' type='hidden' value='21012109582513'/><input name='MerchantTradeDate' type='hidden' value='2021/01/21 09:58:25'/><input name='TotalAmount' type='hidden' value='2'/><input name='TradeDesc' type='hidden' value='bind'/><input name='stage' type='hidden' value='0'/><input name='ClientRedirectURL' type='hidden' value='googlechrome://www.google.com'/><input name='CheckMacValue' type='hidden' value='110AE5B2A2280E8D1D05FE9F9CE68F2E3CA3D4D5BA1894BE72DE57EE4C0532C8'/>\n        <input type='submit'/>\n      </form>\n    ",
    };
  },

  getAllCounties: () => {
    //if (LIVE) {
    //try {
    //return await ApiAdapter.getAllCounties();
    //} catch (e) {
    //console.error(e, 'getAllCounties failed fallback to static data');
    //}
    //}
    return COUNTIES;
  },

  getAllTowns: counties => {
    //if (LIVE) {
    //try {
    //return await ApiAdapter.getAllTowns(counties);
    //} catch (e) {
    //console.error(e, 'getAllTowns failed fallback to static data');
    //}
    //}
    return TOWNS_OF_COUNTY;
  },

  validatePassword: async password => {
    if (LIVE) {
      try {
        return await ApiAdapter.validatePassword(password);
      } catch (err) {
        console.error(err, 'validate password failed');
      }
    }
    return false;
  },

  updateCustomer: async customer => {
    if (LIVE) {
      try {
        return await ApiAdapter.updateCustomer(customer);
      } catch (err) {
        console.error(err, 'update customer failed');
      }
    }
    //FIXME response correct data struct
    return null;
  },

  updatePassword: async passwordSet => {
    if (LIVE) {
      try {
        return await ApiAdapter.updatePassword(passwordSet);
      } catch (err) {
        console.error(err, 'update password failed');
      }
    }
    //FIXME response correct data struct
    return null;
  },

  fetchNewsList: async ({brand, limit, offset}) => {
    if (LIVE) {
      return await ApiAdapter.fetchNewsList({brand, limit, offset});
    }
    return newsList;
  },

  fetchNews: async id => {
    if (LIVE) {
      return await ApiAdapter.fetchNews(id);
    }
    return news;
  },

  fetchPointHistory: async () => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchPointHistory();
      } catch (err) {
        console.error('fetch point history failed', err);
        return [];
      }
    }
    return pointHistory;
  },

  getPromotions: async () => {
    if (LIVE) {
      try {
        return await ApiAdapter.getPromotions();
      } catch (err) {
        console.error('get  promotions failed', err);
        return [];
      }
    }
    return promotions;
  },

  fetchFavStores: async () => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchFavStores();
      } catch (err) {
        console.error('fetch fav stores failed', err);
        return [];
      }
    }
    return favStores;
  },

  setDefaultCard: async id => {
    if (LIVE) {
      try {
        return await ApiAdapter.setDefaultCard(id);
      } catch (err) {
        console.error('set default card failed', err);
      }
    }
    return false;
  },

  deleteCard: async id => {
    if (LIVE) {
      try {
        return await ApiAdapter.deleteCard(id);
      } catch (err) {
        console.error('set default card failed', err);
      }
    }
    return false;
  },

  fetchMyAddrs: async () => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchMyAddrs();
      } catch (err) {
        console.error('fetch my addrs failed', err);
        return [];
      }
    }
    return addrs;
  },

  createAddress: async values => {
    if (LIVE) {
      try {
        return await ApiAdapter.createAddress(values);
      } catch (err) {
        console.error('create address failed', err);
        return false;
      }
    }
    return false;
  },

  deleteAddress: async id => {
    if (LIVE) {
      try {
        return await ApiAdapter.deleteAddress(id);
      } catch (err) {
        console.error('create address failed', err);
      }
    }
    return false;
  },

  updateAddress: async values => {
    if (LIVE) {
      try {
        return await ApiAdapter.updateAddress(values);
      } catch (err) {
        console.error('create address failed', err);
        return false;
      }
    }
    return false;
  },

  setFavStore: async values => {
    if (LIVE) {
      try {
        return await ApiAdapter.setFavStore(values);
      } catch (err) {
        console.error('set favorite store failed', err);
      }
    }
    return false;
  },

  fetchEvent: async id => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchEvent(id);
      } catch (err) {
        console.error('fetch event failed', err);
        return null;
      }
    }
    return event;
  },

  fetchScrollingTexts: async brand => {
    if (LIVE) {
      try {
        return await ApiAdapter.fetchScrollingTexts(brand);
      } catch (err) {
        console.error('fetch scrolling texts failed', err);
        return [];
      }
    }
    return MockData.scrollingTexts;
  },
  reCheckout: async values => {
    if (LIVE) {
      try {
        return await ApiAdapter.reCheckout(values);
      } catch (err) {
        console.error('re checkout failed', err);
        return false;
      }
    }
    return true;
  },

  fetchSubOrder: async id => {
    if (LIVE) {
      return await ApiAdapter.fetchSubOrder(id);
    }
    return subOrder;
  },

  returnPreCalculate: async ({id, items}) => {
    if (LIVE) {
      return await ApiAdapter.returnPreCalculate({id, items});
    }
    return returnPreCalculateDetail;
  },

  returnOrder: async ({id, values}) => {
    if (LIVE) {
      return await ApiAdapter.returnOrder({id, values});
    }
    return true;
  },

  syncPos: async () => {
    try {
      return await ApiAdapter.syncPos();
    } catch (err) {
      return false;
    }
  },

  returnOrderDetail: async id => {
    if (LIVE) {
      return await ApiAdapter.returnOrderDetail(id);
    }
    return returnOrderDetail;
  },

  fetchOrderHistories: async () => {
    try {
      return await ApiAdapter.fetchOrderHistories();
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  queryStockByVariantIds: async ids => {
    return await ApiAdapter.queryStockByVariantIds(ids);
  },

  fetchGeometry: async location => {
    return await ApiAdapter.fetchGeometry(location);
  },
  createMaintainOrder: async values => {
    return await ApiAdapter.createMaintainOrder(values);
  },
  getMaintainOrders: async values => {
    return await ApiAdapter.getMaintainOrders(values);
  },

  getMaintainOrder: async id => {
    return await ApiAdapter.getMaintainOrder(id);
  },
  putMaintainOrderQuotation: async values => {
    return await ApiAdapter.putMaintainOrderQuotation(values);
  },
  cancelMaintainOrder: async values => {
    return await ApiAdapter.cancelMaintainOrder(values);
  },
};
