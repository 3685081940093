import Actions from './Actions';

const initialState = {
  data: null,
  orders: [],
};

export default function reducer(state = initialState, action) {
  let {orders} = state;
  switch (action.type) {
    case Actions.LOGIN:
      return {
        ...state,
        data: action.payload,
      };
    case Actions.AUTO_LOGIN:
      return {
        ...state,
        data: action.payload,
      };
    case Actions.LOGOUT:
      return {...initialState};
    case Actions.UPDATE_PROFILE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case Actions.MAKE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        orders: [...orders, action.payload],
      };

    case Actions.FETCH_ORDER_SUCCESS:
      let foundIndex = orders.findIndex(o => o.id === action.payload.id);
      if (foundIndex > -1) {
        orders[foundIndex] = action.payload;
      } else {
        orders.push(action.payload);
      }
      return {
        ...state,
        orders: [...orders],
      };

    case Actions.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };

    default:
      return state;
  }
}

export function getLoginUser(state) {
  return state.data;
}

export function getShoppingCart(state) {
  return state.data && state.data.cart;
}

export function getMyFavorite(state) {
  return state.data && state.data.favorite;
}

export function getOrderData(state, id) {
  let order = state.orders.find(o => o.id === parseInt(id, 10));
  if (!order) {
    return null;
  }
  return {
    ...order,
    items:
      order &&
      order.items.map(item => ({
        ...item,
        saved_item_json: JSON.parse(item.saved_item_json),
      })),
  };
}

export function getOrders(state) {
  return state.orders;
}
