import tun_agete from '../images/about/tun/agete.jpg';
import tun_LNS from '../images/about/tun/LNS.jpg';
import tun_KENZO from '../images/about/tun/kenzo.jpg';
import tun_SP from '../images/about/tun/SP.jpg';
import tun_IM from '../images/about/tun/IM.jpg';
import tun_brands from '../images/about/tun/brands.jpg';

import kenzo_logo from '../images/about/kenzo/KENZO_Logo.jpg';
import kenzo_01 from '../images/about/kenzo/01.jpg';
import kenzo_02 from '../images/about/kenzo/02.jpg';
import kenzo_03 from '../images/about/kenzo/03.jpg';

import agete_logo from '../images/about/agete/agt_logo.jpg';
import agete_01 from '../images/about/agete/01.jpg';
import agete_02 from '../images/about/agete/02.jpg';
import agete_03 from '../images/about/agete/03.jpg';
import agete_04 from '../images/about/agete/04.jpg';
import agete_05 from '../images/about/agete/05.jpg';
import agete_06 from '../images/about/agete/06.jpg';
import agete_07 from '../images/about/agete/07.jpg';
import agete_08 from '../images/about/agete/08.jpg';

import self_portrait_logo from '../images/about/self-portrait/SP_logo.jpg';
import self_portrait_01 from '../images/about/self-portrait/01.jpg';
import self_portrait_02 from '../images/about/self-portrait/02.jpg';
import self_portrait_03 from '../images/about/self-portrait/03.jpg';
import self_portrait_04 from '../images/about/self-portrait/04.jpg';
import self_portrait_05 from '../images/about/self-portrait/05.jpg';
import self_portrait_06 from '../images/about/self-portrait/06.jpg';
import self_portrait_07 from '../images/about/self-portrait/07.jpg';

import isabel_marant_logo from '../images/about/isabel-marant/IM_logo.jpg';
import isabel_marant_01 from '../images/about/isabel-marant/01.jpg';
import isabel_marant_02 from '../images/about/isabel-marant/02.jpg';

import les_nereides_logo from '../images/about/les-nereides/LNS_logo.jpg';
import les_nereides_01 from '../images/about/les-nereides/01.jpg';
import les_nereides_02 from '../images/about/les-nereides/02.jpg';
import les_nereides_03 from '../images/about/les-nereides/03.jpg';
import les_nereides_04 from '../images/about/les-nereides/04.jpg';
import les_nereides_05 from '../images/about/les-nereides/05.jpg';

export const HELP_LIST_CONFIG = {
  route: '',
  key: 'help-list',
  title: {zh: '協助', en: '協助'},
  items: [
    // FIXME: @guychienll future feature comment first
    // {
    //   key: 'commonQuestions',
    //   route: '/commonQuestions',
    //   title: {zh: '常見問題', en: '常見問題'},
    // },
    {
      key: 'privacyPolicy',
      route: '/privacyPolicy',
      title: {zh: '隱私權政策', en: '隱私權政策'},
    },
    {
      key: 'termsOfOnlineTransaction',
      route: '/termsOfOnlineTransaction',
      title: {zh: '會員條款', en: '會員條款'},
    },
    {
      key: 'cookiesPolicy',
      route: '/cookiesPolicy',
      title: {zh: 'Cookies 政策', en: 'Cookies 政策'},
    },
    {
      key: 'memberService',
      route: '/memberService',
      title: {zh: '會員服務', en: '會員服務'},
    },
    // FIXME: @guychienll future feature comment first
    // {
    //   key: 'maintainServiceIntro',
    //   route: '/maintainServiceIntro',
    //   title: {zh: '維修保養說明', en: '維修保養說明'},
    // },
    {
      key: 'contact',
      route: '/contact',
      title: {zh: '聯絡我們', en: '聯絡我們'},
    },
    {
      key: 'siteMap',
      route: '/siteMap',
      title: {zh: '網站地圖', en: '網站地圖'},
    },
  ],
};

export const PrivacyPolicySections = [
  {
    title: '1. 前言',
    data: [
      '惇聚國際股份有限公司(以下簡稱本公司)重視資訊保密及客戶之個人隱私，且致力保護您的個人隱私及遵循個人資料保護法或相關法令之規定。本隱私權政策（以下簡稱本政策）旨在幫助您了解本公司如何蒐集、使用及保護本公司透過網站自您所取得之個人資料，本政策亦將說明本公司如何揭露、傳輸您的個人資料及其對象。若您為未成年人，您應於您的法定代理人或監護人閱讀並了解本政策後方得使用本網站之服務。若您(未成年人)使用本網站服務，您或您的法定代理人或監護人同意將您的個人資料或相關隱私資訊提供予本公司，皆將被視為您或您的法定代理人或監護人同意本政策。',
      '本政策適用於本公司透過KENZO、Isabel Marant、self-portrait、Les Nereides、agete等，本公司代理之品牌店鋪或其他本公司官方認可之直營網站或app向您提供之各類服務。',
      '「個人資料」是指有關個人不論真實與否的資料，該個人可透過該資料，或透過該資料與其他組織持有或可能有權限存取資料，予以識別。個人資料常見的例子包括姓名、身分證號碼和聯絡資訊。',
      '若您使用本服務、向本網站註冊帳戶、造訪本網站或使用本網站之服務，即表示您知悉並同意接受本隱私權政策概述之作法、要求及 / 或政策，並特此同意本公司收集、使用、揭露及 / 或處理此處所述之個人資料。若本公司變更隱私權政策，本公司將於本網站上公佈變更或修改之隱私權政策。本公司保留隨時修改本隱私權政策的權利。',
    ],
  },
  {
    title: '2. 個人資訊蒐集之時機',
    data: [
      '本公司將在下列時候收集您的個人資訊：',
      '(1) 當您註冊及或使用本網站相關服務，或是向本網站註冊帳戶時',
      '(2) 當您透過網路線上購買或於店舖實體購買時',
      '(3) 當您因特殊原因與本公司互動提供其他文件或資訊，或是當您使用本公司服務時',
      '(4) 當您透過電話 (可能會錄音)、信件、傳真、面對面會議、社交媒體平台和電子郵件等方式與本公司互動時，包括與本公司之客服人員互動時',
      '(5) 當您透過本網站或app進行交易時，或透過本網站或app之即時聊天功能進行互動時',
      '(6) 當您提供意見或投訴予本公司時',
      '(7) 當您基於任何原因提交個人資料予本公司時',
      '上述情形並非包含全部情形，僅列出可能會收集您個人資料的一些常見實例',
    ],
  },
  {
    title: '3. 個人資料之蒐集',
    data: [
      '本公司會在使用本服務的必要範圍內收集使用者的個人資料，包括但不限於，姓名、電子郵件地址、出生日期、帳單地址、銀行帳戶與付款資訊、電話號碼、性別、與您用來存取本網站服務之裝置相關資訊或該裝置所傳送出的資訊、基於身分確認或預防詐欺等目的所要求的政府機關核發之證明或其他資訊、當使用者註冊使用本網站之服務及使用者使用本網站之服務時所提供有關使用者的其他任何資訊，及使用者如何使用本網站之服務之相關資訊；與彙總使用者互動內容的相關資訊等。',
      '如同對大多數的網站一樣，您的裝置會傳送可能包含與您相關的資訊，而網頁伺服器則會在您瀏覽本網站時記錄該資訊。這通常包括但不限於：您裝置的IP位址、電腦或行動裝置作業系統和瀏覽器類型、行動裝置的種類、行動裝置的特性、行動裝置的唯一裝置識別碼(UDID)或行動設備識別碼(MEID)、參考網站的地址(若有)，以及您在本網站時所造訪的網頁以及造訪時間，有時也會利用Cookie(可使用瀏覽器喜好設定予以停用)協助本網站記住您前次的造訪資訊。當您登入時，這項資訊會與您的個人帳戶建立關聯。該資訊也會納入匿名的統計資料中，以協助本公司瞭解訪客使用本網站之情形。',
    ],
  },
  {
    title: '4. 個人資訊之使用',
    data: [
      '本公司可能會針對下列一或多個目的去收集、使用、揭露及/或處理您的個人資料',
      '(1) 考量或處理您向本公司提出的申請/與本公司進行的交易。',
      '(2) 管理、操作、提供或處理您對本網站之使用或存取(包括但不限於記住您的喜好設定)、您與本公司之關係，以及您向本公司開立之使用者帳戶。',
      '(3) 回應、處理、處理或完成交易或滿足您對特定產品及服務的要求，以及向您告知服務問題和不尋常的帳戶操作。',
      '(4) 執行本公司之「服務條款」或任何適用之使用者授權合約。',
      '(5) 保護人身安全和權利、財產或其他人的安全。',
      '(6) 維護及管理偶爾需要進行的軟體更新或其他更新及支援，以確保本服務能順暢運作。',
      '(7) 基於執行或管理您與本公司之關係，或是您的使用本服務之目的，透過語音通話、文字簡訊及/或傳真訊息、電子郵件及/或郵寄或其他方式與您聯繫或溝通，例如但不限於向您傳達與本服務有關的管理資訊。您知悉並同意本公司得透過郵寄信件、文件和通知的方式與您進行上述通訊，遞送過程中可能會在信封/郵件包裹外部揭露您的特定個人資料。',
      '(8) 進行研究、分析和開發活動 (包括但不限於資料分析、問卷調查、產品和服務開發及/或剖析)，以分析您對本服務的使用情形、改進本網站之服務或加強您的客戶體驗。',
      '(9) 允許進行稽核及調查以確認目標受眾的規模和組成，並瞭解他們對本服務的體驗。',
      '(10) 基於行銷或廣告這方面的目的，透過各種通訊模式，向您寄送有關本公司可能正在銷售、行銷或促銷之產品及/或服務之行銷和促銷資訊及資料，無論該產品或服務目前已存在或是未來才會建立；您隨時可以透過電子行銷資料中的取消訂閱功能來取消接受行銷資訊。本公司可能會使用您的聯絡資訊向您寄送本公司之簡訊或電子報。',
      '(11) 回應法律程序或是遵守相關管轄地之任何適用法律、政府或監管要求或是本公司有理由相信揭露為必要的，包括但不限於依據對本公司具有約束力之任何法律規定，符合揭露之要求(包括但不限於，如適用法令要求，揭露您的姓名、聯絡資訊及公司資料)。',
      '(12) 針對內部和法定報告及/或記錄保存要求，產生統計資料和研究結果。',
      '(13) 根據法律或法規義務或是本公司之風險管理程序 (依據法律規定或是本公司所實施者)，執行盡職調查或其他過濾行動 (包括但不限於背景調查)。',
      '(14) 稽核本公司之業務。',
      '(15) 防止或調查任何實際或可能違反本公司服務條款之情形、詐欺、非法活動、疏忽或不當行為，以瞭解這些行為是否與您對本服務的使用有關、是否因為您與本公司之關係而產生。',
      '(16) 回應任何聲稱的或實際的針對本公司之主張，或其他對於內容侵害第三方權利之主張。',
      '(17) 儲存、託管、備份（無論是為了災難復原或其他目的）您的個人資料，不管是在您所在管轄地內或外。',
      '(18) 本公司在取得您同意的當時，向您告知的任何其他目的。',
      '您知悉並同意若依法律要求、或根據法院命令、或對本公司有管轄權之任何政府或主管機關之要求、或基於善意及誠信原則認有合理之必要性時，本公司得存取、保存及揭露您的帳戶資訊和內容。',
      '由於本公司將可能收集、使用、揭露或處理您個人資料的目的取決於當時的情況，該目的可能未出現在上述項目中。不過，本公司會在取得您同意的當時，向您告知該其他目的，除非隱私權相關法令允許本公司於未取得您同意的情況下即可處理適用的資料。',
    ],
  },
  {
    title: '5. 個人資訊之保護',
    data: [
      '本公司實施各式各樣的安全措施，以致力確保您的個人資料在本公司之系統上安全無虞，但不可避免地無法保證絕對的安全。使用者個人資料包含在安全網路之後，只有具有該系統之特殊存取權的有限員工可以存取。',
      '本公司會按照隱私權法及/或其他適用法律保存個人資料。於本公司合理認為(一)保存該個人資料不再符合收集個人資料之目的；(二) 不再需要針對任何法律或業務目的保存個人資料時；以及 (三) 沒有其他法律上之利益需要進一步保存該個人資料時，本公司會立即銷毀您的個人資料，或是進行匿名化處理。若您停止使用本網站或是您使用本網站之權限遭到終止或撤銷，本公司會繼續根據此隱私權政策及隱私權法所規定的義務，加以儲存、使用及/或揭露您的個人資料。根據適用法律，本公司得於未事先通知您的情況下，安全處置您的個人資料。',
    ],
  },
  {
    title: '6. 資訊揭露',
    data: [
      '在開展業務時，本公司將可能需要針對上述一或多個目的向第三方服務供應商、代理人或其他第三方揭露您的個人資料。該第三方服務供應商、代理人及或其他第三方可能會針對上述一或多個目的，代表本公司或以其他方式處理您的個人資料。',
      '該第三方包括但不限於：',
      '(1) 本網站之其他使用者基於本隱私權政策所述之目的。',
      '(2) 承包商、代理人、服務供應商以及其他本公司用以支援本公司之業務之第三方。這些包括但不限於向本公司提供管理或其他服務的單位，如郵寄公司、物流服務提供者、金融服務提供者、電信公司、資訊技術公司和資料中心。',
      '(3) 對本公司有管轄權的政府或監管機關。',
      '(4) 整合、剝奪、改組、重整、解散或其他銷售或轉移 (無論是持續經營的形式，或是破產、清算或類似程序的一部分) 本公司部分或全部資產的買方或其他繼任者，其中由本公司所持有、有關本服務使用者的個人資料也在轉移的資產中；或是本公司所參與之企業資產交易的對手。',
      '(5) 本公司針對一或多個目的而向其揭露個人資料的第三方，該第三方可能會針對一或多個目的反過來收集和處理您的個人資料。',
      '本公司與廣告和規劃供應商分享和使用者及其對本服務使用情形相關的統計及人口統計資訊。',
      '在本公司沒有責任的情況下，第三方可能會非法攔截或存取傳送至網站或是網站所包含的個人資料，技術可能會故障或無法如預期運作，或是有人可能會存取、濫用或誤用資訊。本公司會依照隱私權等相關法令之規定，部署合理的安全措施以保護您的個人資料，但必然無法保證絕對的安全，包括但不限於在本公司沒有責任的情況下，因他人惡意或駭客攻擊而造成未授權揭露等情形。',
    ],
  },
  {
    title: '7. 安全性和第三方網站的免責聲明',
    data: [
      '本公司不保證您在第三方網站上提供之個人資料或其他資訊的安全性。為維護本公司所擁有或控制與您有關之個人資料的安全性，本公司確實實施了各種安全措施。您的個人資料包含在安全網路之後，只有具有該系統之特殊存取權且必須對個人資料保密的有限人員可以存取。當您下訂單或存取個人資料時，本公司會提供安全伺服器以供使用。您提供的所有個人資料或敏感資訊都會以加密方式存放於本公司之資料庫中，只有上述人員可以存取。',
    ],
  },
];

export const CookiesPolicySections = [
  {
    title: '',
    data: [
      '本政策描述了惇聚國際股份有限公司（以下簡稱本公司），作為本網站（https://www.tungrp.com）（以下簡稱本網站）的營運者和資料控制者/資料使用者，如何在本網站上放置和使用cookies和類似科技。',
      'Cookies是放置在您的硬碟上的小資料文件，大多數瀏覽器都會自動接受cookies。某些cookies不會收集能對您進行個人識別的資料，但其他某些cookies（或類似科技）可能會收集您的資料，例如您的瀏覽器類型和版本、操作系統、IP地址、訪問日期和時間、裝置辨認碼、流動網絡資料、位置、域名和網站瀏覽行為。',
      '當您第一次訪問本網站時，您將看到一個彈出窗口，請求您同意在您的設備上放置和使用cookies。您可以給予該許可或（通過管理您的偏好）拒絕cookies的放置和使用。',
      '彈出窗口可能不會在您隨後瀏覽本網站時出現，但您可以更改瀏覽器的選項，以獲通知被設定新的cookies、刪除舊的cookies或者封鎖cookies（無論現在或將來）。有關管理cookies的更多信息，請參閱aboutcookies.org，當中包含有關如何在各種瀏覽器上限制或封鎖cookies的詳盡資料。請注意，倘封鎖cookies，您可能會無法使用本網站提供的所有內容及服務。',
      '本公司使用cookies來提供本網站的基本功能，例如頁面導航、在您返回本網站時識別您、記住您的偏好，個人化您的購物體驗和保持您購物袋的內容。它們亦將通過跟踪您在網站上的瀏覽來提供符合您有興趣的廣告。',
      '除以上所述之外，本公司還可能會自動收集有關您在線期間的匿名資料，以收集有關本服務器使用情況的統計資料及幫助本公司進一步滿足您的需求和期望。',
      '請注意，本政策不適用於可能與本網站鏈接的第三方網站的隱私或cookies做法，而本公司亦不對此等做法承擔任何責任。',
      '本政策可能會以在本頁面發佈的方式不時更新。您應不時查看本政策以了解本公司如何使用cookies。',
      '請參閱本網站之隱私政策以了解本公司收集、處理、使用和/或傳送個人資料的一般做法。',
    ],
  },
];

export const TermsOfOnlineTransactionSections = [
  {
    title: '1. 目的',
    data: [
      '本約定條款之目的，是為確立您與惇聚國際股份有限公司（以下簡稱本公司）於本網站（https://www.tungrp.com）（以下簡稱本網站）購物之權利與義務。當您於本網站進行訂購、付款、消費等相關行為，代表您已閱讀、知悉、並同意本約定條款之所有約定。若您未滿二十歲，請您的家長（或監護人）同時確實閱讀、瞭解並同意遵守本約定條款，您方得使用或繼續使用本網站之各項服務。如未取得同意而進行購物所產生之紛爭，本公司均不負任何義務與責任。',
    ],
  },
  {
    title: '2. 個人資料安全',
    data: [
      '若您願意，您可以提供正確的個人資料，並註冊成為本網站之會員，註冊成為會員並不需要是先支付或承諾任何費用;在您完成註冊後，您能登入本網站，參與本網站之服務或相關活動於購物。您必須擔保這些資料的完整和正確，若個人資料有異動，您必須主動上網登錄修改或連繫客服信箱或客服人員處理，若您提供任何錯誤或不實資料，本公司有權暫停或終止您的會員資格，並拒絕繼續對您提供服務。',
      '您有妥善保管「會員帳號、密碼」的責任和義務，不得洩漏、出借、轉讓或其他方式或名義提供給第三人知悉， 並應於每次使用後確實登出，以防他人冒用，對於使用該組會員帳號密碼登入本網站後之所有一切行為，除可證明非可歸責於您之事由所致者，都將視為您本人之行為，您須負完全責任。若非可歸責於本公司之事由致您的帳號遭盜用而產生您個人的損失，本公司不負賠償責任。',
      '您所留存的個人資料，本公司將依本約定條款負保密責任，除為完成交易或會員服務而提供給相關服務之配合廠商或本約定條款另有約定外，絕不會任意洩漏或提供給第三人。',
      '會員之帳號及密碼遺失，或發現無故遭第三者盜用時，應立即通知本網站連絡掛失，因未即時通知，導致本網站無法有效防止及修改時，所造成的所有損失，會員應自負全責。',
    ],
  },
  {
    title: '3. 個人資料保護',
    data: [
      '本公司將遵守個人資料保護相關法令規定，除依本購物網站使用條款、隱私權政策或法律規定外，不會違法利用您個人資料。在下列情況下，本公司有可能會提供您的個人資料給相關機關，或主張其權利受侵害並提示司法機關正式文件之第三人：',
      '(1) 依法令或受檢警調、司法機關或其他有權機關基於法定程序之要求。',
      '(2) 在緊急情況下為維護其他客戶或第三人之合法權益。',
      '(3) 為維護本購物網站之正常運作。',
      '(4) 為提供本公司相關服務產生之金流、物流或其他協力廠商合作廠商必要資訊。',
      '(5) 使用者有任何違反政府法令或本購物網站使用條款之情形。',
      '本公司與本網站相互連結，除此之外跳脫連結至其他網站，即不適用本購物網站使用條款與隱私權規範。',
    ],
  },
  {
    title: '4. 通知方式',
    data: [
      '您同意一般性通知以電子郵件向您表示通知，重大關乎您個人使用與購物方面權益部分將採取電話直接聯繫您本人告知。',
    ],
  },
  {
    title: '5. 購物注意事項',
    data: [
      '本網站所提供之商品數量有限，請您挑選後儘速完成訂購程序。放入購物車中不代表已完成訂購。若有其他訂購者在您完成前先完成訂購程序，系統會通知您該筆商品已售完或訂購程序異常，將撤銷您的訂單。',
      '您同意並瞭解本公司與本網站所標示之商品價格及其他廣告、促銷或相關資訊，均屬要約之引誘，而您的訂單性質屬要約，本公司有權以任何方式拒絕承諾您的要約或撤銷您的訂單。',
      '本公司於本網站之商品價格及其他廣告、促銷或相關資訊有任何標示錯誤時，本公司將以官方公告進行更正，不論您的訂單是否已被確認，本公司均有權拒絕您要求本公司履行更正前的標價或資訊內容，本公司亦不對您或其他人承擔任何責任。您必須依照本購物網站商品所標示的價格、數量、規格、圖片、說明等相關交易條件進行購買，若前述標示有誤時，本公司有權在您完成訂購程序後拒絕或撤銷您的訂單。',
      '若您選擇以信用卡、金融卡、銀行帳戶或其他第三人支付帳戶等進行付款時，即便您已在本網站輸入相關資訊，也僅是作為確認資訊有效性及相關交易授權之用，並不代表您已經付款或交易已經完成或契約已經成立。若您的信用卡、金融卡、銀行帳戶或其他第三人支付帳戶等已經收款，而後您的訂單被本公司拒絕或撤銷者，本公司將直接退款至您的原購買帳戶。',
      '在您完成線上訂購程序以後，本網站會自動經郵電子郵件或其他方式寄給您一封通知， 但是該通知僅是通知您本公司已經收到您的訂購訊息，不代表交易已經完成或契約已經成立，本網站保有是否接受您的訂單權利。如果本網站確認交易條件無誤、付款完成且仍有庫存，將會直接安排出貨，不另行通知。若您購買多個品牌的商品，您的包裹將依照品牌分批到貨。',
      '本網站進行的所有線上消費，會員同意以線上購物所紀錄之電子交易資料為準；會員如果發現交易資料不正確，應立即通知負責業務店。如有糾紛會員同意以該電子交易資料為認定標準。',
      '本購物網站之部分商品並不含運費，價格標示為商品本身價格。運費將合併於最後的結帳總金額當中。運費若產生變動，將隨時公告於網站。',
    ],
  },
  {
    title: '6. 退貨注意事項',
    data: [
      '依照消費者保護法規定，線上購物皆享有商品到貨「七天鑑賞期」之權益。 ',
      '收到商品的次日起，計算七個工作日，可申請退貨。自送達日(宅配)/取貨日(超商)之日\n 起7天內可免費退貨。',
      '辦理退貨，請還原包裝/紙箱，切勿併箱，避免因爭而無法順利退款。宅配公司會在申請後\n' +
        ' 2~3 個工作天內與您電話聯絡，並至取件地址跟您收取退貨包裹。',
      '若您購物有使用回饋金，敬請注意:經折抵使用之回饋金，將在退貨退款完成後返回，且將有\n 三天使用期限。',
      '在您還不確定是否要辦理退貨以前，請勿拆封；若您購買的商品是耗材、個人衛生用品等一經拆封即無法回復原狀的商品， 請在尚未確認是否辦理退貨前，請勿拆封；一經拆卸即代表您滿意商品並開始使用，並瞭解此行為將影響您的退貨權益。',
      '若有下列情形，將影響您的商品退貨權益：',
      '(1) 商品退貨時必須為全新狀態且完整包裝，並有掛著完整 TUN 標籤與品牌吊牌(包含商品主體、附件、內外包裝、隨附文件、贈品、保證書等）以免影響您退貨權益，故需請您保持商品、附件、包裝、廠商紙箱及所有隨附文件資料的完整，以寄送時的包裝再原封備妥， 若有相關贈品請一併退回，原廠外盒損毀、商品缺件、超過7天鑑賞期或是包材不完整、 有使用痕跡或組裝痕跡，恕無法受理退貨及退款。',
      '(2) 本網站目前未提供換貨服務，請於收到商品的次日起，計算七個工作日，可申請退貨。',
      '(3) 需保留原購買發票。',
      '(4) 本網站有權拒絕超出退貨期限寄送之商品，或商品狀態與所收到狀態不相同之退貨。',
      '(5) 目前恕不接受至實體門市或取貨地點辦理退貨。',
      '(6) 其他於本網站商品銷售網頁上有特別載明者。',
      '(7) 鑑賞期非試用期，若您收到商品經必要之檢視後有任何不合意之處，請勿拆開使用，請依照退貨規定辦理退貨。',
      '(8) 您退回之商品經供應商完成檢測無誤後，本公司將約於 30 個工作天內（不含假日）完成退款作業。',
      '(9) 每筆品牌的交易限退貨乙次。如因個人因素辦理退貨次數過多，本公司有權暫停或終止您的購物與服務資格，敬請見諒。',
      '(10) 若您申辦退貨事宜，為加速退貨退款流程，您同意得由本公司代為處理電子發票、折讓單或銷貨退回單等相關事宜。',
      '(11) 所有未盡詳述表示之處，將遵循中華民國法律所有相關規定。',
      '(12) 若有疑問，歡迎與指定業務店做接洽。',
    ],
  },
  {
    title: '7. 智慧財產權',
    data: [
      '本網站所有商標、圖片、插圖、網站架構、程式設計、畫面安排、網頁編排、其他標示與資訊等等，均屬本公司或相關權利人依法擁有智慧財產權、著作權、商標法、專利法、營業秘密法及公平交易法之保護。非經本公司或權利人事先授權，不得任意使用，以免涉及侵權或違法之責任。',
    ],
  },
  {
    title: '8. 服務中止與免責聲明',
    data: [
      '本公司將以符合目前一般可合理期待安全性之方式與技術維護本購物網站正常運作。產生下列情況時本公司及本網站有權暫停提供服務，且不負事先通知您之義務。本公司對於產生任何直接或間接損害，均不負任何賠償或補償之義務：',
      '(1) 本網站相關軟硬體設備進行搬遷、更換、升級、保養或維修。',
      '(2) 天災或其他不可抗力因素導致服務中止或中斷。',
      '(3) 因電信公司或網路服務公司中斷或其他不可歸責於本公司事由所致之服務暫停或中斷。',
      '(4) 本網站遭受外力影響以致資訊不正確、遭偽造、竄改、刪除或擷取，或致使系統中斷不能正常運作。',
      '(5) 使用者有違本網站使用條款或法令之情形而對該使用者暫停或終止服務。',
      '(6) 本公司或本網站有需要暫停或終止服務。',
    ],
  },
  {
    title: '9. 違約處理',
    data: [
      '本公司與本網站為了維護全體使用者的權益，當您有以下情形時， 本公司得以暫停或中止您的購物資格或拒絕您使用本公司本購物網站服務：',
      '(1) 提供不實或錯誤資料進行登錄。',
      '(2) 未經本人許可而盜刷信用卡或盜用金融帳號。',
      '(3) 經本公司本購物網站認定有惡意退貨情形。',
      '(4) 對本公司本購物網站進行騷擾、辱罵、恐嚇或不當言行。',
      '以上事項您已經同意，且本公司本購物網站對您或第三人均不負任何義務與責任。',
    ],
  },
  {
    title: '10. 本同意書效力與管轄法院',
    data: [
      '除本約定條款外，您亦應遵守本公司會員條款、隱私權政策、本網站之各項公告及各商品之交易相關限制規定。',
      '您在本網站所進行之訂購、交易或相關消費行為，以及本約定條款，都以中華民國法令為準據法，如因此涉訟，您同意以台灣台北地方法院為第一審管轄法院。',
      '本網站保留隨時修改本約定條款之權利，修改後之約定條款將固定公佈於本網站會員中心內，不另行個別通知；如您繼續於本網站進行訂購或其他行為，即視為您已瞭解、並同意遵守修改後之約定條款。',
      '本同意書若有未盡之處，依中華民國法律解釋、補充及辦理。',
    ],
  },
];

export const AboutTheTun = [
  {
    title: '',
    data: [
      {
        context: [
          'TUN是台灣首屈一指的品牌管理及高級零售商。旗下代理各國多元的服裝與珠寶等品牌，於台灣擁有完整的銷售網路，包含KENZO、Isabel Marant、self-portrait、agete、Les Néréides。',
        ],
        images: [],
      },
    ],
  },
  {
    title: '',
    data: [
      {
        context: [],
        images: [
          {
            source: tun_brands,
            ratio: 409 / 1337,
          },
        ],
      },
    ],
  },
  {
    title: '[創建品牌，不止於零售營運]',
    data: [
      {
        context: [
          '我們不只聚焦於零售營運，更致力於為夥伴在台灣本土市場為品牌打造出脫穎而出的品牌。透過商品規劃、精準的行銷活動，宣傳內容，來喚起消費者的共鳴。我們深知顧客需求因人而異，一對一直接的溝通，與完整的遠距離商品瀏覽便利性同等重要。\n' +
            '因此在2022年推出真正整合線上線下的體驗平台。TUN將帶給你前所未有的行動與實體融合的精品消費體驗。身為TUN的尊榮會員，您將能同時擁有線上購物的便捷，與線下購物的貼心客製化服務。\n',
        ],
        images: [
          {
            source: tun_LNS,
            ratio: 970 / 1340,
          },
          {
            source: tun_agete,
            ratio: 970 / 1340,
          },
          {
            source: tun_KENZO,
            ratio: 970 / 1340,
          },
          {
            source: tun_IM,
            ratio: 970 / 1340,
          },
          {
            source: tun_SP,
            ratio: 970 / 1340,
          },
        ],
      },
    ],
  },
];

export const AboutTheKenzo = [
  {
    title: '',
    data: [
      {
        context: [],
        images: [
          {
            source: kenzo_logo,
            ratio: 363 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【KENZO品牌起源】',
    data: [
      {
        context: [
          '走過近半個世紀，KENZO持續將正面能量與有感染力的自由感，注入多元、大膽無邊界的創作中。創辦人Kenzo Takada 1939年2月出生於日本兵庫縣，就讀東京文化服裝學院。1960年獲得日本服飾設計獎，並在三年後，帶著些許材料與碎布，隻身座船前往巴黎。他在1969年於巴黎的Galarie Vivienne開設第一間服飾店『Jungle Jap』，第一次舉辦小型時裝發布會，就吸引法國『ELLE』總編目光，登上11月封面。他，是第一位在巴黎享譽國際的亞裔設計師。設計出如萬花筒般變幻的色彩和圖案，被世人稱作色彩魔術師。',
        ],
        images: [
          {
            source: kenzo_01,
            ratio: 763 / 1340,
          },
          {
            source: kenzo_02,
            ratio: 970 / 1340,
          },
        ],
      },
      {
        context: [
          '在2021年9月21日，LVMH集團Fashion Group總裁Sidney Toledano先生正式公布將由日本元老級潮流教父Nigo出任KENZO藝術總監。\n' +
            'Nigo（本名 Tomoaki Nagao）出生於日本群馬縣，是日本傳說級潮流品牌 A Bathing Ape 的創始人，也是日系美式品牌 Human Made 的主理人；活躍於 90、00 年代裏的原宿，亦是改變世界對於日本潮流認知的關鍵人物。近年他也與許多知名品牌有過聯名合作，如 KAWS、ADIDAS ORIGINALS、LEVI’S、KFC 與 VERDY 等，更二度與 LV 男裝藝術總監 VIRGIL ABLOH 推出 LV² 系列聯名。\n',
        ],
        images: [
          {
            source: kenzo_03,
            ratio: 763 / 1340,
          },
        ],
      },
    ],
  },
];

export const AboutTheAgete = [
  {
    title: '',
    data: [
      {
        context: [],
        images: [
          {
            source: agete_logo,
            ratio: 363 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【agete品牌起源】',
    data: [
      {
        context: [
          'agete誕生於1990年6月9號，選擇在東京的古董通大道裡靜謐的角落開幕。1990年代的agete原是以販賣古董傢俱起家，店裝以灰白的石牆拼接，從遠處看洋溢著古樸的風情，令人過目難忘。而店內則擺飾著古歐洲的骨董傢俱、濃烈異國情懷的土耳其花卉地毯，以及北非神祕風情的玩偶擺設，無處不充滿了木質調的悠然氛圍，同時又擁有前衛巴洛克風格的裝飾擺設。在1990年代的日本，這樣強烈異國風情的店裝風格，是前所未有的；讓agete成為一隅具獨特品味的東京世外桃源。\n' +
            '在2000年，歷經10年的沉澱，agete終於在青山店以品牌最成熟的面貌，呈現agete的靜謐姿態。在人與車聲鼎沸的青山道上，agete也自然成了日本人在鬧中取靜的幽靜新地標。而店裝部份也更趨精緻、優雅，無論從外牆、內部燈光、裝飾性藝術風格的地板，或者店內的古董家具，甚至是幽雅中帶有一絲現代風情的展櫃，都默默的散發出穩定人心的溫暖力量，讓走入agete的你，彷彿走入時空隧道中，享受指針上緩慢流失的從容。\n',
        ],
        images: [
          {
            source: agete_01,
            ratio: 970 / 1340,
          },
          {
            source: agete_02,
            ratio: 970 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【agete ― 經典創作回顧】',
    data: [
      {
        context: [
          '你知道agete的蛻變過程嗎？\n' +
            '在歐美旋風為主流的90年代，「costume jewelry（時裝珠寶）」霸佔飾品的排行榜熱潮，無論是直徑粗大的耳圈、閃閃發光的短頸鍊、異材質拼接的巴洛克式手環都是當時代蔚為風潮的時尚寵兒。正是在此時，agete獨自寧靜地在東京的古董通大道裡的角落開幕，在一開始，agete以販賣骨董及富有古樸歷史感的洋派飾品為主。1990上半時，agete以大器、個性化且充滿異國情調的的飾品為設計主軸，材質上選用純銀、異材質與色彩鮮明的半寶石。\n' +
            '半寶石，是agete始終如一的堅持。不同於其他品牌，對珍貴的紅藍寶石等五大寶石的衷愛，agete選擇傾心於樣貌璞濁，紋理感不ㄧ的瑪瑙與玉髓。吸取大地的精氣，擁有獨特的紋彩與光輝，每件寶石都如同配戴者們，獨一無二。\n',
        ],
        images: [
          {
            source: agete_03,
            ratio: 960 / 1340,
          },
          {
            source: agete_04,
            ratio: 960 / 1340,
          },
          {
            source: agete_05,
            ratio: 960 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【現今的agete】',
    data: [
      {
        context: [
          '在流行趨勢快速變化的Z世代，agete並不追隨潮流，而是堅定地守護品牌的的初衷：將顧客視為自己的家人，並且永遠為顧客提供經典且多樣化的搭配選擇。從2000年開始，agete逐漸揮別90年代的古樸風格，將日本人血液中追求細緻與完美的DNA融入輕珠寶中，讓商品邁入精緻化、優雅化。\n' +
            '另一方面，當歐美珠寶大牌都選擇以18K金為創作主流時，agete開創色澤柔美且富有韻味的10K金作為珠寶材質，成為日本第一個將10K金加入輕珠寶創作的品牌鼻祖。\n' +
            'agete以細緻深遠的作工，搭配優雅鏤花線條，展現沉靜柔美之姿，傳達成熟洗鍊的女性美。然而，在亦趨精緻的同時，agete為了延續品牌草創時期的歐洲古董風氣，仍將十字架、馬蹄鐵、鑰匙、蜜蜂、太陽、蛇等對於西洋文化來說具有幸運象徵的元素融入輕珠寶的靈感發想中，讓戴上飾品的妳有如戴上守護符一般溫暖、高雅。agete出神入化的鍊金術功力，將各單品化作似乎可融入佩戴者肌膚的skin jewelry。在材質的選擇上，agete也漸漸開始將天然母貝、馬鼻珍珠、象牙與龜甲殼融入設計當中，讓飾品更趨於豐富細膩。在agete全系列中，金舊有俗氣、招搖的刻版印象被一掃而空，只在回眸行走一舉一動間，閃出為之一亮的光芒 。\n' +
            'agete輕珠寶，就像一股似有若無的清新空氣，帶領妳到雲霧繚繞的美好夢境中。\n',
        ],
        images: [
          {
            source: agete_06,
            ratio: 960 / 1340,
          },
          {
            source: agete_07,
            ratio: 960 / 1340,
          },
          {
            source: agete_08,
            ratio: 960 / 1340,
          },
        ],
      },
    ],
  },
];

export const AboutTheSelfPortrait = [
  {
    title: '',
    data: [
      {
        context: [],
        images: [
          {
            source: self_portrait_logo,
            ratio: 363 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【self-portrait品牌起源】',
    data: [
      {
        context: [
          'Self-Portrait的創始人兼創意總監Han Chong 1979年出生於馬來西亞檳城，18歲時搬至吉隆玻學習藝術，Han後來才發現時尚才是他的熱情所在。但父母認為時尚在馬來西亞缺乏發展的空間，為了不讓人生留下遺憾，22歲時毅然決然前往名聲響亮的英國中央聖馬丁設計學院(Central Saint Martins) 修讀服裝設計。\n' +
            '畢業後Han 曾與Topshop 等多個英國品牌的設計團隊工作。2011年，他與 Yvonne Hoang共同創立了女裝品牌 Three Floor。在 Three Floor 短暫工作一段時間後，Han希望能有自己的品牌，於是在 2013年創立了Self-Portrait。\n' +
            '從Self-Portrait (自畫像)這個品牌名稱上，便可知看出計師Han Chong與藝術的淵源。自畫像從古至今在藝術界都是重要的一環，甚至現在演變成自拍、代表每個獨立個體。\n',
        ],
        images: [
          {
            source: self_portrait_01,
            ratio: 938 / 1358,
          },
        ],
      },
    ],
  },
  {
    title: '',
    data: [
      {
        context: [
          '作為一個2013年創立的設計師品牌，Self-Portrait的品牌成長也相當亮眼：\n' +
            '•\t2014年產品上線 Net-a-Porter 後24小時內售罄\n' +
            '•\t截至2017年5月31日，銷售額同比2016年上升70％，營業利潤翻了三倍\n' +
            '•\t2018年品牌銷售金額已達1.3億英鎊 (約52億台幣)\n' +
            '在變遷快速的服裝產業中，Han憑著熱情以及對消費者的洞悉，闖出了屬於Self-Portrait的一片天。短短7年裡，Self-Portrait擁有375家店，覆蓋全球70個國家，品牌網站每星期有4萬位顧客造訪，每年兩次在紐約時裝週上亮相。Self-Portrait在時尚日曆上已成為可識別的標籤。 此外，品牌還在紅地毯和名人活動上大獲成功。\n',
        ],
        images: [
          {
            source: self_portrait_02,
            ratio: 1600 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【self-portrait精神】',
    data: [
      {
        context: [
          '可觸及的時尚\n' +
            '創始人兼創意總監Han Chong表示：「從第一天起，我就想做一個設計精美，但價格平易近人的產品系列，因為市場上不是快時尚品牌，就是精品品牌，這兩者中間的市場是空白的。好的設計不應只有一少部份人能擁有，每個人都應該享有不需要花太多錢就能擁有漂亮衣服的權利」\n' +
            'Han 承認一開始很難在定價上保持競爭力：「我花了很多精力指導員工們如何進行精加工，並花了很多時間在確保合作工廠生產產品都能達到我想要的品質，在尋找合適的材質上也做了很多工作。如果你花很多心思研究這些過程，那麼在這個價格段保持競爭優勢是可以實現的。」\n' +
            '「如果真的想要使用昂貴的布料，可以使用一部份在最佳展示部位，再混搭平價材質，使用一部分，以取得設計和價格之間的一個平衡。」\n' +
            '事實證明，Han的混合編織設計已成為備受追捧的獨特賣點。\n',
        ],
        images: [
          {
            source: self_portrait_03,
            ratio: 970 / 1340,
          },
          {
            source: self_portrait_04,
            ratio: 970 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【self-portrait設計風格與特色】',
    data: [
      {
        context: [
          'Self-Portrait產品設計以雕花鏤空蕾絲、網紗、印花、幾何等元素構成品牌鮮明的風格，輪廓充滿現代感，細膩的剪裁展現女性柔美性感曲線，恰到好處的暴露並加入湯姆男孩前衛風格，讓你不需花費過多力氣，就可以成為人群中焦點。\n' +
            '許多名人、時尚和娛樂界最著名的人物都穿過Self-Portrait衣服，包括：凱特王妃-凱特密道頓(Kate Middleton)、前美國第一夫人-蜜雪兒歐巴馬(Michelle Obama)、女星-泰勒絲(Taylor Swift)、克莉絲汀·史都華 (Kristen Stewart)、超模-吉吉哈蒂(Gigi Hadid)，米蘭達可兒(Miranda Kerr)、時尚部落客-Aimee Song，Chiara Ferragni以及台灣女星-林志玲、孫芸芸、韓國女團Blackpink等人都曾穿上Self-Portrait公開現身。\n' +
            '品牌產品系列包括：以洋裝為主的服飾、鞋履、太陽眼鏡、配件及泳裝。\n',
        ],
        images: [
          {
            source: self_portrait_05,
            ratio: 970 / 1340,
          },
          {
            source: self_portrait_06,
            ratio: 1600 / 1340,
          },
          {
            source: self_portrait_07,
            ratio: 1600 / 1340,
          },
        ],
      },
    ],
  },
];

export const AboutTheIsabelMarant = [
  {
    title: '',
    data: [
      {
        context: [],
        images: [
          {
            source: isabel_marant_logo,
            ratio: 363 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【Isabel Marant品牌起源】',
    data: [
      {
        context: [
          '故事開始於1989年，當年僅22歲的Isabel Marant創立了他的第一個針織與運動衫品\n' +
            'Twen。在1995年，他在一個空建築物的庭院哩，舉辦了同名的第一場走秀，並請她的朋友們\n' +
            '協助當模特兒。僅僅三年後，她就在一個舊藝術工作室開了她的第一家精品店。至今，在巴士\n' +
            '底區(Bastille district)的 rue de Charonne仍找的到店鋪原址。\n' +
            '\n' +
            '之後的24年時光，見證了37家精品店的誕生。世事變遷，唯一不變的是Isabel Marant的遠\n' +
            '見。她一如既往地對時尚情有獨鍾。11歲起，她就已明確知道，自己想穿什麼、不想穿什麼。\n' +
            '少女時代的她，穿上自己重新改造的軍裝大衣，搭配父親的毛衣，創造了自己的新風格，大\n' +
            '膽的沉悶守舊的傳統規則。因為對鮮活的布料跟服飾的熱愛，她進入了Studio Bercot學習深造，從此扎根於時尚界。\n' +
            '\n' +
            '當有些人做著時尚美夢，艷羨於雜誌封面的時尚女郎，Isabel Marant正致力於為女性打造適合\n' +
            '街頭漫步的服飾、玩滑板可穿的拉鍊運動衫。她的工作室出品的每一件衣服，都經過其親身試\n' +
            '穿。\n' +
            '她始終鼓勵手工創作，用每一季的系列時裝詮釋傳統手工藝的鮮活力量，她稱之為 [衣物生態學]。如今的Isabel Marant時常穿梭於巴黎、紐約、香港間，對理想矢志不渝，對最早就和她一同奮鬥的員工與合作夥伴真誠如初。\n' +
            '\n' +
            '“Isabel Marant”這個品牌非常的開放、真誠。為了保證品牌的自由度，我沒有選擇一蹴而就。而是一步步腳踏實地的將其發展起來。” \n',
        ],
        images: [
          {
            source: isabel_marant_01,
            ratio: 960 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【Isabel Marant精神】',
    data: [
      {
        context: [
          '真實 / AUTHENTIC\n' +
            '\n' +
            ' 真誠、質樸、不加刻意的修飾。有時帶了些許的野性與灑脫，但絲毫沒有高高在上的疏離。\n' +
            '\n' +
            ' 滿懷誠意，做誠實、正直和真實的自己。品牌的背後並非是沒有溫度的衣服，而是可以確實改變一切的個人。\n' +
            '\n' +
            '“你無法也不應該勸說別人嘗試你自己不想穿著的衣服。”\n' +
            '\n' +
            ' 這種對自己忠實不但不會阻礙創造力，相反，還會成為創意的泉源。真實意味著要衝破所謂的傳統或方向，為自己選擇發聲，做出立場：我們不需要取悅他人，我們只要做最真實的自己。\n' +
            '\n' +
            '開放 / OPEN\n' +
            '\n' +
            '用開啟的視角來看待這個世界，以現代、隨性的精神來體會處世之道。融合多樣的街頭文化和不同的設計風格，兼收並蓄，即使結果預設想有所不同。\n' +
            '\n' +
            '生活就像拿著手提箱在路上行走，你永遠不知道何時需要打開它和其他人分享你的珍藏。\n' +
            '\n' +
            '開放的思想深深植根於巴黎，這座無論是街道、咖啡館還是民眾都會讓人眼前一亮的城市。\n' +
            '\n' +
            '開放的思想，也體現在 Isabel Marant 想要為不同類型的女性設計服裝訴求上。\n' +
            '\n' +
            '默契 / COMPLICIT\n' +
            '\n' +
            '默契，如同家族的精神。故事開始於三個女孩之間的友誼，並延續至今。在冒險、活力與引導的集合下，一個全新的品牌，如幸運符一般的誕生了。\n' +
            '\n' +
            '這種特別，真實存在，卻又可遇而不可求。它輻射再了品牌的理念上，即客戶與服飾之間存在一種強烈的依存感。服飾，就像客戶的一個老朋友，任歲月荏苒它依舊在那兒。\n' +
            '\n' +
            '同時，這也是向其高標準與簡潔明瞭的設計致敬，恰到好處不帶一絲張揚。\n' +
            '\n' +
            '創新精神 / A PIONEERING SPIRIT\n' +
            '\n' +
            '自1994年品牌創立以來，創新精神就一直貫穿始終。無論是亞麻T恤、還是裸靴、荷葉邊短裙，這些時尚單品一直因為簡單而引人注目，歷久彌新。\n' +
            '\n' +
            'Isabel Marant 抓住了時代的精隨，憑藉著對時尚的熱情，其最早的作品就引領著流行方向。但她從來不在時尚界的瞬息萬變中隨波逐流，也不會為了搶佔市場而改變設計。\n' +
            '她的美學主張總能帶給人一種與眾不同的享受，一種出其不意的驚喜。\n' +
            ' \n' +
            '創新無處不在。它也許是夾克的別緻剪裁，也許是某種展現的別樣風情，也許是所有凝聚在一起所傳遞給客戶。\n' +
            '\n' +
            '流暢 / THE FLOW\n' +
            '將服飾帶給你的喜悅與質感融入生活，是 Isabel Marant 恆久承諾。\n' +
            '\n' +
            '輕鬆、舒適、隨性、靈動，這些都是 Isabel Marant 的一貫主張。飄逸間不帶一絲拖沓。\n' +
            '\n' +
            '選擇將時尚融入日常，輕鬆文雅兼具型格與性格。沒有紅毯設計的瑣碎，沒有禮服盛裝的高調，一切以實用主義為主。\n' +
            '\n' +
            '“看似漫不經心的搭配，卻處處凸顯匠心獨具。”\n' +
            ' 展現出來的優雅沒有半分的刻意或是矯揉造作。它透露出的率性更是女性主義酷感態度的巧妙碰撞和完美結合。\n' +
            '\n' +
            'LA DÉGAINE\n' +
            '\n' +
            'La Dégaine 的字面含意是拿出武器。時尚，作為個人的獨特武器，在武裝自己的同時，也可以讓其他人繳械投降。\n' +
            '\n' +
            '時尚不僅只是外表的概念，更是性格的展現 -- 線條間彰顯對自我的肯定和對獨立的追求。\n' +
            '\n' +
            '在這個概念下，才有了不同尋常的特立獨行。當風格、布料、圖案產生了激烈的對比與碰撞，一種非凡的、不對稱的、有缺憾的美感油然而生，它來源於好奇，卻產生了難以描述的魔力。\n' +
            '\n' +
            'Dégaine 是對經典非刻板的巴黎女性的形容，代表了  Isabel Marant 的風格。\n' +
            '\n' +
            'ISABEL MARANT 女孩\n' +
            '\n' +
            '我們在選詞上使用的是女孩而不是女性。 Isabel Marant 女孩，在生活中不走尋常路，灑脫、獨立、朝氣滿滿。\n' +
            '\n' +
            '成群結伴時，他們看上去猶如煙花般燦爛。但單看時，每一位卻又性格鮮明，各具特色。圍繞他們的是沒有敵意的友情，是戀人至上的愛情。他們平易近人，早已摒棄了自我陶醉的法式驕傲。\n' +
            '\n' +
            '他們享受被人喜愛，喜歡梳妝打扮。他們的隨性無論何時都流露出一種性感、可愛、天然去雕飾的優雅。“我不漂亮，但這就是我。”即使是假小子，也有女孩的一面。\n' +
            '\n' +
            '對巴黎女性來說，時尚是他們與生俱來的獨特能力。他們大膽，卻從不過火，在巴黎寬鬆的氛圍下，形成了屬於 Isabel Marant 的Dégaine 風格。\n',
        ],
        images: [
          {
            source: isabel_marant_02,
            ratio: 960 / 1340,
          },
        ],
      },
    ],
  },
];

export const AboutTheLesNereides = [
  {
    title: '',
    data: [
      {
        context: [],
        images: [
          {
            source: les_nereides_logo,
            ratio: 363 / 1340,
          },
        ],
      },
    ],
  },
  {
    title: '【Les Néréides 品牌起源】',
    data: [
      {
        context: [
          'Les Néréides成立於1980年法國南部的尼斯(Nice)，至今已有41年的歷史。\n' +
            '\n' +
            'Les Néréides在希臘神話中象徵著「海洋女神」的寓意，這一富有浪漫餘韻的品牌靈魂，源自創始人暨設計總監Pascale的畫家爺爺。從自身為藝術家的切角來說，Pascale的爺爺一直對「Les Néréides」這象徵著50位守護航海員與漁民的海洋女神們有著無可自拔的迷戀，並且讓Pascale在成長的過程浸泡於藝術的樂園之中。也正是因為爺爺如此浪漫到無可救藥的DNA，才孕育了品牌如詩如畫、充滿藝術氣息的飾品創作。\n',
        ],
        images: [
          {
            source: les_nereides_01,
            ratio: 676 / 1358,
          },
        ],
      },
      {
        context: [
          '而它實際的誕生，是來自於一段美好愛情的相遇―Pascale與Enzo Amaddeo這對充滿浪滿想法的設計師夫妻。二人分別在15及20歲時在比利時藝術學校遇見彼此，一見鐘情，從此互訂終生，而這段愛情火花的誕生，也正是品牌的旅程起點。',
        ],
        images: [
          {
            source: les_nereides_02,
            ratio: 676 / 1358,
          },
        ],
      },
    ],
  },
  {
    title: '【Les Néréides 創作基因】',
    data: [
      {
        context: [
          '從最剛開始時，「花朵」的嬌豔美麗就深深吸引著Pascale與Enzo，自1980年開始，花朵一直都是Les Néréides的靈感發想。 他們曾經這樣形容過花朵的爛漫：「花園是一個雅緻且浪漫的世界，它隨著季節的變化，以及種類的不同，她們可以纖弱、勇敢、憂鬱，也可以陽光，是可塑性非常強的題材。」\n' +
            'Les Néréides就像個精心的插花藝術家，一點一滴用飾品創作去傳達出花卉的生命與感染力，讓更多人能感知到花朵的一顰一笑、一喜一悲。 \n',
        ],
        images: [
          {
            source: les_nereides_03,
            ratio: 938 / 1358,
          },
        ],
      },
    ],
  },
  {
    title: '【Les Néréides 琺瑯工藝】',
    data: [
      {
        context: [
          '琺瑯工藝設計常見於Les Néréides花園系列，眾多顏色的搭配與協調性皆可見其設計師玩味色彩的功力，而花園系列主體材質多為金屬基底手繪琺瑯上色，搭配人造切割水晶、琉璃珠、淡水珍珠、萊茵石、結晶樹酯。\n' +
            '琺瑯原始材料為玻璃磨成的細緻粉末後再經加工製成的顏料，而每次上色都需要經過750℃的高溫燒製，因此Les Néréides的每件琺瑯工藝珠寶都至少需要經過三至五小時的工時，藉此呈現其擬真與繁複的設計。\n' +
            '琺瑯工藝一直為Les Néréides最為人稱道的工法，設計師擅長使用手工琺瑯上色，讓珠寶呈現類似陶瓷的光澤度與色感，以此增加設計上的高級質感，如此紛繁的設計工藝，只為了讓每位配戴上飾品的妳閃耀出與眾不同的光芒。\n',
        ],
        images: [
          {
            source: les_nereides_04,
            ratio: 938 / 1358,
          },
          {
            source: les_nereides_05,
            ratio: 938 / 1358,
          },
        ],
      },
    ],
  },
];

export const MaintainKnowledge = [
  {
    title: '飾品:',
    data: [
      '有些膚質配戴首飾可能引起發癢或過敏等現象。請停止使用，諮詢皮膚科醫師的意見。',
      '請勿在運動、洗澡、就寢、照顧幼兒等時候配戴首飾，以免損壞。',
      '請避免兒童，幼兒，或寵物吞噬的危險，配戴及保管時請格外注意。',
      '首飾經不起碰撞，請避免碰撞或掉落。',
      '請勿在高溫場所(桑拿浴等)或寒冷場所(滑雪場等)配戴首飾，以免損壞或受傷。',
      '有些設計會纏繞衣服纖維或毛髮，配戴或取下時請格外注意。',
      '金屬及染色材料(樹酯與皮革等)，遇水遇汗或摩擦時會有沾色現象，與淡色系服裝配用時，請格外注意。',
      '因商品的狀態、加工技術、配料等原因，或有無法修理的情況，敬請諒解。',
      '與化妝品、香水、洗滌劑、海水、溫泉、紫外線等接觸會引起反應變色，請格外注意。',
    ],
  },
  {
    title: '鑲有天然寶石的商品:',
    data: [
      '珍珠、綠寶石、琥珀、貝殼等敏感材料，與水、化妝水、藥品、香水等接觸，會發生變色反應，並會失去原有質感，使用時請注意。此外，受熱或過度乾燥會產生裂紋，請避免在高溫或陽光直射處保管。 鑲有寶石的部分可能因碰撞而鬆動掉落，使用時請注意。並且，浸水會使黏合劑脫落，做飯、洗衣、洗澡、游泳時請避免配戴。',
    ],
  },
  {
    title: '珍珠商品:',
    data: [
      '珍珠的主要成分為碳酸鈣，有不耐酸、熱、水的性質，配戴時請不要讓珍珠沾上以下物品:',
      '食品: 果汁類、酒類、水果、醋、蛋黃醬、調味汁、咖啡、茶、酒類、醬油、食用油',
      '洗滌劑: 漂白劑、藥品、餐具洗滌劑',
      '醫藥與化妝品: 指甲油、髮膠、酒精、漱口水、防曬霜、乳液等',
      '其他: 油墨、油漆等',
      '如不慎沾上，請立刻用水清洗後用軟布擦拭晾乾。',
      '避免陽光直射，以避免珍珠變黃。',
    ],
  },
  {
    title: '日常保養:',
    data: [
      '配戴後請擦去汗水與污漬，避免直射陽光與灰塵，請在低溫場所收納。',
      '珍珠商品容易引起刮痕，請勿與其他首飾或硬物放在一起收納。',
    ],
  },
  {
    title: '服裝:',
    data: ['服裝收納保養知識:'],
  },
  {
    title: '1. 洗滌方式 - 您可注意:',
    data: [
      '請看您購買商品上的洗標。因每件商品材質不同，請依照洗標做適合的清潔。',
      '若有建議乾洗的標示，請依建議處理。',
      '以下為不同布料洗滌時的基礎注意事項:',
      '雪紡: 洗滌時請先將裝飾部分分開洗滌，請勿大力搓揉，不宜曝曬。',
      '純棉: 建議使用專用清潔劑，注意深淺顏色衣物要分開，不宜曝曬。',
      '牛仔布: 洗滌之前，建議先進行簡單的固色處理，級在水中加入少許鹽，輕輕搓揉即可。',
      '針織: 冷水輕揉搓洗，為保證毛線色澤，可在水中滴入一點點的醋，平鋪晾乾。',
      '毛呢: 冷水洗滌，洗滌時間不宜過長，不要用力擰乾，用手壓乾即可，不可曝曬。乾洗較合適。',
      '化纖: 面料對熱敏感，用溫水洗滌浸泡時間不宜久，也不要和深色衣物混洗。',
    ],
  },
  {
    title: '2. 熨燙須知:',
    data: [
      '* 傳統壓式熨斗:',
      '將衣服放燙衣板上，從熨斗的入水口加水後(如無加水口請務必先將衣服噴濕在進行熨燙)，\n' +
        '依機身轉盤指示選擇衣服材質或溫度，溫度範圍約60℃左右低溫熨燙，等熨斗加熱指示燈熄滅\n' +
        '後，就可開始燙衣服。',
      '應依洗標指示溫度燙衣服，其中絲綢、粘纖、聚酯纖維等材質衣服易因高溫變形，\n' +
        '燙前需在“衣服上放毛巾“再整燙，避免衣服上纖維因溫度太高導致捲曲、破損、溶化等情形\n' +
        '發生。',
      '* 立式熨斗:',
      '又稱掛燙機，將衣服用衣架掛在熨斗支架上，不需調節溫度，手持蒸氣噴嘴來回刷燙，\n' +
        '蒸氣會穿透衣服纖維，讓纖維鬆開，達到衣物平整效果。但像是棉麻、尼龍類的衣服若皺褶\n' +
        '太嚴重，就不易整平。',
    ],
  },
  {
    title: '3. 特別品項保養小知識 - 毛呢大衣:',
    data: [
      '無法頻繁清洗的毛呢大衣，平日的隨手保養是非常重要的功課。',
      '* 衣刷:',
      '如果大衣上有令人在意的灰塵或是髒污，這時可以使用衣刷來將它清掃乾淨，順著衣領到肩頭、\n' +
        '手臂的順序，由上而下清刷。需要特別處理的地方，則要宛如逆著衣服表質纖維般，從下而上\n' +
        '輕刷，讓灰塵刷落。',
      '※ 必須要注意，過度的清刷可能會有損於大衣質感。',
      '* 衣架:',
      '收入衣櫥時，請別忘了要用較粗且厚實的大衣衣架掛上收納。',
      '* 互相穿搭:',
      '最喜愛的外套總是會讓人每天想穿上它，但隨著使用頻率越高，不僅大衣外型會逐漸變形，\n' +
        '質地也容易產生磨損的情形，建議與其他外套互相輪流穿搭，減少使用頻率，這樣才能長久\n' +
        '保持最初的完美質感。',
      '* 起毛球的保養法:',
      '肩膀、袖口及腰部等容易產生摩擦的地方，總是容易起毛球。與其放著不管，不如趁早將起\n' +
        '毛球的部分清理乾淨吧。',
      '如用指尖拔取毛球，會拉扯到衣服材質而造成損傷，因此必須使用一些道具輔助。推薦使用\n' +
        '尖端較細的剪刀。將起毛球的部分放平，不要剪到布料，小心翼翼的將毛球剪下。',
      '也很建議使用市面上所販賣的電動取毛球機。將大衣放在平坦的地方，無須壓太大力，只要\n' +
        '輕輕的順過起毛球的部分。※有時會發生因毛球太細小，而無法吸起的情形。',
    ],
  },
  {
    title: '4. 特別品項保養小知識 - 白色衣物:',
    data: [
      '白色衣物好搭也耐看。是每人衣櫥必備。但如何避免容易泛黃? 正確的使用方式很重要。',
      '* 穿一次就要洗:',
      '千萬別想說沒流汗就不用洗了！白T基本上有濕氣就會發黃，姑且不論流汗與否，光穿在人\n' +
        '身上、空氣中的溼度偏高這些濕氣都會影響白T，想阻止泛黃、延長壽命，建議穿一次就洗',
      '*手洗為佳，建議用洗衣袋',
      '白T跟白色衣物，就跟內衣褲一樣，最佳的洗滌方式為冷水手洗。若要方便一點，在洗白色\n' +
        '衣物時，除了放進洗衣袋避免拉扯變形外，更建議可以翻面清洗，可以減緩毛球增加速度。',
      '* 禁止高溫',
      '白T & 白色棉質衣物，對於高溫的耐受力很低，碰到高溫就可能造成泛黃，還是建議大家用\n' +
        '冷水清洗、陰乾（太陽曬乾也可能因溫度過高而泛黃）使用烘乾機的朋友請選擇低溫，才能像\n' +
        '剛買一樣的好看。',
      '* 換季收納，[掛]起來更好',
      '前面不斷提到，白T&白色棉質衣物，不耐高溫與潮濕，換季收納時相對乾爽的收納方式為\n' +
        '「掛起來」，比整個摺在箱子裡理想許多，可以的話，為白T多罩上一層透氣的防塵袋，之後\n' +
        '拿出來照樣完好。',
    ],
  },
];

export const AboutBrandContext = {
  kenzo: AboutTheKenzo,
  'self-portrait': AboutTheSelfPortrait,
  agete: AboutTheAgete,
  'isabel-marant': AboutTheIsabelMarant,
  'les-nereides': AboutTheLesNereides,
};
