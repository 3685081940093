const HINT_TYPE = {
  REQUIRED: '不可為空',
  PHONE_ERROR: '手機格式錯誤',
  EMAIL_ERROR: '電子信箱格式不正確',
  PASSWORD_ERROR: '密碼需為 8-12 位英數字組合',
  BIRTHDATE_REQUIRED: '請選擇出生年月日',
  INVOICE_CARRIER_ERROR: '手機條碼格式不正確',
  INVOICE_TAX_ERROR: '統一編號格式不正確',
};

export class Chain {
  constructor(fn) {
    this.fn = fn;
    this.successor = null;
  }

  setNextSuccessor(successor) {
    return (this.successor = successor);
  }

  passRequest() {
    const ret = this.fn.apply(this, arguments);
    if (ret === 'nextSuccessor') {
      return (
        this.successor &&
        this.successor.passRequest.apply(this.successor, arguments)
      );
    }
    return ret;
  }
}

const nextSuccessor = 'nextSuccessor';

export const is_required = value => {
  if (value !== '' && value !== 0) {
    return nextSuccessor;
  } else {
    return HINT_TYPE.REQUIRED;
  }
};

export const is_all_number = value => {
  if (/^[0-9]*$/.test(value)) {
    return nextSuccessor;
  } else {
    return HINT_TYPE.PHONE_ERROR;
  }
};

export const is_first_two_letter_should_be_zero_nine = value => {
  if (/^09/.test(value)) {
    return nextSuccessor;
  } else {
    return HINT_TYPE.PHONE_ERROR;
  }
};

export const is_length_should_be_12 = value => {
  if (value.length === 10) {
    return nextSuccessor;
  } else {
    return HINT_TYPE.PHONE_ERROR;
  }
};

export const is_email = value => {
  if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
    return nextSuccessor;
  } else {
    return HINT_TYPE.EMAIL_ERROR;
  }
};

export const is_password = value => {
  const reg = /^[A-Za-z0-9]{8,12}$/;
  const containNumberReg = /[0-9]+/;
  const containLetterReg = /[A-Za-z]+/;

  if (
    reg.test(value) &&
    containNumberReg.test(value) &&
    containLetterReg.test(value)
  ) {
    return nextSuccessor;
  } else {
    return HINT_TYPE.PASSWORD_ERROR;
  }
};

export const is_birthdate_required = value => {
  if (value !== '') {
    return nextSuccessor;
  } else {
    return HINT_TYPE.BIRTHDATE_REQUIRED;
  }
};

export const is_invoice_carrier = value => {
  if (/^\/[0-9A-Z+-.]{7}$/g.test(value)) {
    return nextSuccessor;
  } else {
    return HINT_TYPE.INVOICE_CARRIER_ERROR;
  }
};

const is_invoice_tax = value => {
  return /^\d{8}$/.test(value) ? nextSuccessor : HINT_TYPE.INVOICE_TAX_ERROR;
};

export const endOfChain = () => {
  return null;
};

export const getRequiredValidator = () => {
  const isRequired = new Chain(is_required);
  const end = new Chain(endOfChain);
  isRequired.setNextSuccessor(end);
  return isRequired;
};

export const getPhoneValidator = () => {
  const isRequired = new Chain(is_required);
  const isAllNumber = new Chain(is_all_number);
  const isFirstTwoLetterShouldBeZeroNine = new Chain(
    is_first_two_letter_should_be_zero_nine,
  );
  const isLengthShouldBe12 = new Chain(is_length_should_be_12);
  const end = new Chain(endOfChain);

  isRequired.setNextSuccessor(isAllNumber);
  isAllNumber.setNextSuccessor(isFirstTwoLetterShouldBeZeroNine);
  isFirstTwoLetterShouldBeZeroNine.setNextSuccessor(isLengthShouldBe12);
  isLengthShouldBe12.setNextSuccessor(end);
  return isRequired;
};

export const getEmailValidator = () => {
  const isRequired = new Chain(is_required);
  const isEmail = new Chain(is_email);
  const end = new Chain(endOfChain);
  isRequired.setNextSuccessor(isEmail);
  isEmail.setNextSuccessor(end);
  return isRequired;
};

export const getPasswordValidator = () => {
  const isRequired = new Chain(is_required);
  const isPassword = new Chain(is_password);
  const end = new Chain(endOfChain);
  isRequired.setNextSuccessor(isPassword);
  isPassword.setNextSuccessor(end);
  return isRequired;
};

export const getForgotPasswordValidator = () => {
  const isPassword = new Chain(is_password);
  const end = new Chain(endOfChain);
  isPassword.setNextSuccessor(end);
  return isPassword;
};

export const getBirthdateValidator = () => {
  const isBirthdateRequired = new Chain(is_birthdate_required);
  const end = new Chain(endOfChain);
  isBirthdateRequired.setNextSuccessor(end);
  return isBirthdateRequired;
};

export const getInvoiceCarrierValidator = () => {
  const isInvoiceCarrier = new Chain(is_invoice_carrier);
  const end = new Chain(endOfChain);
  isInvoiceCarrier.setNextSuccessor(end);
  return isInvoiceCarrier;
};

export const getInvoiceTaxValidator = () => {
  const isInvoiceTax = new Chain(is_invoice_tax);
  const end = new Chain(endOfChain);
  isInvoiceTax.setNextSuccessor(end);
  return isInvoiceTax;
};

export const validateForm = ({values, errors, setErrors, validators}) => {
  const _errors = {...errors};

  Object.keys(validators).forEach(
    field => (_errors[field] = validators[field].passRequest(values[field])),
  );

  setErrors(_errors);

  return Object.values(_errors).every(error => error === null);
};
