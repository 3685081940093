import React from 'react';
import {navigate} from 'gatsby';
import {brandNameToBrandId, brandsArr, brandsDisplayArr} from '../Domain/Brand';
import queryString from 'query-string';
import * as StaticData from '../StaticData';
import Api from '../Api';

const Context = React.createContext();
const Actions = {};
export const NavActions = {};
export const Breakpoints = {
  tablet: 1023,
  mobile: 640,
};

export const BrandProductImageRatio = {
  // ratio: h/w
  kenzo: 1,
  'self-portrait': 1,
  agete: 1,
  'isabel-marant': 1,
  'les-nereides': 1,
};

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      activeBrand: null,
      fetchingCats: false,
      fetchingInitial: false,
      brandCats: null,
      brandSeries: null,
      homeData: null,
      cart: null,
      showMiniCart: false,
      runtimeCheckoutData: null,
      runtimeProfileCvsData: null,
      allStoreData: null, // {brand_1: [...], brand_2: [...]}
      breakpoints: Breakpoints,
      brandProductImageRatio: BrandProductImageRatio,
      showActivityIndicator: false,
      globalToastContent: null,
      globalModalContent: null,
      globalSlideDownContent: null,
      points: {
        useBirthGiftPoints: null,
        useRebatePoints: null,
      },
      isUsePoints: false,
      favStoresByBrand: null,
      scrollingTexts: [],
      navType: 'solid',
    };

    NavActions.navigate = (route, options) => {
      const {replace = false} = options || {};
      let [pathname, queryStr] = route.split('?');
      let _nextRouteQuery = '';
      const shouldAppendBrandGroup =
        pathname === '/home' ||
        pathname === '/stores' ||
        pathname === '/product' ||
        pathname === '/products' ||
        pathname === '/series' ||
        pathname === '/news' ||
        pathname === '/news/detail' ||
        pathname === '/event' ||
        pathname === '/aboutTheBrand';

      try {
        if (queryStr) {
          const parsed = queryString.parse(queryStr);
          if (shouldAppendBrandGroup && !parsed?.brand) {
            parsed.brand = Actions.getActiveBrand();
          }
          const brand = parsed.brand;
          if (brandsArr.indexOf(brand) !== -1) {
            Actions.setActiveBrand(brand);
            _nextRouteQuery = queryString.stringify(parsed);
          } else {
            _nextRouteQuery = queryString.stringify(parsed);
          }
        } else {
          if (shouldAppendBrandGroup) {
            _nextRouteQuery = `brand=${Actions.getActiveBrand()}`;
          }
          if (pathname === '/product' || pathname === '/products') {
            navigate('/404');
            return;
          }
        }
      } catch (err) {
        console.warn(err);
      }
      navigate(`${pathname}${_nextRouteQuery ? `?${_nextRouteQuery}` : ''}`, {
        replace,
      });
    };

    Actions.setNavType = navType => {
      this.setState({navType: navType});
    };

    Actions.setLoading = loading =>
      this.setState({showActivityIndicator: loading});

    Actions.setGlobalToastContent = elem =>
      this.setState({globalToastContent: elem});

    Actions.setGlobalModalContent = elem =>
      this.setState({globalModalContent: elem});

    Actions.setGlobalSlideDownContent = elem =>
      this.setState({globalSlideDownContent: elem});

    Actions.getBreakpoints = () => this.state.breakpoints;

    Actions.getCurrentBrandDisplay = () => {
      if (!this.state.activeBrand) {
        return '';
      }
      return (
        brandsDisplayArr.find(b => b.key === this.state.activeBrand)?.display ||
        ''
      );
    };

    Actions.loadInitial = ({defaultBrand = null} = {}) => {
      this.setState({fetchingInitial: true});
      // priority: queryString > localStorage > x
      let nextActiveBrand = null;
      try {
        if (defaultBrand && brandsArr.indexOf(defaultBrand) !== -1) {
          // cannot find brand, rollback to localStorage
          nextActiveBrand = defaultBrand;
        } else {
          nextActiveBrand = window.localStorage.getItem('activeBrand');
        }
      } catch (err) {}

      if (brandsArr.indexOf(nextActiveBrand) !== -1) {
        window.localStorage.setItem('activeBrand', nextActiveBrand);
      } else {
        nextActiveBrand = null;
        window.localStorage.setItem('activeBrand', '');
      }

      this.setState({fetchingInitial: false, activeBrand: nextActiveBrand});
      return {activeBrand: nextActiveBrand};
    };

    Actions.loadHomeData = async ({forceUpdate = true} = {}) => {
      let brandHomeData = this.state.homeData;
      if (forceUpdate || !brandHomeData) {
        brandHomeData = await Api.getBrandHomeData(this.state.activeBrand);
      }
      let resultHomeData = brandHomeData
        ? {
            ...brandHomeData,
            categories: this.state.brandCats?.[this.state.activeBrand] || [],
          }
        : null;
      this.setState({homeData: resultHomeData});
      return resultHomeData;
    };

    Actions.getBrandStores = async ({
      forceUpdate = false,
      brand = null,
    } = {}) => {
      let _brandToFetch = brand || this.state.activeBrand;
      let result = null;
      if (forceUpdate || !this.state.allStoreData?.[_brandToFetch]) {
        result = await Api.getBrandStores(_brandToFetch);
        return result;
      }
      return this.state.allStoreData[_brandToFetch];
    };

    Actions.setAllBrandStores = values => {
      this.setState({allStoreData: values});
    };

    Actions.setActiveBrand = b => {
      if (brandsArr.indexOf(b) !== -1) {
        try {
          window.localStorage.setItem('activeBrand', b);
          Actions.fetchScrollingTexts(b);
        } catch (err) {
          //
        }
        this.setState({activeBrand: b});
      }
    };

    Actions.getActiveBrand = () => {
      return this.state.activeBrand;
    };

    Actions.fetchAllCats = async () => {
      let resultCats = {};
      this.setState({fetchingCats: true});
      for (let b of brandsArr) {
        resultCats[b] = await Api.getBrandCategories(b);
      }
      this.setState({brandCats: resultCats, fetchingCats: false});
      return resultCats;
    };

    Actions.fetchCategory = async () => {
      let res = await Api.getBrandCategories(this.state.activeBrand);
      this.setState({
        brandCats: {
          ...this.state.brandCats,
          [this.state.activeBrand]: res,
        },
      });
      return res;
    };

    Actions.fetchProducts = async ({
      brand,
      collectionId,
      filter,
      sort,
      offset,
      limit,
      categoryIds,
      subCollectionIds,
      seriesId,
      keyword,
    }) => {
      const collectionIds = !Array.isArray(collectionId)
        ? [collectionId]
        : collectionId;

      return await Api.fetchProducts({
        brand,
        filter,
        sort,
        offset,
        limit,
        categoryIds,
        collectionId: collectionIds,
        subCollectionIds,
        seriesId,
        keyword,
      });
    };

    Actions.fetchProductById = async (id, _brand = null) => {
      let brand = _brand || this.state.activeBrand;
      const result = await Api.fetchProductById(id);
      return {
        ...result,
        info_ship: StaticData.ProductInfoSection[brand].info_ship,
      };
    };

    Actions.queryStockByVariantIds = async (ids = []) => {
      return await Api.queryStockByVariantIds(ids);
    };

    Actions.fetchCart = async ({
      useBirthGift,
      useRebatePoints,
      isUsePoints = null,
      isPreCalculate = false,
    } = {}) => {
      const isUse = isUsePoints === null ? this.state.isUsePoints : isUsePoints;
      const getUsedBirthGift = () => {
        if (isUse) {
          if (Number.isInteger(useBirthGift)) {
            return useBirthGift;
          } else if (Number.isInteger(this.state.points.useBirthGiftPoints)) {
            return this.state.points.useBirthGiftPoints;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      };
      const getUsedRebatePoints = () => {
        if (isUse) {
          if (Number.isInteger(useRebatePoints)) {
            return useRebatePoints;
          } else if (Number.isInteger(this.state.points.useRebatePoints)) {
            return this.state.points.useRebatePoints;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      };
      const res = await Api.fetchCart({
        useBirthGift: getUsedBirthGift(),
        useRebatePoints: getUsedRebatePoints(),
      });
      if (!isPreCalculate) {
        this.setState({cart: res});
      }
      return res;
    };

    Actions.setPoints = async values => {
      const resp = await Actions.fetchCart({
        useBirthGift: values.useBirthGiftPoints,
        useRebatePoints: values.useRebatePoints,
        isUsePoints: values.isUsePoints,
        isPreCalculate: values.isPreCalculate || false,
      });
      this.setState({
        points: {
          useBirthGiftPoints: values.useBirthGiftPoints,
          useRebatePoints: values.useRebatePoints,
        },
      });
      return resp;
    };

    Actions.updateCart = async ({items, useRebatePoints, useBirthGift}) => {
      const res = await Api.updateCart({
        items,
        useBirthGift: this.state.isUsePoints
          ? useBirthGift || this.state.points.useBirthGiftPoints || 0
          : 0,
        useRebatePoints: this.state.isUsePoints
          ? useRebatePoints || this.state.points.useRebatePoints || 0
          : 0,
      });
      this.setState({cart: res});
      return res;
    };

    Actions.setShowMiniCart = show => {
      this.setState({showMiniCart: show});
    };

    Actions.setRuntimeCheckoutData = data => {
      this.setState({runtimeCheckoutData: data});
    };

    Actions.getRuntimeCheckoutData = () => {
      return this.state.runtimeCheckoutData;
    };

    Actions.fetchSeries = async ({seriesId}) => {
      return await Api.fetchSeries({seriesId});
    };

    Actions.fetchAllSeries = async brandId => {
      const _seriesList = await Api.fetchAllSeries(brandId);
      this.setState({brandSeries: _seriesList});
      return _seriesList;
    };

    Actions.fetchSearchData = async keyword => {
      return await Api.fetchSearchData(keyword);
    };

    Actions.getCustomerExist = async phone => {
      return Api.getCustomerExist(phone);
    };

    Actions.fetchOrders = async ({limit, offset, status}) => {
      return Api.fetchOrders({limit, offset, status});
    };

    Actions.fetchOrder = async id => {
      return Api.fetchOrder(id);
    };

    Actions.cancelOrder = async id => {
      return Api.cancelOrder(id);
    };

    Actions.login = async loginSet => {
      try {
        const token = await Api.login(loginSet);
        if (!token) {
          throw 'login failed';
        }

        const me = await Api.fetchMe();

        if (!me) {
          // FIXME: unknown why api still response 200 with null response body when fake token was sent...
          throw 'get profile failed';
        }

        this.setState({
          currentUser: me,
        });

        return token;
      } catch (err) {
        Api.clearToken();
        throw 'auto login failed';
      }
    };

    Actions.autoLogin = async () => {
      const token = Api.getToken();
      try {
        if (!token) {
          throw 'get local token failed';
        }

        const me = await Api.fetchMe();

        if (!me) {
          // FIXME: unknown why api still response 200 with null response body when fake token was sent...
          throw 'get profile failed';
        }

        this.setState({
          currentUser: me,
        });
      } catch (err) {
        Api.clearToken();
        console.error(err);
        // throw 'auto login failed';
      }
      return token;
    };

    Actions.genOtp = async phone => {
      return await Api.genOtp(phone);
    };

    Actions.validateOtp = async ({phone, otp}) => {
      return await Api.validateOtp({phone, otp});
    };

    Actions.register = async customerInfo => {
      return await Api.register(customerInfo);
    };

    Actions.fetchMe = async () => {
      const me = await Api.fetchMe();
      this.setState({currentUser: me});
    };

    Actions.logout = () => {
      return Api.logout();
    };

    Actions.resetPassword = async ({phone, password, otp}) => {
      return await Api.resetPassword({
        password,
        otp,
        phone,
      });
    };

    Actions.fetchNewsList = async ({brand, limit, offset}) => {
      return await Api.fetchNewsList({brand, limit, offset});
    };

    Actions.fetchNews = async id => {
      return await Api.fetchNews(id);
    };

    Actions.getBindCreditCardForm = async path => {
      return await Api.getBindCreditCardForm(path);
    };

    Actions.getMyCreditCards = async () => {
      return await Api.getMyCreditCards();
    };

    Actions.checkout = async data => {
      return await Api.checkout(data);
    };

    Actions.getAllCounties = async () => {
      return await Api.getAllCounties();
    };

    Actions.getAllTowns = async counties => {
      return await Api.getAllTowns(counties);
    };

    Actions.validatePassword = async password => {
      return await Api.validatePassword(password);
    };

    Actions.updateCustomer = async customer => {
      return await Api.updateCustomer(customer);
    };

    Actions.updatePassword = async passwordSet => {
      return await Api.updatePassword(passwordSet);
    };

    Actions.setIsUsePoints = async isUsePoints => {
      await Actions.fetchCart({isUsePoints});
      this.setState({isUsePoints: isUsePoints});
    };

    Actions.fetchPointHistory = async () => {
      return await Api.fetchPointHistory();
    };

    Actions.getPromotions = async () => {
      return await Api.getPromotions();
    };

    Actions.fetchFavStores = async () => {
      return await Api.fetchFavStores();
    };

    Actions.setRuntimeProfileCvsData = data => {
      this.setState({runtimeProfileCvsData: data});
    };

    Actions.setDefaultCard = async id => {
      return await Api.setDefaultCard(id);
    };

    Actions.deleteCard = async id => {
      return await Api.deleteCard(id);
    };

    Actions.fetchMyAddrs = async () => {
      return await Api.fetchMyAddrs();
    };

    Actions.createAddress = async values => {
      return await Api.createAddress(values);
    };

    Actions.updateAddress = async values => {
      return await Api.updateAddress(values);
    };

    Actions.deleteAddress = async id => {
      return await Api.deleteAddress(id);
    };

    Actions.setFavStore = async values => {
      return await Api.setFavStore(values);
    };

    Actions.fetchEvent = async id => {
      return await Api.fetchEvent(id);
    };

    Actions.fetchScrollingTexts = async b => {
      let result = await Api.fetchScrollingTexts(b || this.state.activeBrand);
      this.setState({scrollingTexts: result});
      return result;
    };

    Actions.reCheckout = async values => {
      return await Api.reCheckout(values);
    };

    Actions.fetchSubOrder = async id => {
      return await Api.fetchSubOrder(id);
    };

    Actions.returnPreCalculate = async ({id, items}) => {
      return await Api.returnPreCalculate({id, items});
    };

    Actions.returnOrder = async ({id, values}) => {
      return await Api.returnOrder({id, values});
    };

    Actions.returnOrderDetail = async id => {
      return await Api.returnOrderDetail(id);
    };

    Actions.syncPos = async () => {
      return await Api.syncPos();
    };

    Actions.fetchOrderHistories = async () => {
      return await Api.fetchOrderHistories();
    };

    Actions.fetchGeometry = async location => {
      return await Api.fetchGeometry(location);
    };
    Actions.createMaintainOrder = async values => {
      return await Api.createMaintainOrder(values);
    };

    Actions.getMaintainOrders = async values => {
      return await Api.getMaintainOrders(values);
    };

    Actions.getMaintainOrder = async id => {
      return await Api.getMaintainOrder(id);
    };
    Actions.putMaintainOrderQuotation = async values => {
      return await Api.putMaintainOrderQuotation(values);
    };

    Actions.cancelMaintainOrder = async values => {
      return await Api.cancelMaintainOrder(values);
    };

    Actions.getAllStores = async () => {
      try {
        for (const brand of brandsArr) {
          const stores = await Api.getBrandStores(brand);
          this.setState({
            allStoreData: {...this.state.allStoreData, [brand]: stores},
          });
        }
      } catch (e) {
        for (const brand of brandsArr) {
          this.setState({
            allStoreData: {...this.state.allStoreData, [brand]: []},
          });
        }
      }
    };
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

class Consumer extends React.Component {
  render() {
    return (
      <Context.Consumer>{state => this.props.children(state)}</Context.Consumer>
    );
  }
}

function withConsumer(Comp) {
  class WrappedComp extends React.Component {
    render() {
      return (
        <Consumer>{state => <Comp app={state} {...this.props} />}</Consumer>
      );
    }
  }

  WrappedComp.displayName = `WithConfig-${Comp.displayName}`;
  return WrappedComp;
}

export {Provider, Consumer, withConsumer, Actions, Context};
