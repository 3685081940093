import React from 'react';
import styled from 'styled-components';
import {Color, Spinner} from './Widget';

export const BUTTON_SKIN = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  CRYSTAL: 'crystal',
  RED: 'red',
  GOLD: 'gold',
};

export const BUTTON_SIZE = {
  LARGE: 'lg',
  MEDIUM: 'md',
  SMALL: 'sm',
};

const RectButton = props => {
  const {
    text,
    onClick,
    isLoading = false,
    isDisabled = false,
    skin = BUTTON_SKIN.DEFAULT,
    size = BUTTON_SIZE.MEDIUM,
    refer = null,
    style = {},
    hoverEffect = true,
    ...rest
  } = props;
  const [hovered, setHovered] = React.useState(false);
  return (
    <Wrapper
      hoverEffect={hoverEffect}
      size={size}
      skin={skin}
      onClick={onClick}
      disabled={isDisabled}
      style={style}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={refer}
      {...rest}>
      {isLoading && <Spinner className="spinner" />}
      {!isLoading && (typeof text === 'function' ? text(hovered) : text)}
    </Wrapper>
  );
};

export default RectButton;

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: ${props => props.size.width};
  ${props => heightOfButtons[props.size]}
  ${props => getSkin('normal', props.skin)};
  white-space: nowrap;
  ${({hoverEffect}) =>
      hoverEffect
        ? `
  :hover {
    cursor: pointer;
    ${props => getSkin('hover', props.skin)};
  }
      `
        : ''}
    :disabled {
    cursor: not-allowed;
    ${props => getSkin('disabled', props.skin)};
  }
`;

const heightOfButtons = {
  [BUTTON_SIZE.LARGE]: `
  padding: 13px 26px;
  font-size: 16px;
  line-height: 24px;
  `,
  [BUTTON_SIZE.MEDIUM]: `
  padding: 10.5px 21px;
  font-size: 14px;
  line-height: 21px;
  `,
  [BUTTON_SIZE.SMALL]: `
  padding: 6px 12px;
  font-size: 12px;
  line-height: 18px;
  `,
};

const getSkin = (status, skin) => SKINS[`${status}_${skin}`];

const getSkinStyles = (bgColor, fontColor, borderColor, ...rest) =>
  `
    background-color: ${bgColor};
    color: ${fontColor};
    border: 1px solid ${borderColor};
    & > .spinner {
      border-left: 1.1em solid ${fontColor};
    }
    ${rest}
    `;

const SKINS = {
  normal_default: getSkinStyles(
    Color.mainWhite,
    Color.mainDark,
    Color.mainDark,
  ),
  normal_primary: getSkinStyles(
    Color.mainDark,
    Color.mainWhite,
    Color.mainDark,
  ),
  normal_crystal: getSkinStyles(
    Color.transparent,
    Color.mainWhite,
    Color.mainWhite,
  ),
  normal_red: getSkinStyles('#af2d2d', Color.mainWhite, Color.mainWhite),
  normal_gold: getSkinStyles('#c0a270', Color.mainWhite, Color.mainWhite),
  hover_default: getSkinStyles(Color.mainDark, Color.mainWhite, Color.mainDark),
  hover_primary: getSkinStyles(
    Color.mainGray_78,
    Color.mainWhite,
    Color.mainDark,
  ),
  hover_crystal: getSkinStyles(
    Color.mainWhite,
    Color.mainDark,
    Color.mainWhite,
  ),
  hover_gold: getSkinStyles(Color.mainGray_78, Color.mainWhite, Color.mainDark),
  hover_red: getSkinStyles(Color.mainGray_78, Color.mainWhite, Color.mainDark),
  disabled_default: getSkinStyles(
    Color.mainWhite,
    Color.mainDark,
    Color.mainDark,
    ['opacity:0.3;'],
  ),
  disabled_primary: getSkinStyles(
    Color.mainGray_18,
    Color.mainWhite,
    Color.transparent,
  ),
  disabled_crystal: getSkinStyles(
    Color.transparent,
    Color.mainWhite,
    Color.mainWhite,
    ['opacity:0.3;'],
  ),
  disabled_red: getSkinStyles(
    Color.mainGray_18,
    Color.mainWhite,
    Color.mainWhite,
  ),
  disabled_gold: getSkinStyles(
    Color.mainGray_18,
    Color.mainWhite,
    Color.mainWhite,
  ),
};
