import React from 'react';
import Jcb from '../images/icon-card-jcb.svg';
import Mastercard from '../images/icon-card-mastercard.svg';
import Visa from '../images/icon-card-visa.svg';

export const SHIPPING_TYPE = {
  cvs: 'cvs',
  home: 'home',
};

export const SHIPPING_TYPE_DISPLAY = {
  cvs: {zh: '超商取貨', en: 'csv'},
  home: {zh: '宅配', en: 'home'},
};

// order start

// UNPAID      = :unpaid       # 未付款
// PROCESSING  = :processing   # 處理中
// WAITING     = :waiting      # 待收貨
// DONE        = :done         # 已完成
// CANCELED    = :canceled     # 已取消

export const ORDER_STATUS = {
  processing: 'processing',
  done: 'done',
  canceled: 'canceled',
  waiting: 'waiting',
  unpaid: 'unpaid',
  returned: 'returned',
};

export const ORDER_STATUS_TRANSFORM_REVERSE = {
  [ORDER_STATUS.processing]: 'processing',
  [ORDER_STATUS.done]: 'done',
  [ORDER_STATUS.canceled]: 'canceled',
  [ORDER_STATUS.waiting]: 'waiting',
  [ORDER_STATUS.unpaid]: 'unpaid',
  [ORDER_STATUS.returned]: 'returned',
};

export const ORDER_STATUS_TRANSFORM = {
  processing: ORDER_STATUS.processing,
  waiting: ORDER_STATUS.waiting,
  done: ORDER_STATUS.done,
  canceled: ORDER_STATUS.canceled,
  unpaid: ORDER_STATUS.unpaid,
  returned: ORDER_STATUS.returned,
};

export const ORDER_STATUS_DISPLAY = {
  [ORDER_STATUS.processing]: {zh: '處理中', en: 'processing'},
  [ORDER_STATUS.waiting]: {zh: '待收貨', en: 'waiting'},
  [ORDER_STATUS.done]: {zh: '已完成', en: 'done'},
  [ORDER_STATUS.canceled]: {zh: '已取消', en: 'cancelled'},
  [ORDER_STATUS.unpaid]: {zh: '未完成付款', en: 'unpaid'},
  [ORDER_STATUS.returned]: {zh: '退貨/退款', en: 'returned'},
};

// order end

// suborder start

// 子訂單狀態
// :unpaid       # 未付款
// :processing   # 處理中
// :shipped      # 已出貨
// :canceled     # 已取消
// :done         # 已完成
// :returned     # 退貨

export const SUBORDER_STATUS = {
  unpaid: 'unpaid',
  processing: 'processing',
  shipped: 'shipped',
  canceled: 'canceled',
  done: 'done',
  returned: 'returned',
};

// 子訂單出貨狀態
// :ready        # 備貨中
// :shipped      # 已出貨
// :arrived      # 已到店
// :received     # 已取/已送達
// :failed       # 未成功取件

export const SUBORDER_SHIPPING_STATUS = {
  preparing: 'preparing', // ready
  shipped: 'shipped', // shipped
  delivered: 'delivered', //arrived
  received: 'received', //received
  failed: 'failed', //failed
};

export const SUBORDER_SHIPPING_STATUS_TRANSFORM = {
  ready: SUBORDER_SHIPPING_STATUS.preparing,
  shipped: SUBORDER_SHIPPING_STATUS.shipped,
  arrived: SUBORDER_SHIPPING_STATUS.delivered,
  received: SUBORDER_SHIPPING_STATUS.received,
  failed: SUBORDER_SHIPPING_STATUS.failed,
};

export const SUBORDER_SHIPPING_STATUS_DISPLAY = {
  [SHIPPING_TYPE.home]: {
    [SUBORDER_SHIPPING_STATUS.preparing]: {zh: '備貨中', en: 'preparing'},
    [SUBORDER_SHIPPING_STATUS.shipped]: {zh: '已出貨', en: 'shipped'},
    [SUBORDER_SHIPPING_STATUS.received]: {zh: '已送達', en: 'received'},
    [SUBORDER_SHIPPING_STATUS.failed]: {zh: '未成功寄件', en: 'returned'},
  },
  [SHIPPING_TYPE.cvs]: {
    [SUBORDER_SHIPPING_STATUS.preparing]: {zh: '備貨中', en: 'preparing'},
    [SUBORDER_SHIPPING_STATUS.shipped]: {zh: '已出貨', en: 'shipped'},
    [SUBORDER_SHIPPING_STATUS.delivered]: {zh: '已到店', en: 'delivered'},
    [SUBORDER_SHIPPING_STATUS.received]: {zh: '已取貨', en: 'received'},
    [SUBORDER_SHIPPING_STATUS.failed]: {zh: '未取貨', en: 'returned'},
  },
};

export const STEPS_BY_SUBORDER_SHIPPING_STATUS = {
  [`${SHIPPING_TYPE.cvs}_normal`]: [
    SUBORDER_SHIPPING_STATUS.preparing,
    SUBORDER_SHIPPING_STATUS.shipped,
    SUBORDER_SHIPPING_STATUS.delivered,
    SUBORDER_SHIPPING_STATUS.received,
  ],
  [`${SHIPPING_TYPE.home}_normal`]: [
    SUBORDER_SHIPPING_STATUS.preparing,
    SUBORDER_SHIPPING_STATUS.shipped,
    SUBORDER_SHIPPING_STATUS.received,
  ],
  [`${SHIPPING_TYPE.cvs}_failed`]: [
    SUBORDER_SHIPPING_STATUS.preparing,
    SUBORDER_SHIPPING_STATUS.shipped,
    SUBORDER_SHIPPING_STATUS.delivered,
    SUBORDER_SHIPPING_STATUS.failed,
  ],
  [`${SHIPPING_TYPE.home}_failed`]: [
    SUBORDER_SHIPPING_STATUS.preparing,
    SUBORDER_SHIPPING_STATUS.shipped,
    SUBORDER_SHIPPING_STATUS.failed,
  ],
};

// suborder end

// "e_gui_type": "none", //subtype invoice
// "e_gui_carrier": null, // 載具 invoice account 有值代表使用 手機條碼載具
// "e_gui_tax_number": null, //統編
// 有統編就會是公司 ，有載具一定是個人

export const INVOICE_SUBTYPE = {
  ecpay: 'ecpay',
  mobile: 'mobile',
  b2b: 'b2b',
};

export const INVOICE_SUBTYPE_DISPLAY = {
  [INVOICE_SUBTYPE.ecpay]: {zh: '會員載具', en: 'ecpay'},
  [INVOICE_SUBTYPE.mobile]: {zh: '手機條碼載具', en: 'mobile'},
  [INVOICE_SUBTYPE.b2b]: {zh: '統一編號', en: 'b2b'},
};

export const INVOICE_TYPE = {
  person: 'person',
  company: 'company',
};

export const INVOICE_TYPE_DISPLAY = {
  [INVOICE_TYPE.person]: '個人 (二聯式) 電子發票',
  [INVOICE_TYPE.company]: '公司用 (三聯式) 電子發票',
};

export const CVS_TYPE_DATA = {
  FAMIC2C: {
    key: 'FAMIC2C',
    iconPath: '/images/icon-logo-family-mart.png',
    display_key: 'checkout__cvs_store_fami',
  },
  UNIMARTC2C: {
    key: 'UNIMARTC2C',
    iconPath: '/images/icon-logo-7-eleven.png',
    display_key: 'checkout__cvs_store_711',
  },
  HILIFEC2C: {
    key: 'HILIFEC2C',
    iconPath: '/images/icon-logo-hi-life.png',
    display_key: 'checkout__cvs_store_hilife',
  },
};

export const CREDIT_CARD_TYPE = {
  visa: 'visa',
  mastercard: 'mastercard',
  jcb: 'jcb',
};

export const CREDIT_CARD_TYPE_PIC = {
  [CREDIT_CARD_TYPE.visa]: <img src={Visa} alt="" />,
  [CREDIT_CARD_TYPE.mastercard]: <img src={Mastercard} alt="" />,
  [CREDIT_CARD_TYPE.jcb]: <img src={Jcb} alt="" />,
};

export const CANCEL_REASON = {
  customer_request: {
    zh: '由您取消',
    en: 'customer request',
  },
  staff_cancel: {
    zh: '由門市取消',
    en: 'staff cancel',
  },
  expired: {
    zh: '超時未付款，自動取消',
    en: 'expired',
  },
};
