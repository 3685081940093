import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';

// reference: https://github.com/gatsbyjs/gatsby/issues/2293

// query {
//   allImageSharp {
//     edges {
//       node {
//         fluid(maxWidth: 1200) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// }

const StaticImage = ({name, path, style = {}}) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={data => {
      if (!name) {
        // for legacy compatibility
        name = path;
      }

      return (
        <Img
          fluid={
            data.allImageSharp.edges.find(element => {
              // Match string after final slash
              return element.node.fluid.src.split('/').pop() === name;
            }).node.fluid
          }
          style={style}
        />
      );
    }}
  />
);

export {StaticImage};
