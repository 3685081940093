import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../Contexts/AppContext';
import * as L from '../Utils/Lang';
import {goTo} from '../Utils/UrlUtils';

const ANIMATION_DURATION = 1; // sec

function ScrollingTextsBar(props) {
  const {isShown} = props;
  const app = React.useContext(AppContext.Context);
  const data = app.scrollingTexts;
  const [activeItemIdx, setActiveItemIdx] = React.useState(0);
  const [prevActiveItemIdx, setPrevActiveItemIdx] = React.useState(null);

  React.useEffect(() => {
    if (!isShown) {
      return;
    }

    let _removePrevTimeoutId = null;
    let _timeoutId = setTimeout(() => {
      let _nextIdx = activeItemIdx < data.length - 1 ? activeItemIdx + 1 : 0;
      setActiveItemIdx(_nextIdx);
      setPrevActiveItemIdx(activeItemIdx);

      _removePrevTimeoutId = setTimeout(() => {
        setPrevActiveItemIdx(null);
      }, ANIMATION_DURATION * 1000);
    }, 5000);

    return () => {
      _timeoutId && clearTimeout(_timeoutId);
      _removePrevTimeoutId && clearTimeout(_removePrevTimeoutId);
    };
  }, [isShown, activeItemIdx, setActiveItemIdx, data]);

  if (!isShown) {
    return null;
  }

  return (
    <Wrapper>
      {data.map((datum, idx) => {
        return (
          <RollingText
            key={idx}
            index={idx}
            className={`${activeItemIdx === idx ? 'active' : 'disabled'}${
              datum.link_to ? ' clickable' : ''
            }${prevActiveItemIdx === idx ? ' fade-out' : ''}`}
            onClick={() =>
              datum.link_to && goTo(datum.link_to, AppContext.NavActions)
            }>
            {L.d(datum.title)}
          </RollingText>
        );
      })}
    </Wrapper>
  );
}

const RollingText = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #fff;
  text-align: center;

  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;

  transition: top ${ANIMATION_DURATION}s ease-in;

  &.clickable {
    text-decoration: underline;
    cursor: pointer;
  }

  &.active {
    top: 0px;
  }

  &.disabled.fade-out {
    top: calc(-1 * var(--scroll-bar-height) - 5px);
    transition: top ${ANIMATION_DURATION}s ease-in;
  }

  &.disabled {
    top: calc(var(--scroll-bar-height) + 5px);
    transition: top 0s ease-in;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0px;
  background-color: #141414;
  height: var(--scroll-bar-height);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export default ScrollingTextsBar;
