import * as SvgIcon from '../Components/SvgIcon';
import React from 'react';

const brandsObj = {
  kenzo: 'kenzo',
  selfPortrait: 'self-portrait',
  agete: 'agete',
  isabelMarant: 'isabel-marant',
  lesNereides: 'les-nereides',
};

const brandsArr = [
  'kenzo',
  'self-portrait',
  'agete',
  'isabel-marant',
  'les-nereides',
];

const brandsDisplayArr = [
  {key: 'kenzo', display: 'KENZO'},
  {key: 'self-portrait', display: 'self-portrait'},
  {key: 'agete', display: 'agete'},
  {key: 'isabel-marant', display: 'Isabel Marant'},
  {key: 'les-nereides', display: 'Les Néréides'},
];

const brandNameToBrandId = {
  kenzo: 1,
  'self-portrait': 5,
  agete: 2,
  'isabel-marant': 3,
  'les-nereides': 4,
};
const brandIdToBrandName = {
  1: 'kenzo',
  5: 'self-portrait',
  2: 'agete',
  3: 'isabel-marant',
  4: 'les-nereides',
};

const brandLogos = {
  kenzo: <SvgIcon.LogoKenzo w={120} h={56} color={'inherit'} />,
  'self-portrait': (
    <SvgIcon.LogoSelfPortrait w={120} h={56} color={'inherit'} size={24} />
  ),
  agete: <SvgIcon.LogoAgete color={'inherit'} w={120} h={56} size={24} />,
  'isabel-marant': (
    <SvgIcon.LogoIsabelMarant w={120} h={56} color={'inherit'} size={24} />
  ),
  'les-nereides': (
    <SvgIcon.LogoLesNereides w={120} h={56} color={'inherit'} size={24} />
  ),
};

export {
  brandsArr,
  brandsObj,
  brandsDisplayArr,
  brandNameToBrandId,
  brandIdToBrandName,
  brandLogos,
};
