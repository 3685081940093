const Settings = {
  env: 'stg',
  webUrl: 'https://uatecweb.tungrp.com',
  apiUrl: 'https://uatecapi.tungrp.com',
  cvsMapServerHost: 'https://uatecadmin.tungrp.com',
  creditCardCallbackHost: 'https://uatecrelay.tungrp.com',
  mediaHost: 'https://dvkvars7ubgms.cloudfront.net',
  s3Host: 'https://s3-ap-northeast-1.amazonaws.com/stg.hecafe.com.tw',
  debug: true,
  useMock: false,
  facebookAppId: '1276459929127208',
  googleClientId: '115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com',
  googleAnalyticsId: 'UA-127064537-1',
  googleApiKey: 'AIzaSyA1p97EMG4hATeN7VjHsQCCQDKJgkqy9Ow',
  userType: 'aws',
  slsApi: {
    host: 'http://localhost:3000',
  },
  configs: {
    enableAnonymousCart: false,
  },
  merchantId: '1027806',
  secondPhaseFlag: true,
  version: '2021-12-15',
  login_client_id: 'Tdkam5CrBRCRoZiiCjaPPLcnjLdWPPNOw1YRJPcfZoc',
  login_client_secret: 'rN3EGztfr6TjI2AldB3O_uf16MA-k01_8aUJvMBpfQ4',
};

module.exports = Settings;
