import React, {useContext} from 'react';
import styled from 'styled-components';
import {Cross} from './SvgIcon';
import {FlexRow} from './Widget';
import {Actions, Context} from '../Contexts/AppContext';
import {useDimension} from '../Hooks/AppHooks';

const SlideDown = () => {
  const app = useContext(Context);
  const {mobile} = useDimension();
  const content = app.globalSlideDownContent;

  return (
    <Wrapper
      isShown={content}
      breakpoints={app.breakpoints}
      style={{transform: content ? 'translateY(0)' : 'translateY(-100%)'}}>
      <FlexRow
        style={{
          justifyContent: 'flex-end',
          display: mobile ? 'none' : 'flex',
        }}>
        <Cross
          className="dismiss"
          onClick={() => {
            Actions.setGlobalSlideDownContent(null);
          }}
        />
      </FlexRow>
      {content}
    </Wrapper>
  );
};

export default SlideDown;

const Wrapper = styled.div`
  width: 100%;
  min-height: 161px;
  max-height: 100%;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  z-index: 15; // Should more than navbar.js:227
  background-color: #fff;
  padding: 26px 50px;
  transition: top 400ms ease-in-out;
  overflow-y: auto;
  ${props => (props.isShown ? 'box-shadow: 0 0 10px #999999;' : '')}

  &::-webkit-scrollbar {
    display: none;
  }

  & .dismiss {
    :hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    position: fixed;
    max-height: 100%;
    padding: 0 20px;
  }
`;
