import React from 'react';
import styled from 'styled-components';

function FixedRatioImage(props) {
  let {
    width,
    ratio,
    children,
    image,
    mode = 'contain',
    extraCss = '',
    rwd = null,
  } = props; // ratio = h/w
  if (width) {
    return (
      <div style={{width}}>
        <Wrapper
          ratio={ratio}
          width={width}
          image={image}
          mode={mode}
          extraCss={extraCss}
          rwd={rwd}>
          <div className="content">{children}</div>
        </Wrapper>
      </div>
    );
  }

  return (
    <Wrapper
      ratio={ratio}
      width={width}
      image={image}
      mode={mode}
      extraCss={extraCss}
      rwd={rwd}>
      <div className="content">{children}</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  --ratio: ${props => props.ratio};
  --background-image: url(${props => props.image});

  height: 0;
  ${props =>
    props.width
      ? `width: ${
          typeof props.width === 'number'
            ? `${props.width}px`
            : `${props.width}`
        };`
      : ''};
  overflow: hidden;
  padding-top: calc(var(--ratio) * 100%);
  position: relative;
  background: white var(--background-image);
  background-size: ${props => props.mode || 'contain'};
  background-position: center;

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${props =>
    props.rwd &&
    props.rwd.map(
      r =>
        `
        @media screen and (max-width: ${r.breakpoint}px) {
          --ratio: ${r.ratio};
          --background-image: url(${r.image});
        }
        `,
    )}

  ${props => props.extraCss}
`;

export default FixedRatioImage;
