import * as _User from './Reducers/User';
import * as _Lang from './Reducers/Lang';
import {namedExportWithMemorizedOnce} from './Utils/FpUtil';
import ParseQuery from './Utils/ParseQuery';

let User = namedExportWithMemorizedOnce(_User);
let Lang = namedExportWithMemorizedOnce(_Lang);

const selectors = {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLang: state => Lang.getLang(state.lang),
  getLoginUser: state => User.getLoginUser(state.user),
};

export default selectors;
