import React, {useEffect} from 'react';
import {navigate} from 'gatsby';
import {connect, Provider} from 'react-redux';
import {getStore} from './Store';
import 'antd/dist/antd.css';
import Layout from './Layout';
import Selectors from './Selectors';
import ActionCreator from './ActionCreator';
import * as AppContext from './Contexts/AppContext';
import * as Geolocation from './Contexts/GeolocationContext';
import queryString from 'query-string';
import ActivityIndicator from './Components/ActivityIndicator';
import * as GlobalContext from './GlobalProvider';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({error, info});
    console.warn('*** Error Boundary ***', error, info);
  }

  render() {
    let {error} = this.state;

    if (error) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div style={{padding: 20, backgroundColor: 'grey'}}>
            <h2 style={{color: 'white'}}>Oops! We have errors!</h2>
            <p style={{color: '#ddd'}}>Please reload the page</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const {app} = this.props;
    AppContext.Actions.setLoading(true);

    try {
      if (app.brandCats === null) {
        await AppContext.Actions.fetchAllCats();
      }
      await AppContext.Actions.getAllStores();
    } catch (err) {
      console.error(err);
    }

    try {
      await AppContext.Actions.fetchCart();
    } catch (err) {
      console.error(err);
    }

    try {
      await AppContext.Actions.autoLogin();

      if (app.currentUser) {
        setTimeout(async () => {
          await AppContext.Actions.syncPos();
        }, 1000 * 60 * 60 * 8);
        await AppContext.Actions.fetchCart();
      }
    } catch (err) {
      console.log('debug', err);
    } finally {
      AppContext.Actions.setLoading(false);
    }
  }

  render() {
    const {app, location} = this.props;

    let safeToPreloadContent = false;

    if (location.pathname === '/') {
      safeToPreloadContent = true; // safe to preload content without loading initial
    }

    const _isContentSafeToRender = () => {
      let result = true;
      if (safeToPreloadContent) {
        result = true;
      } else if (app.fetchingCats || app.fetchingInitial) {
        result = false;
      } else if (!app.activeBrand && location.pathname !== '/') {
        result = false;
      }

      return result;
    };

    return (
      <ErrorBoundary>
        <Layout key={this.props.lang} {...this.props}>
          {_isContentSafeToRender() ? (
            this.props.children
          ) : (
            <div style={{height: '100vh'}} />
          )}
        </Layout>

        <ActivityIndicator />
      </ErrorBoundary>
    );
  }
}

const PageContainer = connect(
  (state, ownProps) => ({lang: Selectors.getLang(state)}),
  ActionCreator,
)(GlobalContext.withConsumer(AppContext.withConsumer(Page)));

function AppRoot(props) {
  useEffect(() => {
    const _fetch = async () => {
      let brandInUrl = null;
      try {
        brandInUrl = queryString.parse(window.location.search).brand;
      } catch (err) {}

      let {activeBrand} = await AppContext.Actions.loadInitial({
        defaultBrand: brandInUrl || null,
      });

      if (!activeBrand) {
        navigate('/');
      } else {
        await AppContext.Actions.fetchScrollingTexts();
        await AppContext.Actions.loadHomeData();
        if (window.location.pathname === '/') {
          navigate('/home');
        }
      }
    };

    _fetch();
  }, []);

  return (
    <Provider store={getStore()}>
      <GlobalContext.GlobalProvider>
        <AppContext.Provider>
          <Geolocation.Provider>{props.children}</Geolocation.Provider>
        </AppContext.Provider>
      </GlobalContext.GlobalProvider>
    </Provider>
  );
}

export {AppRoot, PageContainer};
