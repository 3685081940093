import React from 'react';
import {Breakpoints} from '../Contexts/AppContext';

function useDimension() {
  const [dimension, setDimension] = React.useState({});
  const detectDimension = React.useCallback(() => {
    const nextDimension = {};
    if (typeof window !== undefined) {
      nextDimension.innerWidth = window.innerWidth;
      nextDimension.innerHeight = window.innerHeight;
      setDimension(nextDimension);
    }
  }, []);

  const onResize = React.useCallback(
    e => {
      detectDimension();
    },
    [detectDimension],
  );

  React.useEffect(() => {
    try {
      detectDimension();
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    } catch (ex) {
      // bypass
    }
  }, [onResize, detectDimension]);

  const mobile = dimension.innerWidth <= Breakpoints.mobile;
  const tablet = !mobile && dimension.innerWidth <= Breakpoints.tablet;
  const desktop = !(mobile || tablet);

  return {
    dimension,
    mobile,
    tablet,
    desktop,
  };
}

function useFocus(inputRef) {
  const [focus, setFocus] = React.useState(false);

  React.useEffect(() => {
    const elem = inputRef.current;
    const onFocus = event => {
      setFocus(true);
    };
    const onBlur = event => {
      setFocus(false);
    };

    if (elem) {
      elem.addEventListener('focus', onFocus);
      elem.addEventListener('blur', onBlur);
    }

    return () => {
      if (elem) {
        elem.removeEventListener('focus', onFocus);
        elem.removeEventListener('blur', onBlur);
      }
    };
  });

  return {
    focus,
  };
}

export {useFocus, useDimension};
