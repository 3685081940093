import React, {Fragment, useContext} from 'react';
import styled from 'styled-components';
import {Context} from '../../Contexts/AppContext';
import {useDimension} from '../../Hooks/AppHooks';

const Modal = () => {
  const app = useContext(Context);
  const visible = !!app.globalModalContent;
  const {dimension} = useDimension();
  const mobile = dimension.innerWidth < 960;

  return (
    <Fragment>
      <Backdrop visible={visible} />
      <Dialog visible={visible} mobile={mobile}>
        {app.globalModalContent}
      </Dialog>
    </Fragment>
  );
};

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 300ms;
`;

const Backdrop = styled(FullScreenWrapper)`
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 12;
`;

const Dialog = styled(FullScreenWrapper)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props =>
    props.visible
      ? 'translateY(0px)'
      : props.mobile
      ? 'translateY(500px)'
      : 'translateY(300px)'};
  transition: 300ms;
  background-color: transparent;
  z-index: 13;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.mobile ? 'flex-start' : 'center')};
  align-items: ${props => (props.mobile ? 'stretch' : 'center')};
`;

export default Modal;
