import React, {useState} from 'react';
import SlideInPanel from './SlideInPanel';
import styled from 'styled-components';
import * as AppHooks from '../Hooks/AppHooks';
import * as AppContext from '../Contexts/AppContext';
import {NavActions} from '../Contexts/AppContext';
import {AnimSubMenu, Color, FontSize, LineHeight, SeparateLine} from './Widget';
import * as SvgIcon from './SvgIcon';
import * as L from '../Utils/Lang';
import {brandsDisplayArr} from '../Domain/Brand';
import {HELP_LIST_CONFIG} from '../Domain/Help';

function MenuPanelContent(props) {
  const {onClose, logoIcon, breakpoints, app, panelRef} = props;
  const [openId, setOpenId] = useState(null);
  const [subOpenId, setSubOpenId] = useState(null);
  const isHelpListActive = openId === HELP_LIST_CONFIG.key;

  return (
    <Wrapper breakpoints={breakpoints}>
      <div className="header">
        <SvgIcon.Cross
          size={40}
          color={Color.mainDark}
          style={{marginRight: 4}}
          onClick={onClose}
        />
        <div
          style={{color: Color.mainDark}}
          onClick={() => {
            AppContext.NavActions.navigate(`/home`);
            onClose();
          }}>
          {logoIcon}
        </div>
      </div>
      <div className="content">
        <div className="category-wrapper">
          {app.brandCats?.[app.activeBrand].map((category, idx) => {
            const isActive =
              openId && openId.indexOf(`item-cat-${category.id}`) === 0;
            return (
              <div className="cat-item-wrapper" key={idx}>
                <div
                  className="cat-item"
                  key={idx}
                  onClick={() =>
                    setOpenId(isActive ? null : `item-cat-${category.id}`)
                  }>
                  <div className={`display${isActive ? ' active' : ''}`}>
                    {L.d(category.title)}
                  </div>

                  {isActive ? (
                    <SvgIcon.Minus size={24} color={Color.mainDark_70} />
                  ) : (
                    <SvgIcon.Plus
                      className={'icon-plus'}
                      size={24}
                      color={Color.mainDark_70}
                    />
                  )}
                </div>

                <AnimSubMenu
                  active={isActive}
                  items={category.collections.length + category.series.length}>
                  {category.series.length !== 0 &&
                    category.series.map((series, idx) => {
                      return (
                        <div
                          className="submenu-item"
                          key={idx}
                          onClick={() => {
                            NavActions.navigate(`/series?id=${series.id}`);
                            panelRef.current.close();
                          }}>
                          {L.d(series.title)}
                        </div>
                      );
                    })}
                  {category.collections.map((collection, idx) => {
                    return (
                      <div
                        className="submenu-item"
                        key={idx}
                        onClick={() => {
                          NavActions.navigate(
                            `/products?collection_id=${collection.id}`,
                          );
                          panelRef.current.close();
                        }}>
                        {L.d(collection.title)}
                      </div>
                    );
                  })}
                </AnimSubMenu>
              </div>
            );
          })}
        </div>

        <div className="groups">
          <div
            className="group-item"
            onClick={() => {
              NavActions.navigate('/news');
              onClose();
            }}>
            最新消息
          </div>
          <div
            className="group-item"
            onClick={() => {
              NavActions.navigate('/stores');
              onClose();
            }}>
            門市
          </div>
        </div>

        <SeparateLine />

        <div className="groups">
          <div
            style={{fontSize: FontSize.paragraph}}
            className="cat-item"
            onClick={() =>
              setOpenId(isHelpListActive ? null : HELP_LIST_CONFIG.key)
            }>
            <div className={`display${isHelpListActive ? ' active' : ''}`}>
              {L.d(HELP_LIST_CONFIG.title)}
            </div>

            {isHelpListActive ? (
              <SvgIcon.Minus size={24} color={Color.mainDark_70} />
            ) : (
              <SvgIcon.Plus
                className={'icon-plus'}
                size={24}
                color={Color.mainDark_70}
              />
            )}
          </div>

          <AnimSubMenu
            active={isHelpListActive}
            items={[HELP_LIST_CONFIG.items.length]}>
            {HELP_LIST_CONFIG.items.map((item, idx) => {
              return (
                <div
                  className="submenu-item"
                  key={idx}
                  onClick={() => {
                    NavActions.navigate(item.route);
                    panelRef.current.close();
                  }}>
                  {L.d(item.title)}
                </div>
              );
            })}
          </AnimSubMenu>

          <div
            className="group-item"
            onClick={() => {
              NavActions.navigate('/aboutTheTun');
              panelRef.current.close();
            }}>
            關於 TUN
          </div>
        </div>

        <SeparateLine />

        <div className="groups">
          <div className="group-title">Other brands</div>
          {brandsDisplayArr
            .filter(b => b.key !== app.activeBrand)
            .map((b, idx) => {
              const isActive =
                openId && openId.indexOf(`item-brand-${b.key}`) === 0;
              return (
                <div key={idx} className="brand-group-content-wrapper">
                  <div
                    className="group-item"
                    key={idx}
                    onClick={() =>
                      setOpenId(isActive ? null : `item-brand-${b.key}`)
                    }>
                    <div className={`display${isActive ? ' active' : ''}`}>
                      {b.display}
                    </div>
                    {isActive ? (
                      <SvgIcon.Minus size={24} color={Color.mainDark_70} />
                    ) : (
                      <SvgIcon.Plus
                        className={'icon-plus'}
                        size={24}
                        color={Color.mainDark_70}
                      />
                    )}
                  </div>

                  <AnimSubMenu active={isActive}>
                    <div
                      className="submenu-item"
                      onClick={() => {
                        NavActions.navigate(`/home?brand=${b.key}`);
                        onClose();
                      }}>
                      前往品牌首頁
                    </div>
                    {app.brandCats?.[b.key].map((cat, index) => {
                      const isCatActive =
                        subOpenId &&
                        subOpenId.indexOf(`item-cat-${cat.id}`) === 0;
                      return (
                        <div key={index}>
                          <div
                            className="group-item"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <div
                              style={{
                                padding: '4px 0 4px 20px',
                                cursor: 'pointer',
                                fontWeight: 'normal',
                              }}
                              onClick={() => {
                                setSubOpenId(
                                  isCatActive ? null : `item-cat-${cat.id}`,
                                );
                              }}>
                              {L.d(cat.title)}
                            </div>
                            {isCatActive ? (
                              <SvgIcon.Minus
                                size={24}
                                color={Color.mainDark_70}
                              />
                            ) : (
                              <SvgIcon.Plus
                                className={'icon-plus'}
                                size={24}
                                color={Color.mainDark_70}
                              />
                            )}
                          </div>
                          <AnimSubMenu active={isCatActive}>
                            {cat.series.length !== 0 &&
                              cat.series.map((series, idx) => {
                                return (
                                  <div
                                    className="submenu-item"
                                    style={{padding: '4px 0 4px 40px'}}
                                    key={idx}
                                    onClick={() => {
                                      NavActions.navigate(
                                        `/series?id=${series.id}&brand=${b.key}`,
                                      );
                                      panelRef.current.close();
                                    }}>
                                    {L.d(series.title)}
                                  </div>
                                );
                              })}
                            {cat.collections.map((c, idx) => (
                              <div
                                className="submenu-item"
                                style={{padding: '4px 0 4px 40px'}}
                                key={idx}
                                onClick={() => {
                                  NavActions.navigate(
                                    `/products?collection_id=${c.id}&brand=${b.key}`,
                                  );
                                  onClose();
                                }}>
                                {L.d(c.title)}
                              </div>
                            ))}
                          </AnimSubMenu>
                        </div>
                      );
                    })}
                    <div
                      className="submenu-item"
                      onClick={() => {
                        NavActions.navigate(`/news?brand=${b.key}`);
                        onClose();
                      }}>
                      最新消息
                    </div>
                    <div
                      className="submenu-item"
                      onClick={() => {
                        NavActions.navigate(`/stores?brand=${b.key}`);
                        onClose();
                      }}>
                      門市
                    </div>
                  </AnimSubMenu>
                </div>
              );
            })}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  padding: 12px 50px;
  --menu-header-height: 80px;
  color: ${Color.mainDark};

  & > .header {
    display: flex;
    align-items: center;
    height: var(--menu-header-height);
  }

  & > .content {
    height: calc(100% - var(--menu-header-height));
    overflow: auto;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      display: none;
    }
    & > .category-wrapper {
      padding: 10px 0;
      flex: 1;
      & > .cat-item-wrapper {
        padding-bottom: 16px;
      }
    }

    & .cat-item {
      font-size: ${FontSize.highlight}px;
      line-height: ${LineHeight.highlight}px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      & > .icon-plus {
        visibility: hidden;
      }
      & :hover {
        & > .icon-plus {
          visibility: visible;
        }
      }
      & > .display.active {
        border-bottom: 1px solid ${Color.mainDark};
      }
    }

    & > .groups {
      & > .group-title {
        font-size: ${FontSize.meta}px;
        line-height: ${LineHeight.meta}px;
        color: ${Color.mainDark_50};
        margin-bottom: 17px;
      }

      .group-item {
        font-size: ${FontSize.paragraph}px;
        line-height: ${LineHeight.paragraph}px;
        margin-top: 17px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        & > .icon-plus {
          visibility: hidden;
        }
        :hover {
          & > .icon-plus {
            visibility: visible;
          }
        }

        & > .display {
        }

        & > .display.active {
          border-bottom: 1px solid ${Color.mainDark};
        }
      }
      & .brand-group-content-wrapper {
        & .group-item:first-child {
          margin-top: 0;
        }
        .icon-plus {
          visibility: hidden;
        }
        & > .brand-submenu-wrapper {
          padding-bottom: 12px;

          & > .submenu-item {
            :hover {
              .icon-plus {
                visibility: visible;
              }
            }
            padding: 4px 20px;
            font-weight: normal;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    & > .content {
      & > .category-wrapper {
        & > .cat-item-wrapper {
        }
      }
      & .cat-item {
        .icon-plus {
          visibility: visible;
        }
      }

      & > .groups {
        .group-item {
          .icon-plus {
            visibility: visible;
          }
        }
      }
    }
  }
`;

function MenuPanel(props) {
  const {getInstance, app} = props;
  const {dimension} = AppHooks.useDimension();
  const panelRef = React.useRef(null);

  const open = React.useCallback(
    ({logoIcon}) => {
      panelRef.current.open(
        <MenuPanelContent
          app={app}
          breakpoints={app.breakpoints}
          logoIcon={logoIcon}
          panelRef={panelRef}
          onClose={() => {
            panelRef.current.close();
          }}
        />,
      );
    },
    [app],
  );

  const close = React.useCallback(() => {
    panelRef.current.close();
  }, []);

  if (getInstance) {
    getInstance({
      open,
      close,
    });
  }

  return (
    <SlideInPanel
      zIndex={10000}
      size={
        dimension && dimension.innerWidth <= app.breakpoints.mobile
          ? dimension.innerWidth
          : 366
      }
      position={'left'}
      style={{backgroundColor: 'white', maxWidth: '100vw'}}
      getInstance={inst => {
        panelRef.current = inst;
      }}
    />
  );
}

export default AppContext.withConsumer(MenuPanel);
