import React, {useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import FixedRatioImage from './FixedRatioImage';
import {Color, fstyles} from './Widget';
import * as L from '../Utils/Lang';
import {
  Actions,
  BrandProductImageRatio,
  Context,
  NavActions,
} from '../Contexts/AppContext';
import {useDimension} from '../Hooks/AppHooks';
import {aggregateProductVariants, getPriceTag} from '../Utils/ProductUtil';
import {ProductItemShadow} from './ProductItemShadow';
import {brandIdToBrandName} from '../Domain/Brand';
import {MEMBERSHIP_CONFIG, MEMBERSHIP_LEVEL_DISPLAY} from '../Domain/Member';

function ProductItem(props) {
  const {product, itemWidth} = props;
  const app = useContext(Context);
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;
  const {promote_price, member_price, price} = product || {};
  const [isHoverOnItem, setIsHoverOnItem] = useState(false);

  const isMember = useMemo(() => {
    if (!product || !app.currentUser) {
      return false;
    }
    return Boolean(app.currentUser);
  }, [app.currentUser, product]);

  const isPromote = useMemo(() => {
    if (!product) {
      return false;
    }
    return Boolean(product?.promotion);
  }, [product]);

  const priceFlag = useMemo(() => {
    if (!product) {
      return 'none';
    }
    if (!isMember && !isPromote) {
      return 'none';
    } else if (isMember && isPromote) {
      return promote_price <= member_price ? 'promote' : 'member';
    } else if (isMember && !isPromote) {
      return 'member';
    } else {
      return 'promote';
    }
  }, [isMember, isPromote, member_price, product, promote_price]);

  const memberDiscountRatio = useMemo(() => {
    if (!product || !app.currentUser) {
      return false;
    }
    return MEMBERSHIP_CONFIG[app.currentUser.vip_level].discount[
      brandIdToBrandName[product.brand_id]
    ];
  }, [app.currentUser, product]);

  const ratio = useMemo(() => {
    if (!product) {
      return 0;
    }
    return product.brand_id
      ? BrandProductImageRatio[brandIdToBrandName[product.brand_id]]
      : BrandProductImageRatio[app.activeBrand.toLocaleLowerCase()];
  }, [app.activeBrand, product]);

  const groupVariants = useMemo(() => {
    if (!product) {
      return [];
    }
    return aggregateProductVariants(product.variants);
  }, [product]);

  const showImage = useMemo(() => {
    if (!product) {
      return null;
    }

    const images =
      product.variants.find(v => v.id === product.specified_variant_id)
        ?.images || product.image;

    if (!Array.isArray(images)) {
      return product.image;
    }

    if (images.length === 0) {
      return null;
    } else if (images.length === 1) {
      return images[0];
    } else {
      if (isHoverOnItem) {
        return images[1];
      } else {
        return images[0];
      }
    }
  }, [isHoverOnItem, product]);

  if (!product) {
    return null;
  }

  return (
    <ItemWrapper itemWidth={itemWidth}>
      <ImageWrapper
        onMouseEnter={() => {
          setIsHoverOnItem(true);
        }}
        onMouseLeave={() => {
          setIsHoverOnItem(false);
        }}
        onClick={() => {
          NavActions.navigate(`/product/?id=${product.id}`);
          Actions.setGlobalSlideDownContent(null);
        }}>
        <FixedRatioImage
          ratio={ratio}
          image={showImage}
          alt="product banner"
          mode="cover"
          width={itemWidth}
          extraCss={{
            height: `${itemWidth * ratio}px`,
            backgroundPosition: 'center',
          }}
        />
        {product.variants && (
          <ProductItemShadow
            groupVariants={groupVariants}
            itemWidth={itemWidth}
          />
        )}
      </ImageWrapper>
      <div className="description">
        <div className="name">{L.d(product.name)}</div>
        <PriceTag smallDevice={smallDevice}>
          {getPriceTag({
            priceSet: {price, promote_price, member_price},
            isPromote,
            isMember,
            mobile: smallDevice,
            hasPrefix: true,
          })}
        </PriceTag>
        {priceFlag === 'member' && memberDiscountRatio !== 0 && (
          <Tag>
            {L.d(MEMBERSHIP_LEVEL_DISPLAY[app.currentUser.vip_level])} -
            {memberDiscountRatio}%
          </Tag>
        )}
        {priceFlag === 'promote' && (
          <Tag>
            {L.d(product.promotion.title)} -{100 - product.promotion.ratio} %
          </Tag>
        )}
      </div>
    </ItemWrapper>
  );
}

const ImageWrapper = styled.div`
  position: relative;
  & .shadow {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0),
      ${Color.mainDark_40}
    );
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: stretch;
    justify-content: center;
  }
  & :hover {
    cursor: pointer;
    & .shadow {
      display: flex;
    }
  }
`;

const ItemWrapper = styled.div`
  width: ${props => props.itemWidth}px;
  & > .description {
    user-select: none;
    background-color: white;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    > .name {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: #141414;
    }
  }
`;

const PriceTag = styled.div`
  display: flex;
  flex-direction: ${props => (props.smallDevice ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  color: ${Color.mainDark};
  ${props => (props.smallDevice ? fstyles.text12 : fstyles.text14)}
  & > .price {
    margin: 0 2.5px;
    white-space: nowrap;
  }
  & > .deprecated {
    color: ${Color.mainDark_30};
    text-decoration: line-through;
  }
`;

const Tag = styled.div`
  color: #cca75c;
  max-width: 186px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default ProductItem;
