import styled from 'styled-components';
import React, {useContext, useMemo} from 'react';
import {Actions, Context, NavActions} from '../Contexts/AppContext';
import * as SvgIcon from './SvgIcon';
import {SearchArea} from './search/SearchArea';
import {Color, FlexCol} from './Widget';

const BottomNav = styled.div`
  display: none;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  min-height: var(--bottom-navbar-height);
  & > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 5);
  }
  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    display: flex;
  }
`;

const BottomNavBar = () => {
  const app = useContext(Context);

  const getIsActive = pathname => {
    if (typeof window !== `undefined`) {
      return window.location.pathname.indexOf(pathname) > -1;
    }
    return false;
  };

  const activeStatus = {
    home: getIsActive('home'),
    message: getIsActive('message'),
    cart: getIsActive('cart'),
    user: getIsActive('profile'),
  };

  return (
    <BottomNav breakpoints={app.breakpoints}>
      <div
        className="button"
        onClick={() => {
          NavActions.navigate('/home');
        }}>
        {activeStatus.home ? <SvgIcon.HomeFill /> : <SvgIcon.Home />}
      </div>
      <div
        className="button"
        onClick={() => {
          Actions.setGlobalSlideDownContent(<SearchArea />);
        }}>
        {activeStatus.search ? <SvgIcon.SearchFill /> : <SvgIcon.Search />}
      </div>
      <div
        className="button"
        onClick={() => {
          NavActions.navigate('/cart');
        }}>
        {activeStatus.cart ? <SvgIcon.CartFill /> : <SvgIcon.Cart />}
      </div>
      {/*TODO: have not implement feature*/}
      {/*<div className="button" onClick={() => {}}>*/}
      {/*  {activeStatus.message ? <SvgIcon.MessageFill /> : <SvgIcon.Message />}*/}
      {/*</div>*/}
      <div
        className="button"
        onClick={() => {
          NavActions.navigate('/profile');
        }}>
        {activeStatus.user ? <SvgIcon.UserFill /> : <SvgIcon.User />}
      </div>
    </BottomNav>
  );
};

export default BottomNavBar;
