import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../Contexts/AppContext';
import * as SvgIcon from './SvgIcon';

function ActivityIndicator(props) {
  const app = React.useContext(AppContext.Context);
  const visible =
    app.showActivityIndicator || app.fetchingCats || app.fetchingInitial;

  return (
    <>
      <Wrapper visible={visible}>
        <div className="loader" />
      </Wrapper>

      <Backdrop visible={visible} />
    </>
  );
}

const CoverAll = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
`;

const Wrapper = styled(CoverAll)`
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    opacity: ${props => (props.visible ? 1 : 0)};
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Backdrop = styled(CoverAll)`
  z-index: 100;
  transition: 200ms;
  background-color: ${props =>
    props.visible ? 'rgba(0,0,0,0.3)' : 'transparent'};
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
`;

export default ActivityIndicator;
