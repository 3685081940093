import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import SlideInPanel from './SlideInPanel';
import * as Widget from './Widget';
import {Color} from './Widget';
import * as SvgIcon from './SvgIcon';
import * as AppContext from '../Contexts/AppContext';
import {useDimension} from '../Hooks/AppHooks';
import {NavActions} from '../Contexts/AppContext';

function BrandPanel(props) {
  const panelRef = useRef();
  const {dimension} = useDimension();
  const breakpoints = AppContext.Actions.getBreakpoints();
  const [isOn, setIsOn] = useState(false);
  return (
    <>
      <WhiteBackground />
      <SlideInPanel
        getInstance={inst => {
          panelRef.current = inst;
          props.getInstance({
            open: () => {
              panelRef.current.open(<BrandPanelContent panelRef={panelRef} />);
              setIsOn(true);
            },
            close: () => {
              panelRef.current.close();
              setIsOn(false);
            },
            isOn: isOn,
          });
        }}
        position="top"
        size={
          dimension?.innerWidth <= breakpoints.mobile
            ? 520
            : dimension?.innerWidth <= breakpoints.tablet
            ? 280
            : 200
        }
        zIndex={8}
        style={{padding: 0}}
      />
    </>
  );
}

function BrandPanelContent(props) {
  const {panelRef} = props;
  const [hoveredBrand, setHoveredBrand] = useState(null);

  return (
    <BrandsContainer
      onMouseLeave={() => {
        panelRef.current.close();
      }}
      style={{
        backgroundColor: 'white',
        paddingTop: 100,
        paddingRight: 80,
        paddingLeft: 80,
      }}>
      <Widget.FlexRow style={{flexWrap: 'wrap', justifyContent: 'center'}}>
        {[
          {key: 'kenzo', icon: SvgIcon.LogoKenzo},
          {
            key: 'self-portrait',
            icon: SvgIcon.LogoSelfPortrait,
          },
          {key: 'agete', icon: SvgIcon.LogoAgete},
          {
            key: 'isabel-marant',
            icon: SvgIcon.LogoIsabelMarant,
          },
          {key: 'les-nereides', icon: SvgIcon.LogoLesNereides},
        ].map(({icon: Icon, key}, idx) => (
          <div
            key={idx}
            className={`logo-wrapper${
              hoveredBrand && hoveredBrand !== key ? ' grey' : ''
            }`}
            onMouseEnter={() => setHoveredBrand(key)}
            onMouseLeave={() => setHoveredBrand(null)}
            onClick={() => {
              AppContext.Actions.setActiveBrand(key);
              NavActions.navigate(`/home?brand=${key}`);
              panelRef.current.close();
            }}>
            <Icon w={113} h={36} color={'inherit'} />
          </div>
        ))}
      </Widget.FlexRow>
    </BrandsContainer>
  );
}

const WhiteBackground = styled.div`
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: var(--navnar-height);
  top: 0;
  left: 0;
  background-color: white;
`;

const BrandsContainer = styled.div`
  padding: 20px 0px;
  width: 100vw;
  & .logo-wrapper {
    cursor: pointer;
    padding: 20px;
    color: ${Color.mainDark};
    transition: color 0.2s ease-in;
  }

  & .logo-wrapper.grey {
    color: ${Color.mainDark_20};
  }
`;

export default BrandPanel;
