import {
  Color,
  FlexCol,
  FlexRow,
  FontSize,
  fstyles,
  UnderlineButton,
} from '../Widget';
import * as L from '../../Reducers/Lang';
import React from 'react';
import {ACTION_BUTTONS_TYPE, ActionButtons, Content, Counter} from './CartItem';
import styled from 'styled-components';
import FixedRatioImage from '../FixedRatioImage';
import {NavActions} from '../../Contexts/AppContext';

const MobileCartItem = props => {
  const {
    itemStatus,
    type,
    item,
    isAvailable,
    onCounterClick,
    deleteClickHandler,
    nextBuyClickHandler,
    isEditing,
    setIsEditing,
    priceTag,
    discountTag,
  } = props;
  const {isPublished, isSoldOut, isInsufficient} = itemStatus;
  const {quantity, variant} = item;
  const {colorDisplay, size, images, product} = variant;

  return (
    <Wrapper>
      <FlexRow>
        <div style={{position: 'relative'}}>
          <div
            style={{cursor: 'pointer'}}
            onClick={async () => {
              await NavActions.navigate(
                `/product?id=${item.variant.product.id}`,
              );
            }}>
            <FixedRatioImage
              extraCss={{filter: isAvailable ? 'none' : 'opacity(0.2)'}}
              width="112px"
              image={images[0]}
              mode="cover"
              ratio={1.25}
            />
          </div>
          {!isAvailable && (
            <div
              style={{
                position: 'absolute',
                top: '60px',
                left: '25px',
                color: Color.mainDark_50,
                fontWeight: 'bold',
              }}>
              {isSoldOut && '商品已售完'}
              {!isPublished && '商品已售完'}
            </div>
          )}
        </div>
        <FlexCol
          style={{
            flex: 1,
            height: '140px',
            paddingLeft: 12,
            justifyContent: 'space-between',
          }}>
          <FlexCol
            style={{
              height: '100%',
              width: '100%',
              justifyContent: 'space-between',
              filter: isAvailable ? 'none' : 'opacity(0.2)',
            }}>
            <Content>
              {discountTag}
              <div
                style={{cursor: 'pointer'}}
                onClick={async () => {
                  await NavActions.navigate(
                    `/product?id=${item.variant.product.id}`,
                  );
                }}
                className="name">
                {L.d(product.title)}
              </div>
              <div className="desc">
                {L.d(colorDisplay)}{' '}
                {L.d(colorDisplay) && L.d(size) && <span>/</span>} {L.d(size)}
              </div>
              {!isEditing && <div className="desc">數量 : {quantity}</div>}
              {isInsufficient && isAvailable && (
                <UnderlineButton
                  onClick={() => {
                    setIsEditing(true);
                  }}
                  style={{
                    color: '#e22828',
                    fontSize: FontSize.mini,
                    margin: 0,
                  }}>
                  庫存不足，編輯數量
                </UnderlineButton>
              )}
              {isEditing && isAvailable && (
                <Counter onChange={onCounterClick} quantity={quantity} />
              )}
            </Content>
          </FlexCol>

          <FlexRow
            style={{
              flex: 1,
              justifyContent: 'flex-end',
            }}>
            {isAvailable && (
              <div className="price-tag-container">{priceTag}</div>
            )}
            {!isAvailable && !isEditing && (
              <ActionButtons
                itemStatus={itemStatus}
                actionButtonsType={ACTION_BUTTONS_TYPE.UNDERLINE}
                cartItemType={type}
                onDeleteClick={deleteClickHandler}
                onNextBuyClick={nextBuyClickHandler}
              />
            )}
          </FlexRow>
        </FlexCol>
      </FlexRow>
    </Wrapper>
  );
};

export default MobileCartItem;

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  background-color: #fff;
  ${fstyles.text12}
  & .price-tag-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    white-space: nowrap;
    & > .price {
      margin-left: 8px;
      :last-child {
        font-size: 14px;
        font-weight: bold;
      }
    }
    & .deprecated {
      color: ${Color.mainDark_70};
      text-decoration: line-through;
    }
  }
  & .discountReason {
    color: #cca75c;
    max-width: 150px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;
