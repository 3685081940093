import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useFocus} from '../Hooks/AppHooks';
import {Eye, EyeSlash} from './SvgIcon';

function TextInput(props) {
  const {
    label,
    error,
    icons = {},
    style = {},
    styleCss = '',
    disabled = false,
    ..._inputProps
  } = props;
  const inputRef = React.useRef();
  const {focus} = useFocus(inputRef);
  const [clearPass, setClearPass] = React.useState(false);
  const {placeholder, type: inputType, ...inputProps} = _inputProps;

  const getInputType = () => {
    if (inputType === 'search') {
      return inputType;
    }
    if (inputType !== 'password') {
      return inputType || 'text';
    }
    return clearPass ? 'text' : 'password';
  };

  const float = useMemo(() => {
    if (inputProps.value || focus) {
      return true;
    } else {
      return !inputProps.value && focus;
    }
  }, [focus, inputProps.value]);

  return (
    <Wrapper
      disabled={disabled}
      styleCss={styleCss}
      float={float}
      focus={focus}
      error={error}
      style={style}>
      <div className="container">
        {icons.left}
        <label>{label}</label>
        <input
          ref={inputRef}
          placeholder={placeholder || label}
          type={getInputType()}
          disabled={disabled}
          {...inputProps}
        />
        {icons.right}
        <div className="error">{error}</div>
        {inputType === 'password' && (
          <div className="toggle-pw" onClick={() => setClearPass(!clearPass)}>
            {clearPass ? <Eye /> : <EyeSlash />}
          </div>
        )}
      </div>
      <div className="error-offset-block" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    position: relative;
    border-bottom: ${props => {
      if (props.error) {
        return '1px solid #e22828';
      } else if (props.focus) {
        return '1px solid #141414';
      } else if (props.disabled) {
        return 'none';
      } else {
        return '1px solid rgba(20, 20, 20, 0.1)';
      }
    }};
    transition: 300ms;

    & > label {
      display: block;
      color: ${props => (props.focus ? '#141414' : 'rgba(20, 20, 20, 0.7)')};
      opacity: ${props => (props.float ? 1 : 0)};
      transform: ${props =>
        props.float ? 'translateY(0px)' : 'translateY(30px)'};
      transition: 300ms;
      margin: 0 0 4px;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.67;
      pointer-events: none;
    }

    & > input {
      width: 100%;
      display: block;
      color: #141414;
      margin: 4px 0;
      font-size: 16px;
      line-height: 1.5;
      padding: 0 0 12px;
      border-radius: 0;
      appearance: none;
      outline: none;
      border: none;
    }

    & > input[disabled] {
      background-color: transparent;
      padding-bottom: 0px;
    }

    & > .error {
      position: absolute;
      bottom: -24px;
      left: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.67;
      color: #e22828;
      max-height: ${props => (props.error ? 100 : 0)}px;
      opacity: ${props => (props.error ? 1 : 0)};
      transition: 300ms;
    }

    & > .toggle-pw {
      position: absolute;
      right: 0;
      top: 26px;
      cursor: pointer;
    }
  }

  & > .error-offset-block {
    height: 24px;
  }

  ${props => props.styleCss}
`;

export default TextInput;
