export const SupportLangs = [
  {display: '繁體中文', key: 'tw'},
  {display: 'English', key: 'en'},
];

export const DefaultLang = 'tw';

const StringTable = {
  // 建議先編輯好 tw 語系後，整段複製至 en 語系內
  tw: {
    // common
    c__alert_confirm: '確定',
    // footer
    footer__input_phone: '請輸入手機號碼',
    // home page
    home__welcome: '歡迎!',
    // store page
    store__store: '門市',
    store__location_not_found: '位置權限未開啟',
    store__need_location_permission:
      '如要找尋附近門市，請至瀏覽器的設定中開啟此網站的「位置」權限。',
    store__search_other: '探索其他',
    store__day_mon: '星期一',
    store__day_tue: '星期二',
    store__day_wed: '星期三',
    store__day_thu: '星期四',
    store__day_fri: '星期五',
    store__day_sat: '星期六',
    store__day_sun: '星期日',

    // checkout page
    checkout__cvs_store_fami: '全家',
    checkout__cvs_store_711: '7-ELEVEN',
    checkout__cvs_store_hilife: '萊爾富',
  },
  en: {
    // common
    c__alert_confirm: 'OK',
    // footer
    footer__input_phone: 'Please input phone',
    // home page
    home__welcome: 'Welcome!',
    // store page
    store__store: 'stores',
    store__location_not_found: 'store__location_not_found',
    store__need_location_permission: 'store__need_location_permission',
    store__search_other: 'search else',
    store__day_mon: 'Mon.',
    store__day_tue: 'Tue.',
    store__day_wed: 'Wed.',
    store__day_thu: 'Thur.',
    store__day_fri: 'Fri.',
    store__day_sat: 'Sat.',
    store__day_sun: 'Sun.',

    // checkout page
    checkout__cvs_store_fami: '全家',
    checkout__cvs_store_711: '7-ELEVEN',
    checkout__cvs_store_hilife: '萊爾富',
  },
};

export default StringTable;
