import React from 'react';
import {FlexRow} from './Widget';
import ProductItem from './ProductItem';

function ProductGrid(props) {
  const {products, itemPerRow, itemWidth, extraStyle = {}} = props;
  const productRows = products.reduce((rows, product, idx) => {
    if (idx % itemPerRow === 0) {
      rows.push([]);
    }

    rows[rows.length - 1].push(product);
    return rows;
  }, []);

  const blankFillList = new Array(3).fill(0);

  return productRows.map((productsInRow, idx) => {
    return (
      <FlexRow
        key={idx}
        style={{
          justifyContent: 'space-between',
          marginBottom: 16,
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          ...extraStyle,
        }}>
        {productsInRow.map((product, index) => {
          return (
            <ProductItem key={index} itemWidth={itemWidth} product={product} />
          );
        })}
        {blankFillList.map((_b, index) => (
          <div
            key={index}
            style={{
              width: itemWidth,
              height: 1,
              visibility: 'hidden',
            }}
          />
        ))}
      </FlexRow>
    );
  });
}

export default ProductGrid;
