import React, {Fragment, useContext, useState} from 'react';
import styled from 'styled-components';
import {Actions, Context, NavActions} from '../../Contexts/AppContext';
import {useDimension} from '../../Hooks/AppHooks';
import {Color, FlexRow} from '../../Components/Widget';
import * as SvgIcon from '../../Components/SvgIcon';

export const MENU = {
  credits: 'credits',
  personalInfo: 'personal_info',
  changePassword: 'change_password',
  cardManagement: 'card_management',
  addrManagement: 'addr_management',
  myFavStore: 'my_fav_store',
  orders: 'orders',
  reCheckout: 're_checkout',
  return: 'return',
  maintain: 'maintain',
  maintainDetail: 'maintain_detail',
  maintainHistory: 'maintain_history',
  maintainApply: 'maintain_apply',
  memberLevel: 'member_level',
  _profileSetting: '_profileSetting', // not a actual route
};

const MENU_ITEMS = [
  {
    title: '購物金',
    routeKey: MENU.credits,
    description: '消費回饋、升續等獎勵、生日禮金',
    actionDescription: '查看餘額',
    subItems: [],
  },
  {
    title: '會員等級 / 權益',
    routeKey: MENU.memberLevel,
    description: '當前等級及權益、升續等累計進度',
    actionDescription: '查看等級 / 權益',
    subItems: [],
  },
  {
    title: '購買紀錄',
    routeKey: MENU.orders,
    description: '追蹤、修改訂單，或安排退貨事宜',
    actionDescription: '查看訂單',
    subItems: [],
  },
  // FIXME: @guychienll future feature comment first
  //{
  //title: '維修服務',
  //routeKey: MENU.maintain,
  //description: '追蹤進度、確認處理狀況',
  //actionDescription: '查看進度',
  //subItems: [],
  //},
  {
    title: '帳戶設定',
    routeKey: MENU._profileSetting,
    description: '管理登入、聯絡資訊、運送及帳單',
    actionDescription: '',
    subItems: [
      {
        title: '個人資料',
        routeKey: MENU.personalInfo,
      },
      {
        title: '變更密碼',
        routeKey: MENU.changePassword,
      },
      {
        title: '信用卡管理',
        routeKey: MENU.cardManagement,
      },
      {
        title: '常用地址',
        routeKey: MENU.addrManagement,
      },
      {
        title: '指定服務門市',
        routeKey: MENU.myFavStore,
      },
    ],
  },
];

const LandingMenuItem = function(props) {
  const {item, style} = props;
  return (
    <LandingMenuItemWrapper style={style}>
      <h2>{item.title}</h2>
      <p>{item.description}</p>

      {item.subItems.length > 0 ? (
        <div className="subitems">
          {item.subItems.map((subItem, idx, arr) => {
            const _isLast = arr.length === idx + 1;
            const _isFirst = idx === 0;
            return (
              <div
                className="action"
                key={idx}
                onClick={() =>
                  NavActions.navigate(`/profile?active=${subItem.routeKey}`)
                }
                style={{
                  paddingLeft: _isFirst ? 0 : 10,
                  paddingRight: 10,
                  borderRight: _isLast
                    ? 'none'
                    : `1px solid ${Color.mainDark_30}`,
                }}>
                <span>{subItem.title}</span>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className="action"
          onClick={() =>
            item.routeKey &&
            NavActions.navigate(`/profile?active=${item.routeKey}`)
          }>
          <span>{item.actionDescription}</span>
          <SvgIcon.ChevronRight size={16} color={Color.mainDark} />
        </div>
      )}
    </LandingMenuItemWrapper>
  );
};

const LandingMenuItemWrapper = styled.div`
  padding: 32px 0px;
  & > h2 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: ${Color.mainDark};
    margin-bottom: 8px;
  }

  & > p {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${Color.mainDark_70};
    margin-bottom: 24px;
  }

  & .subitems {
    display: flex;
    align-items: center;
  }
  & .action {
    cursor: pointer;
    display: flex;
    align-items: center;

    & span {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
    }
  }

  & > .subitems {
    & > .action {
    }
  }

  & > .action {
    & span {
      margin-right: 4px;
    }
  }
`;

export function MemberCenterLandingMenu(props) {
  return (
    <LandingMenuWrapper>
      {MENU_ITEMS.map((item, idx, arr) => {
        const _isLastRow =
          Math.ceil((idx + 1) / 2) > Math.floor(arr.length / 2);
        return (
          <LandingMenuItem
            item={item}
            key={idx}
            style={{
              flexBasis: 'calc((100% - 30px) / 2)',
              marginRight: idx % 2 === 0 ? 30 : 0,
              borderBottom: _isLastRow
                ? 'none'
                : `1px solid ${Color.mainDark_10}`,
            }}
          />
        );
      })}
    </LandingMenuWrapper>
  );
}

export function MemberCenterMenu(props) {
  const {active, style = {}} = props;
  const {mobile, tablet} = useDimension();
  const desktop = !(mobile || tablet);
  const app = useContext(Context);
  const [profileSubMenuExpand, setProfileSubMenuExpand] = useState(false);

  return (
    <Fragment>
      <Wrapper style={style}>
        {desktop && (
          <div
            onClick={() => {
              NavActions.navigate('/profile');
            }}
            style={{
              fontSize: 24,
              fontWeight: 300,
              marginBottom: 20,
              cursor: 'pointer',
            }}>
            會員中心
          </div>
        )}
        {MENU_ITEMS.map((item, idx) => {
          if (item.routeKey === MENU._profileSetting) {
            return (
              <Fragment key={idx}>
                <div
                  onClick={() => {
                    setProfileSubMenuExpand(prev => !prev);
                  }}>
                  <div
                    style={{
                      marginBottom: 12,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <div className="menu">帳戶設定</div>
                    {profileSubMenuExpand ? (
                      <SvgIcon.Minus />
                    ) : (
                      <SvgIcon.Plus />
                    )}
                  </div>
                </div>
                {profileSubMenuExpand &&
                  item.subItems.map((subMenu, idx) => (
                    <div
                      key={idx}
                      className={`submenu ${
                        active === subMenu.routeKey ? 'active' : ''
                      }`}
                      style={{marginBottom: 14}}
                      onClick={() =>
                        NavActions.navigate(
                          `/profile?active=${subMenu.routeKey}`,
                        )
                      }>
                      {subMenu.title}
                    </div>
                  ))}
                {app.currentUser && (
                  <FlexRow>
                    <div
                      className="menu"
                      style={{
                        fontWeight: 300,
                      }}
                      onClick={() => {
                        Actions.logout();
                        window.location.reload();
                      }}>
                      登出
                    </div>
                  </FlexRow>
                )}
              </Fragment>
            );
          }

          return (
            <div
              key={idx}
              className={`menu ${active === item.routeKey ? 'active' : ''}`}
              onClick={() =>
                item.routeKey &&
                NavActions.navigate(`/profile?active=${item.routeKey}`)
              }>
              {item.title}
            </div>
          );
        })}
      </Wrapper>
    </Fragment>
  );
}

const LandingMenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  width: 240px;
  font-weight: 300;

  & > h2 {
    font-size: 24px;
    line-height: 1.33;
    margin: 0 0 20px 0;
  }

  & .menu {
    font-size: 16px;
    line-height: 1.5;
    cursor: pointer;
    font-weight: 300;
    height: 44px;
    display: flex;
    align-items: center;
  }

  & > .submenu {
    padding-left: 20px;
    font-size: 14px;
    line-height: 1.5;
    cursor: pointer;
    height: 22px;
    display: flex;
    align-items: center;
  }

  & > .active {
    font-weight: bold;
  }
`;

export default MemberCenterMenu;
