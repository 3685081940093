import React from 'react';
import styled from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexColCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FlexCenter = FlexRowCenter;

// Colors
export const Color = {
  mainWhite: '#fff',
  mainWhite_30: 'rgba(255, 255, 255, 0.3)',
  mainWhite_50: 'rgba(255, 255, 255, 0.5)',
  mainWhite_70: 'rgba(255, 255, 255, 0.7)',
  mainDark: '#141414',
  mainDark_03: 'rgba(20, 20, 20, 0.03)',
  mainDark_10: 'rgba(20, 20, 20, 0.1)',
  mainDark_20: 'rgba(20, 20, 20, 0.2)',
  mainDark_30: 'rgba(20, 20, 20, 0.3)',
  mainDark_40: 'rgba(20, 20, 20, 0.4)',
  mainDark_50: 'rgba(20, 20, 20, 0.5)',
  mainDark_70: 'rgba(20, 20, 20, 0.7)',
  mainGray_18: 'rgba(0,0,0,0.18)', //d0d0d0
  mainGray_78: 'rgba(0,0,0,0.78)', //383838
  transparent: 'rgba(0,0,0,0)',
  red: '#e22828',
  lightRed: '#F7E8E8',
  gold: '#cca75c',
  lightGold: '#F9F6EF',
};

// Texts
export const FontSize = {
  banner: 56,
  title: 38,
  subtitle: 30,
  highlight: 24,
  highlight_s: 20,
  paragraph: 16,
  meta: 14,
  mini: 12,
};

export const LineHeight = {
  banner: 64,
  title: 46,
  subtitle: 38,
  highlight: 32,
  highlight_s: 28,
  paragraph: 22,
  meta: 20,
  mini: 20,
};

export const TextBanner = props => {
  return (
    <TextStyleWrapper style={props.style || {}} css={props.extraCss || ''}>
      <div className="text__banner">{props.children}</div>
    </TextStyleWrapper>
  );
};

export const TextTitle = props => {
  return (
    <TextStyleWrapper style={props.style || {}} css={props.extraCss || ''}>
      <div className="text__title">{props.children}</div>
    </TextStyleWrapper>
  );
};

export const TextSubtitle = props => {
  return (
    <TextStyleWrapper style={props.style || {}} css={props.extraCss || ''}>
      <div className="text__subtitle">{props.children}</div>
    </TextStyleWrapper>
  );
};

export const TextHighLight = props => {
  return (
    <TextStyleWrapper style={props.style || {}} css={props.extraCss || ''}>
      <div className="text__highlight">{props.children}</div>
    </TextStyleWrapper>
  );
};

export const TextHighLightS = props => {
  return (
    <TextStyleWrapper style={props.style || {}} css={props.extraCss || ''}>
      <div className="text__highlight-s">{props.children}</div>
    </TextStyleWrapper>
  );
};

export const TextParagraph = props => {
  return (
    <TextStyleWrapper style={props.style || {}} css={props.extraCss || ''}>
      <div className="text__paragraph">{props.children}</div>
    </TextStyleWrapper>
  );
};

export const TextMeta = props => {
  return (
    <TextStyleWrapper style={props.style || {}} css={props.extraCss || ''}>
      <div className="text__meta">{props.children}</div>
    </TextStyleWrapper>
  );
};

const TextStyleWrapper = styled.div`
  color: ${Color.mainDark};
  text-align: center;

  & > .text__banner {
    font-size: ${FontSize.banner}px;
    line-height: ${LineHeight.banner}px;
    font-weight: bold;
  }

  & > .text__title {
    font-size: ${FontSize.title}px;
    line-height: ${LineHeight.title}px;
    font-weight: bold;
  }

  & > .text__subtitle {
    font-size: ${FontSize.subtitle}px;
    line-height: ${LineHeight.subtitle}px;
    font-weight: bold;
  }

  & > .text__highlight {
    font-size: ${FontSize.highlight}px;
    line-height: ${LineHeight.highlight}px;
    font-weight: bold;
  }

  & > .text__highlight-s {
    font-size: ${FontSize.highlight_s}px;
    line-height: ${LineHeight.highlight_s}px;
    font-weight: bold;
  }

  & > .text__paragraph {
    font-size: ${FontSize.paragraph}px;
    line-height: ${LineHeight.paragraph}px;
  }

  & > .text__meta {
    font-size: ${FontSize.meta}px;
    line-height: ${LineHeight.meta}px;
  }

  & > * {
    ${props => props.css || ''}
  }
`;

export const fstyles = {
  text12: `font-size: ${FontSize.mini}px; line-height: ${LineHeight.mini}px;`,
  text14: `font-size: ${FontSize.meta}px; line-height: ${LineHeight.meta}px;`,
  text16: `font-size: ${FontSize.paragraph}px; line-height: ${LineHeight.paragraph}px;`,
  text20: `font-size: ${FontSize.highlight_s}px; line-height: ${LineHeight.highlight_s}px;`,
  text24: `font-size: ${FontSize.highlight}px; line-height: ${LineHeight.highlight}px;`,
  text30: `font-size: ${FontSize.subtitle}px; line-height: ${LineHeight.subtitle}px;`,
  text38: `font-size: ${FontSize.title}px; line-height: ${LineHeight.title}px;`,
  text56: `font-size: ${FontSize.banner}px; line-height: ${LineHeight.banner}px;`,
};

export default {
  // text
  TextBanner,
  TextTitle,
  TextSubtitle,
  TextHighLight,
  TextHighLightS,
  TextParagraph,
  TextMeta,
  fstyles,
};

export function ClearButton(props) {
  const {children} = props;
  return <ClearButtonWrapper {...props}>{children}</ClearButtonWrapper>;
}

const ClearButtonWrapper = styled.button`
  outline: none;
  border: none;
  padding: 6px;
  cursor: pointer;
`;

export function CheckButton(props) {
  const {checked, children} = props;
  return (
    <CheckButtonWrapper checked={checked} {...props}>
      <div className="icon">
        <div className="checked" />
      </div>

      <label>{children}</label>
    </CheckButtonWrapper>
  );
}

export const CheckBox = props => {
  const {isOn, onClick, size = 20} = props;
  return (
    <button
      style={{
        minWidth: size,
        width: size,
        height: size,
        outline: 'none',
        border: `1px solid ${Color.mainDark_70}`,
        backgroundColor: isOn ? Color.mainDark : Color.mainWhite,
        color: Color.mainWhite,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={onClick}>
      ✓
    </button>
  );
};

const CheckButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  & > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: solid 1px rgba(20, 20, 20, 0.3);
    margin-right: 12px;

    & > .checked {
      width: ${props => (props.checked ? 8 : 0)}px;
      height: ${props => (props.checked ? 8 : 0)}px;
      background-color: ${props => (props.checked ? 'black' : 'white')};
      transition: 200ms;
    }
  }

  & > label {
    flex: 1;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
  }
`;
export const Spinner = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  & {
    font-size: 2px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid ${Color.mainGray_18};
    border-right: 1.1em solid ${Color.mainGray_18};
    border-bottom: 1.1em solid ${Color.mainGray_18};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear;
  }
  @-webkit-keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const UnderlineButton = styled.button`
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${Color.mainDark};
  font-size: 14px;
  line-height: calc(14px * 1.5);
  padding: 0.5px 0;
  :hover {
    color: ${Color.mainDark_70};
    cursor: pointer;
  }
  :disabled {
    color: ${Color.mainDark_30};
    cursor: not-allowed;
  }
`;

export const SeparateLine = styled.div`
  border-top: 1px solid ${Color.mainDark_10};
  width: 100%;
  margin-top: 17px;
  margin-bottom: 18px;
`;

export const CustomNavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: var(--scroll-bar-height);
  height: var(--navbar-height);
  z-index: 10;
  background-color: white;
  width: 100%;
  padding: 0 10px;
`;

export const Block = styled.div`
  padding: 12px 20px;
  margin: 4px 0;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  line-height: 1.57;
  white-space: pre-line;
  ${props =>
    props.warning &&
    `
    background-color: ${Color.lightGold};
    color: ${Color.gold};
  `};
  ${props =>
    props.error &&
    `
    background-color: ${Color.lightRed};
    color: ${Color.red};
  `}
`;

export const AnimSubMenu = styled.div`
  height: ${props => (props.active ? props.items * 40 : 0)}px;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 200ms;
  padding-top: 5px;
  overflow: hidden;
  & > .submenu-item {
    padding: 4px 0 4px 20px;
    cursor: pointer;
  }
`;
