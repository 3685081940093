import {
  Color,
  FlexCol,
  FlexColCenter,
  FlexRow,
  FontSize,
  fstyles,
} from '../Widget';
import * as L from '../../Reducers/Lang';
import {getFormatPrice} from '../../Utils/ProductUtil';
import React, {Fragment} from 'react';
import {ACTION_BUTTONS_TYPE, ActionButtons, Content, Counter} from './CartItem';
import styled from 'styled-components';
import FixedRatioImage from '../FixedRatioImage';
import {NavActions} from '../../Contexts/AppContext';

const DesktopCartItem = props => {
  const {
    item,
    isAvailable,
    itemStatus,
    type,
    onCounterClick,
    quantity,
    deleteClickHandler,
    nextBuyClickHandler,
    priceTag,
    discountTag,
  } = props;
  const {colorDisplay, size, images, product} = item.variant;

  return (
    <Wrapper isAvailable={isAvailable}>
      <FlexRow
        style={{
          flex: 1,
          justifyContent: 'space-between',
        }}>
        <FlexRow
          style={{
            height: '100%',
            filter: isAvailable ? 'none' : 'opacity(0.2)',
            flex: 1,
          }}>
          <div
            style={{cursor: 'pointer'}}
            onClick={async () => {
              await NavActions.navigate(
                `/product?id=${item.variant.product.id}`,
              );
            }}>
            <FixedRatioImage
              width="144px"
              image={images[0]}
              mode="cover"
              ratio={1.25}
            />
          </div>

          <FlexCol
            style={{
              height: '100%',
              justifyContent: 'space-between',
              paddingLeft: '17px',
            }}>
            <Content>
              {discountTag}
              <div
                style={{cursor: 'pointer'}}
                onClick={async () => {
                  await NavActions.navigate(
                    `/product?id=${item.variant.product.id}`,
                  );
                }}
                className="name">
                {L.d(product.title)}
              </div>
              <div className="desc">
                {L.d(colorDisplay)}{' '}
                {L.d(colorDisplay) && L.d(size) && <span>/</span>} {L.d(size)}
              </div>
              <div className="desc">數量 : {quantity}</div>
            </Content>
            {isAvailable && (
              <ActionButtons
                actionButtonsType={ACTION_BUTTONS_TYPE.UNDERLINE}
                itemStatus={itemStatus}
                cartItemType={type}
                onDeleteClick={deleteClickHandler}
                onNextBuyClick={nextBuyClickHandler}
              />
            )}
          </FlexCol>
        </FlexRow>

        <FlexColCenter>
          {isAvailable ? (
            <FlexColCenter>
              <Counter onChange={onCounterClick} quantity={quantity} />
              {itemStatus.isInsufficient && (
                <div
                  style={{
                    fontSize: FontSize.mini,
                    color: '#e22828',
                    margin: '8px 0',
                  }}>
                  庫存不足
                </div>
              )}
            </FlexColCenter>
          ) : (
            <ActionButtons
              itemStatus={itemStatus}
              actionButtonsType={ACTION_BUTTONS_TYPE.BLOCK}
              cartItemType={type}
              onDeleteClick={deleteClickHandler}
              onNextBuyClick={nextBuyClickHandler}
            />
          )}
        </FlexColCenter>

        <div className="price-tag-container">{priceTag}</div>
      </FlexRow>
    </Wrapper>
  );
};

export default DesktopCartItem;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  ${fstyles.text14}

  & .price-tag-container {
    align-items: flex-end;
    filter: ${({isAvailable}) => (isAvailable ? 'none' : 'opacity(0.2)')};
    min-width: 150px;
    margin-left: 24px;
    & .price {
      :last-child {
        font-size: 14px;
        font-weight: bold;
      }
    }
    & .deprecated {
      color: ${Color.mainDark_70};
      text-decoration: line-through;
    }
  }

  .now {
    display: flex;
    color: ${Color.mainDark};
    font-weight: bold;
    line-height: 24px;
    & > div {
      margin-left: 2px;
    }
  }
`;
