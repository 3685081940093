import styled from 'styled-components';
import {Color, FlexRow, fstyles} from '../Widget';
import {Actions, NavActions} from '../../Contexts/AppContext';
import * as L from '../../Utils/Lang';
import React from 'react';
import {useDimension} from '../../Hooks/AppHooks';

export const SearchList = props => {
  const {brand, keyword, collection, category, amount} = props;
  const {mobile} = useDimension();
  return (
    <Wrapper mobile={mobile}>
      <FlexRow
        onMouseDown={() => {
          NavActions.navigate(
            `/search?brand_id=${brand.id}&collection_id=${
              collection ? collection.id : 0
            }&category_id=${category ? category.id : 0}&keyword=${keyword}`,
          );
          Actions.setGlobalSlideDownContent(null);
        }}
        className="list">
        <span className="collection">{keyword}</span>
        <div style={{margin: '0 8px'}}>
          <span>{`${L.d(brand.title)}`}</span>
          {category && (
            <>
              <span>・</span>
              <span>{`${L.d(category.title)}`}</span>
            </>
          )}
          {collection && (
            <>
              <span>・</span>
              <span>{`${L.d(collection.title)}`}</span>
            </>
          )}
        </div>
        <div className="product_amount">({amount})</div>
      </FlexRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: ${props => (props.mobile ? '272px' : '656px')};
  display: flex;
  flex-direction: column;
  color: ${Color.mainDark};
  ${fstyles.text16}
  & > .list > .collection {
    color: ${Color.mainDark_50};
  }
  & > .list {
    margin: 8px 0;
    cursor: pointer;
    position: relative;
    align-self: start;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: #141414;
      transition: width 400ms ease-in;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
`;
