import React from 'react';
import useDimension from './Hooks/useDimension';
import {Context, RWD} from './GlobalContext';

function GlobalProvider(props) {
  const [dimension, setDimension] = React.useState({});
  const [rwd, setRwd] = React.useState(RWD.mobile);

  const onNextDimension = React.useCallback(
    nextDimension => {
      setDimension(nextDimension);
      if (nextDimension.innerWidth > 1023) {
        setRwd(RWD.desktop);
      } else if (nextDimension.innerWidth > 640) {
        setRwd(RWD.tablet);
      } else {
        setRwd(RWD.mobile);
      }
    },
    [setDimension, setRwd],
  );

  useDimension({
    setValue: onNextDimension,
  });

  return (
    <Context.Provider value={{dimension, rwd}}>
      {props.children}
    </Context.Provider>
  );
}

class Consumer extends React.Component {
  render() {
    return (
      <Context.Consumer>{state => this.props.children(state)}</Context.Consumer>
    );
  }
}

function withConsumer(Comp) {
  class WrappedComp extends React.Component {
    render() {
      return (
        <Consumer>{state => <Comp rwd={state} {...this.props} />}</Consumer>
      );
    }
  }

  WrappedComp.displayName = `WithConfig-${Comp.displayName}`;
  return WrappedComp;
}

export {GlobalProvider, Consumer, withConsumer};
