import {Color, FlexCol, FlexRow, fstyles} from '../Widget';
import * as L from '../../Reducers/Lang';
import React from 'react';
import styled from 'styled-components';
import {Content} from './CartItem';
import FixedRatioImage from '../FixedRatioImage';
import {NavActions} from '../../Contexts/AppContext';

const MiniCartItem = props => {
  const {itemStatus, item, isAvailable, priceTag, discountTag} = props;
  const {isPublished, isSoldOut, isInsufficient} = itemStatus;
  const {quantity, variant} = item;
  const {colorDisplay, size, images, product} = variant;

  return (
    <Wrapper>
      <FlexRow style={{height: '100%', width: '100%'}}>
        <div style={{position: 'relative'}}>
          <div
            style={{cursor: 'pointer'}}
            onClick={async () => {
              await NavActions.navigate(
                `/product?id=${item.variant.product.id}`,
              );
            }}>
            <FixedRatioImage
              extraCss={{filter: isAvailable ? 'none' : 'opacity(0.2)'}}
              width={112}
              ratio={10 / 8}
              image={images[0]}
              mode="cover"
            />
          </div>

          {isSoldOut && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }}
              className="itemStatus">
              商品已售完
            </div>
          )}
          {!isPublished && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }}
              className="itemStatus">
              商品已售完
            </div>
          )}
        </div>
        <FlexCol
          style={{
            height: '100%',
            width: '100%',
            justifyContent: 'space-between',
            paddingLeft: '17px',
            filter: isAvailable ? 'none' : 'opacity(0.2)',
          }}>
          <Content>
            {discountTag}
            <div
              style={{cursor: 'pointer'}}
              onClick={async () => {
                await NavActions.navigate(
                  `/product?id=${item.variant.product.id}`,
                );
              }}
              className="name">
              {L.d(product.title)}
            </div>
            <div className="desc">
              {L.d(colorDisplay)}{' '}
              {L.d(colorDisplay) && L.d(size) && <span>/</span>} {L.d(size)}
            </div>
            <div className="desc">數量 : {quantity}</div>
          </Content>
          <FlexRow style={{width: '100%', justifyContent: 'space-between'}}>
            {isInsufficient && isAvailable && (
              <div className="itemStatus" style={{color: '#e22828'}}>
                庫存不足
              </div>
            )}
            <div className="price-tag-container">{priceTag}</div>
          </FlexRow>
        </FlexCol>
      </FlexRow>
    </Wrapper>
  );
};

export default MiniCartItem;

const Wrapper = styled.div`
  width: 100%;
  height: 140px;
  position: relative;
  white-space: nowrap;
  ${fstyles.text12}
  & .deprecated {
    color: ${Color.mainDark_70};
    text-decoration: line-through;
  }
  & .now {
    display: flex;
    color: ${Color.mainDark};
    font-weight: bold;
    line-height: 22px;
    & > div {
      margin-left: 2px;
    }
  }
  & .price-tag-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    white-space: nowrap;
    & > .price {
      margin-left: 8px;
      :last-child {
        font-size: 14px;
        font-weight: bold;
      }
    }
    & .deprecated {
      color: ${Color.mainDark_70};
      text-decoration: line-through;
    }
  }
`;
