import {brandNameToBrandId, brandsObj} from './Brand';

export const MEMBERSHIP_LEVEL = {
  M: 'M', // normal
  F: 'F', // silver ( i guess
  G: 'G', // gold
  D: 'D', //diamond
};

export const MEMBERSHIP_LEVEL_DISPLAY = {
  // FIXME: i18n
  [MEMBERSHIP_LEVEL.M]: {zh: '一般', en: 'normal'},
  [MEMBERSHIP_LEVEL.F]: {zh: '銀卡', en: 'silver'}, // silver ( i guess
  [MEMBERSHIP_LEVEL.G]: {zh: '金卡', en: 'gold'},
  [MEMBERSHIP_LEVEL.D]: {zh: '鑽卡', en: 'diamond'},
};

export const MEMBERSHIP_CONFIG = {
  [MEMBERSHIP_LEVEL.M]: {
    type: MEMBERSHIP_LEVEL.M, // 卡種
    onceConsumptionPrice: 0, //onceConsumptionPrice 單筆消費升等門檻 （ 命名錯誤 )
    birthGiftPoints: 0, // 回饋生日禮金
    rebatePointsRatio: 0, // 回饋購物金％
    levelUpPrice: 12000, //升等獎勵
    renewPrice: 0, //續卡獎勵
    grandTotalLevelUpThreshold: 0, // 累計消費升等門檻
    grandTotalRenewThreshold: 0, // 累計續卡門檻
    discount: {
      [brandsObj.kenzo]: 0,
      [brandsObj.agete]: 0,
      [brandsObj.isabelMarant]: 0,
      [brandsObj.lesNereides]: 0,
      [brandsObj.selfPortrait]: 0,
    },
  },
  [MEMBERSHIP_LEVEL.F]: {
    type: MEMBERSHIP_LEVEL.F,
    onceConsumptionPrice: 20000,
    birthGiftPoints: 500,
    rebatePointsRatio: 1,
    levelUpPrice: 2000,
    renewPrice: 1000,
    grandTotalLevelUpThreshold: 0,
    grandTotalRenewThreshold: 30000,
    discount: {
      [brandsObj.kenzo]: 0,
      [brandsObj.agete]: 0,
      [brandsObj.isabelMarant]: 0,
      [brandsObj.lesNereides]: 10,
      [brandsObj.selfPortrait]: 0,
    },
  },
  [MEMBERSHIP_LEVEL.G]: {
    type: MEMBERSHIP_LEVEL.G,
    onceConsumptionPrice: 40000,
    birthGiftPoints: 1000,
    rebatePointsRatio: 2,
    levelUpPrice: 4000,
    renewPrice: 2000,
    grandTotalLevelUpThreshold: 60000,
    grandTotalRenewThreshold: 60000,
    discount: {
      [brandsObj.kenzo]: 10,
      [brandsObj.agete]: 5,
      [brandsObj.isabelMarant]: 10,
      [brandsObj.lesNereides]: 15,
      [brandsObj.selfPortrait]: 10,
    },
  },
  [MEMBERSHIP_LEVEL.D]: {
    type: MEMBERSHIP_LEVEL.D,
    onceConsumptionPrice: 120000,
    birthGiftPoints: 2000,
    rebatePointsRatio: 4,
    levelUpPrice: 6000,
    renewPrice: 4000,
    grandTotalLevelUpThreshold: 150000,
    grandTotalRenewThreshold: 150000,
    discount: {
      [brandsObj.kenzo]: 20,
      [brandsObj.agete]: 10,
      [brandsObj.isabelMarant]: 20,
      [brandsObj.lesNereides]: 20,
      [brandsObj.selfPortrait]: 20,
    },
  },
};

export const MEMBERSHIP_LEVEL_THRESHOLDS = [
  {
    key: MEMBERSHIP_LEVEL.M,
    threshold: 0,
    grandTotalLevelUpThreshold: null,
    rewards: 0,
    reward_ratio: 0,
  },
  {
    key: MEMBERSHIP_LEVEL.F,
    threshold: 20000,
    grandTotalLevelUpThreshold: null,
    rewards: 0,
    reward_ratio: 0.01,
  },
  {
    key: MEMBERSHIP_LEVEL.G,
    threshold: 40000,
    grandTotalLevelUpThreshold: 60000,
    rewards: 2000,
    reward_ratio: 0.02,
  },
  {
    key: MEMBERSHIP_LEVEL.D,
    threshold: 120000,
    grandTotalLevelUpThreshold: 150000,
    rewards: 12000,
    gold_rewards: 6000,
    reward_ratio: 0.04,
  },
];

export const MEMBER_SOURCE = {
  web: 'web',
  app: 'app',
  pos: 'pos',
};
