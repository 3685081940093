import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Color, FlexCol, FlexRow, FontSize, UnderlineButton} from '../Widget';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../RectButton';
import {Actions, Context} from '../../Contexts/AppContext';
import Confirm from '../Modal/Confirm';
import {getFormatCartItems, getPriceTag} from '../../Utils/ProductUtil';
import DesktopCartItem from './DesktopCartItem';
import MiniCartItem from './MiniCartItem';
import MobileCartItem from './MobileCartItem';
import * as SvgIcon from '../SvgIcon';
import {MEMBERSHIP_CONFIG, MEMBERSHIP_LEVEL_DISPLAY} from '../../Domain/Member';
import {brandIdToBrandName} from '../../Domain/Brand';
import * as L from '../../Utils/Lang';

export const CART_ITEM_TYPE = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  MINI: 'mini',
};

const CartItem = props => {
  const {
    type = CART_ITEM_TYPE.DESKTOP,
    item,
    style,
    items = [],
    isEditing = false,
    setIsEditing = () => {},
  } = props;

  const {variant, quantity} = item;
  const {product} = variant;

  const app = useContext(Context);
  const isMember = Boolean(app.currentUser);
  const isPromote = Boolean(product.promotion);

  const [itemStatus, setItemStatus] = useState({
    isSoldOut: variant.stock <= 0,
    isPublished: variant.product.published,
    isInsufficient: variant.stock < quantity,
    isDiscount: isMember || isPromote,
  });

  const currentItems = items.map(item => ({
    variant_id: item.variant.id,
    quantity: item.quantity,
  }));

  const isAvailable = !itemStatus.isSoldOut && itemStatus.isPublished;

  useEffect(() => {
    setItemStatus({
      isSoldOut: variant.stock <= 0,
      isPublished: variant.product.published,
      isInsufficient: variant.stock < quantity,
      isDiscount: isMember || isPromote,
    });
  }, [
    isMember,
    isPromote,
    product.promotion,
    quantity,
    variant.product.published,
    variant.stock,
  ]);

  const deleteClickHandler = () => {
    Actions.setGlobalModalContent(
      <Confirm
        onClick={async () => {
          const nextCart = currentItems
            .map(item => {
              if (item.variant_id !== variant.id) {
                return item;
              }
              return null;
            })
            .filter(item => item !== null);
          await Actions.updateCart({items: nextCart});
          Actions.setGlobalModalContent(null);
        }}
        cancelLabel="取消"
        confirmLabel="確定移除"
        title="確定要移除這個商品嗎？"
      />,
    );
  };

  const nextBuyClickHandler = () => {
    Actions.setGlobalModalContent(
      <Confirm
        onClick={async () => {
          const nextCart = currentItems
            .map(item => {
              if (item.variant_id !== variant.id) {
                return item;
              }
              return null;
            })
            .filter(item => item !== null);
          await Actions.updateCart({items: nextCart});
          //TODO: Guy call favorite api
          Actions.setGlobalModalContent(null);
        }}
        cancelLabel="取消"
        confirmLabel="確定下次再買"
        title="確定要下次再買這個商品嗎？"
      />,
    );
  };

  const onCounterClick = type => async _e => {
    let result;
    if (type === 'plus') {
      result = quantity + 1;
    } else {
      result = quantity - 1;
    }
    if (result === 0) {
      await deleteClickHandler();
    } else {
      try {
        Actions.setLoading(true);
        await updateCartForQuantity({id: variant.id, quantity: result});
      } finally {
        Actions.setLoading(false);
      }
    }
  };

  const updateCartForQuantity = async ({id, quantity}) => {
    try {
      const nextCartItems = getFormatCartItems(items);
      const targetIndex = nextCartItems.findIndex(i => i.variant_id === id);
      nextCartItems[targetIndex].quantity = quantity;
      await Actions.updateCart({items: nextCartItems});
    } catch (err) {
      console.log(err, 'update quantity failed');
      throw err;
    }
  };

  const priceFlag = useMemo(() => {
    if (!variant) {
      return 'none';
    }
    if (!isMember && !isPromote) {
      return 'none';
    } else if (isMember && isPromote) {
      return variant.promote_price <= variant.member_price
        ? 'promote'
        : 'member';
    } else if (isMember && !isPromote) {
      return 'member';
    } else {
      return 'promote';
    }
  }, [isMember, isPromote, variant]);

  const memberDiscountRatio = useMemo(() => {
    if (!product || !app.currentUser) {
      return false;
    }
    return MEMBERSHIP_CONFIG[app.currentUser.vip_level].discount[
      brandIdToBrandName[product.brand_id]
    ];
  }, [app.currentUser, product]);

  const discountTag = useMemo(() => {
    if (priceFlag === 'member' && memberDiscountRatio !== 0) {
      return (
        <div className="discount">
          {L.d(MEMBERSHIP_LEVEL_DISPLAY[app.currentUser.vip_level])} -
          {memberDiscountRatio}%
        </div>
      );
    } else if (priceFlag === 'promote') {
      if (!product.promotion) {
        return null;
      }
      return (
        <div className="discount">
          {L.d(product.promotion.title)} -{100 - product.promotion.ratio} %
        </div>
      );
    } else {
      return null;
    }
  }, [
    app.currentUser?.vip_level,
    memberDiscountRatio,
    priceFlag,
    product.promotion,
  ]);

  const priceTag = getPriceTag({
    priceSet: {
      price: variant.price * quantity,
      member_price: variant.member_price * quantity,
      promote_price: variant.promote_price * quantity,
    },
    isPromote,
    isMember,
    mobile: true,
  });

  return (
    <div style={style}>
      {type === CART_ITEM_TYPE.DESKTOP && (
        <DesktopCartItem
          type={type}
          itemStatus={itemStatus}
          isAvailable={isAvailable}
          item={item}
          quantity={quantity}
          onCounterClick={onCounterClick}
          deleteClickHandler={deleteClickHandler}
          nextBuyClickHandler={nextBuyClickHandler}
          priceTag={priceTag}
          discountTag={discountTag}
        />
      )}

      {type === CART_ITEM_TYPE.MINI && (
        <MiniCartItem
          item={item}
          itemStatus={itemStatus}
          type={type}
          isAvailable={isAvailable}
          quantity={quantity}
          deleteClickHandler={deleteClickHandler}
          nextBuyClickHandler={nextBuyClickHandler}
          priceTag={priceTag}
          discountTag={discountTag}
        />
      )}

      {type === CART_ITEM_TYPE.MOBILE && (
        <MobileCartItem
          item={item}
          itemStatus={itemStatus}
          type={type}
          isAvailable={isAvailable}
          quantity={quantity}
          onCounterClick={onCounterClick}
          deleteClickHandler={deleteClickHandler}
          nextBuyClickHandler={nextBuyClickHandler}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          priceTag={priceTag}
          discountTag={discountTag}
        />
      )}
    </div>
  );
};

export default CartItem;

export const Counter = props => {
  const {onChange, quantity = 0} = props;
  return (
    <FlexRow
      style={{
        width: '80px',
        justifyContent: 'space-between',
        fontWeight: 'bold',
      }}>
      <StyledCounterButton
        size={20}
        skin={BUTTON_SKIN.DEFAULT}
        disabled={quantity <= 0}
        onClick={onChange('minus')}>
        <SvgIcon.Minus size={20} />
      </StyledCounterButton>
      {quantity}
      <StyledCounterButton
        skin={BUTTON_SKIN.DEFAULT}
        size={20}
        onClick={onChange('plus')}>
        <SvgIcon.Plus size={20} />
      </StyledCounterButton>
    </FlexRow>
  );
};

const StyledCounterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: ${Color.mainWhite};
  width: ${({size}) => `${size}px`};
  height: ${({size}) => `${size}px`};
  border: 1px solid ${Color.mainDark_30};
  color: ${Color.mainDark_30};
  & svg,
  path {
    color: ${Color.mainDark};
  }

  &:hover {
    border: 1px solid ${Color.mainDark};
    color: ${Color.mainDark};
  }
  &:disabled {
    filter: opacity(0.3);
  }
`;

const StyledContent = styled.div`
  & > .discount {
    color: #cca75c;
    max-width: 186px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > .name {
    font-weight: bold;
    color: ${Color.mainDark};
    margin-bottom: 12px;
  }
  .desc {
    color: ${Color.mainDark_70};
  }
`;

export const Content = props => {
  const {children} = props;
  return <StyledContent>{children}</StyledContent>;
};

export const ACTION_BUTTONS_TYPE = {
  UNDERLINE: 'underline',
  BLOCK: 'block',
};

export const ActionButtons = props => {
  const {
    itemStatus,
    actionButtonsType = ACTION_BUTTONS_TYPE.UNDERLINE,
    cartItemType,
    onDeleteClick,
    onNextBuyClick,
  } = props;

  const {isSoldOut, isPublished} = itemStatus;

  return (
    <>
      {actionButtonsType === ACTION_BUTTONS_TYPE.UNDERLINE && (
        <FlexRow
          style={{
            color: Color.mainDark_30,
          }}>
          <UnderlineButton onClick={onDeleteClick}>移除</UnderlineButton>
          {/*FIXME: @guychienll future feature comment first*/}
          {/*{(isSoldOut || cartItemType === CART_ITEM_TYPE.DESKTOP) && (*/}
          {/*  <Fragment>*/}
          {/*    <span style={{margin: '0 10px'}}>|</span>*/}
          {/*    <UnderlineButton onClick={onNextBuyClick}>*/}
          {/*      下次再買*/}
          {/*    </UnderlineButton>*/}
          {/*  </Fragment>*/}
          {/*)}*/}
        </FlexRow>
      )}
      {actionButtonsType === ACTION_BUTTONS_TYPE.BLOCK && (
        <FlexCol style={{height: '100px', width: '88px'}}>
          <div
            style={{
              color: Color.mainDark_50,
              fontSize: FontSize.mini,
              margin: '0 auto',
            }}>
            {isSoldOut && '商品已售完'}
            {!isPublished && '商品已售完'}
          </div>
          <RectButton
            size={BUTTON_SIZE.SMALL}
            skin={BUTTON_SKIN.DEFAULT}
            text="移除"
            style={{margin: '8px 0'}}
            onClick={onDeleteClick}
          />
          {/*FIXME: @guychienll future feature comment first*/}
          {/*{isSoldOut && (*/}
          {/*  <RectButton*/}
          {/*    size={BUTTON_SIZE.SMALL}*/}
          {/*    skin={BUTTON_SKIN.PRIMARY}*/}
          {/*    text="下次再買"*/}
          {/*    onClick={onNextBuyClick}*/}
          {/*  />*/}
          {/*)}*/}
        </FlexCol>
      )}
    </>
  );
};
