// # 金流狀態: 退款中/退款失敗/已退款
// enum payment_status: %i(refunding failed refunded)

// #   history: {
//   #     applied_at:
//   #     processing_at:
//   #     refund_failed_at:
//   #     return_failed_at:
//   #     done_at:
//   #   }

// # 退貨狀態: 申請退貨/已確認商品退款中/退款失敗/退貨失敗/已完成退貨
// enum status: %i(applied processing refund_failed return_failed done)
export const RETURN_STATUS = {
  applied: 'applied',
  processing: 'processing',
  refund_failed: 'refund_failed',
  return_failed: 'return_failed',
  done: 'done',
};

export const RETURN_STATUS_TRANSFORM = {
  applied: 'applied',
  processing: 'processing',
  refund_failed: 'processing', // ui no this step , so make it map to processing
  return_failed: 'return_failed',
  done: 'done',
};

export const RETURN_STATUS_DISPLAY = {
  [RETURN_STATUS.applied]: {
    zh: '申請退貨',
    en: 'applied',
  },
  [RETURN_STATUS.processing]: {
    zh: '已確認商品退款中',
    en: 'processing',
  },
  [RETURN_STATUS.done]: {
    zh: '已完成退款',
    en: 'done',
  },
  [RETURN_STATUS.return_failed]: {
    zh: '退貨失敗',
    en: 'failed',
  },
};

// invoice_status
// # 發票狀態:  未開立/已開立/已完成折讓/折讓失敗
// enum status: %i(unissued issued allowance allowance_failed

export const RETURN_INVOICE_STATUS = {
  unissued: 'unissued',
  issued: 'issued',
  allowance: 'allowance',
  allowance_failed: 'allowance_failed',
};

export const RETURN_INVOICE_STATUS_DISPLAY = {
  [RETURN_INVOICE_STATUS.unissued]: {
    zh: '尚未開立',
    en: 'unissued',
  },
  [RETURN_INVOICE_STATUS.issued]: {
    zh: '已開立',
    en: 'issued',
  },
  [RETURN_INVOICE_STATUS.allowance]: {
    zh: '已完成折讓',
    en: 'allowance',
  },
  [RETURN_INVOICE_STATUS.allowance_failed]: {
    // dont let user to know this step
    zh: '已開立',
    en: 'issued',
  },
};

// unreviewed success failure
export const APPROVE_STATUS = {
  unreviewed: 'unreviewed',
  success: 'success',
  failure: 'failure',
};
