import React from 'react';
import styled from 'styled-components';
import SvgMenu from '../svg-icons/icon-menu.svg';
import SvgLogoKenzo from '../svg-icons/icon-nav-logo-kenzo.svg';
import SvgLogoAgete from '../svg-icons/icon-nav-logo-agete.svg';
import SvgLogoIsabelMarant from '../svg-icons/icon-nav-logo-isabel-marant.svg';
import SvgLogoLesNereides from '../svg-icons/icon-nav-logo-les-nereides.svg';
import SvgLogoSelfPortrait from '../svg-icons/icon-nav-logo-self-portrait.svg';
import SvgChevronBigLeft from '../svg-icons/icon-chevron-big-left.svg';
import SvgChevronBigRight from '../svg-icons/icon-chevron-big-right.svg';
import SvgDropdown from '../svg-icons/icon-dropdown.svg';
import SvgDropup from '../svg-icons/icon-dropup.svg';
import SvgPlus from '../svg-icons/icon-plus.svg';
import SvgMinus from '../svg-icons/icon-minus.svg';
import SvgChevronLeft from '../svg-icons/icon-chevron-left.svg';
import SvgChevronRight from '../svg-icons/icon-chevron-right.svg';
import SvgList from '../svg-icons/icon-list.svg';
import SvgCross from '../svg-icons/icon-cross.svg';
import SvgMap from '../svg-icons/icon-map.svg';
import SvgArrowRight from '../svg-icons/icon-arrow-right.svg';
import SvgEye from '../svg-icons/icon-eye.svg';
import SvgEyeSlash from '../svg-icons/icon-eye-slash.svg';
import SvgEdit from '../svg-icons/icon-edit.svg';
import SvgCopy from '../svg-icons/icon-copy.svg';
import SvgInfo from '../svg-icons/icon-info.svg';
import SvgShare from '../svg-icons/icon-share.svg';
import SvgFacebook from '../svg-icons/icon-social-facebook.svg';
import SvgHome from '../svg-icons/icon-home.svg';
import SvgHomeFill from '../svg-icons/icon-home-fill.svg';
import SvgSearch from '../svg-icons/icon-search.svg';
import SvgSearchFill from '../svg-icons/icon-search-fill.svg';
import SvgCart from '../svg-icons/icon-cart.svg';
import SvgCartFill from '../svg-icons/icon-cart-fill.svg';
import SvgMessage from '../svg-icons/icon-message.svg';
import SvgMessageFill from '../svg-icons/icon-message-fill.svg';
import SvgUser from '../svg-icons/icon-user.svg';
import SvgUserFill from '../svg-icons/icon-user-fill.svg';
import SvgLogoTungrp from '../svg-icons/logo-tun-white.svg';
import SvgBorderPlus from '../svg-icons/icon-plus-border.svg';
import SvgBorderMinus from '../svg-icons/icon-minus-border.svg';
import SvgCube from '../svg-icons/icon-cube.svg';
import SvgCrossCircle from '../svg-icons/icon-cross-circle.svg';
import SvgGps from '../svg-icons/icon-gps.svg';
import SvgSquare from '../svg-icons/icon-square.svg';
import SvgGift from '../svg-icons/icon-gift.svg';
import SvgHardware from '../svg-icons/icon-hardware.svg';
import SvgSparkle from '../svg-icons/icon-sparkle.svg';
import SvgCustomize from '../svg-icons/icon-customize.svg';
import SvgStyle from '../svg-icons/icon-style.svg';

const _overwritePathColor = Svg => props => (
  <Wrapper color={props.color} {...props}>
    <Svg />
  </Wrapper>
);

const Wrapper = styled.div`
  display: inline-block;
  color: ${props => props.color || '#444'};
  width: ${props => props.w || props.size || 24}px;
  height: ${props => props.h || props.size || 24}px;
  & svg {
    width: ${props => props.w || props.size || 24}px;
    height: ${props => props.h || props.size || 24}px;
    & path {
      fill: currentColor;
    }
  }
`;

const Menu = _overwritePathColor(SvgMenu);
const Home = _overwritePathColor(SvgHome);
const HomeFill = _overwritePathColor(SvgHomeFill);
const Search = _overwritePathColor(SvgSearch);
const SearchFill = _overwritePathColor(SvgSearchFill);
const User = _overwritePathColor(SvgUser);
const UserFill = _overwritePathColor(SvgUserFill);
const Cart = _overwritePathColor(SvgCart);
const CartFill = _overwritePathColor(SvgCartFill);
const Message = _overwritePathColor(SvgMessage);
const MessageFill = _overwritePathColor(SvgMessageFill);
const LogoKenzo = _overwritePathColor(SvgLogoKenzo);
const ChevronBigLeft = _overwritePathColor(SvgChevronBigLeft);
const ChevronBigRight = _overwritePathColor(SvgChevronBigRight);
const DropDown = _overwritePathColor(SvgDropdown);
const DropUp = _overwritePathColor(SvgDropup);
const Plus = _overwritePathColor(SvgPlus);
const Minus = _overwritePathColor(SvgMinus);
const ChevronLeft = _overwritePathColor(SvgChevronLeft);
const ChevronRight = _overwritePathColor(SvgChevronRight);
const List = _overwritePathColor(SvgList);
const Cross = _overwritePathColor(SvgCross);
const Map = _overwritePathColor(SvgMap);
const ArrowRight = _overwritePathColor(SvgArrowRight);
const LogoAgete = _overwritePathColor(SvgLogoAgete);
const LogoIsabelMarant = _overwritePathColor(SvgLogoIsabelMarant);
const LogoLesNereides = _overwritePathColor(SvgLogoLesNereides);
const LogoSelfPortrait = _overwritePathColor(SvgLogoSelfPortrait);
const Eye = _overwritePathColor(SvgEye);
const EyeSlash = _overwritePathColor(SvgEyeSlash);
const Edit = _overwritePathColor(SvgEdit);
const Copy = _overwritePathColor(SvgCopy);
const Info = _overwritePathColor(SvgInfo);
const Facebook = _overwritePathColor(SvgFacebook);
const Share = _overwritePathColor(SvgShare);
const LogoTungrp = _overwritePathColor(SvgLogoTungrp);
const BorderPlus = _overwritePathColor(SvgBorderPlus);
const BorderMinus = _overwritePathColor(SvgBorderMinus);
const Cube = _overwritePathColor(SvgCube);
const CrossCircle = _overwritePathColor(SvgCrossCircle);
const Gps = _overwritePathColor(SvgGps);
const Square = _overwritePathColor(SvgSquare);
const Gift = _overwritePathColor(SvgGift);
const Customize = _overwritePathColor(SvgCustomize);
const Hardware = _overwritePathColor(SvgHardware);
const Sparkle = _overwritePathColor(SvgSparkle);
const Style = _overwritePathColor(SvgStyle);

export {
  Menu,
  Search,
  SearchFill,
  User,
  UserFill,
  Cart,
  CartFill,
  Home,
  HomeFill,
  Message,
  MessageFill,
  LogoKenzo,
  LogoAgete,
  LogoIsabelMarant,
  LogoLesNereides,
  LogoSelfPortrait,
  ChevronBigLeft,
  ChevronBigRight,
  DropDown,
  DropUp,
  Plus,
  Minus,
  ChevronLeft,
  ChevronRight,
  List,
  Cross,
  Map,
  ArrowRight,
  Eye,
  EyeSlash,
  Edit,
  Copy,
  Info,
  Share,
  Facebook,
  LogoTungrp,
  BorderMinus,
  BorderPlus,
  Cube,
  CrossCircle,
  Gps,
  Square,
  Gift,
  Customize,
  Hardware,
  Sparkle,
  Style,
};
