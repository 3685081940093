const initialState = [];

export default function reducer(state = initialState, action) {
  let {type, payload} = action;
  switch (type) {
    default:
      break;
  }
  return state;
}

export function list(state) {
  return state;
}
