import React from 'react';

const RWD = {
  mobile: 0,
  tablet: 1,
  desktop: 2,
};

const Context = React.createContext();
const Actions = {};

export {Context, Actions, RWD};
