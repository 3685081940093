import Actions from './Actions';

const initialState = null;

export default function cart(state = initialState, {type, payload}) {
  switch (type) {
    case Actions.SET_CART_DATA:
      return payload;

    default:
      return state;
  }
}

export function getCartData(state) {
  return state;
}
