import React from 'react';
import {brandsArr} from '../Domain/Brand';
import Api from '../Api';

export const Context = React.createContext();
const Actions = {};

class Provider extends React.Component {
  constructor(props) {
    super(props);

    this._watchId = null;
    this.state = {
      permission: null,
      curLocation: null,
    };

    Actions.askPermisssion = () => {
      if ('geolocation' in navigator) {
        /* geolocation is available */
        navigator.geolocation.getCurrentPosition(
          position => {
            this.setState({
              permission: true,
              curLocation: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            });
            // do_something(position.coords.latitude, position.coords.longitude);
          },
          err => {
            this.setState({permission: false, curLocation: null});
            console.warn(err);
          },
        );
      } else {
        /* geolocation IS NOT available */
        this.setState({permission: false, curLocation: null});
      }
    };

    Actions.subscribeLocationWatcher = () => {
      if ('geolocation' in navigator) {
        if (this._watchId) {
          navigator.geolocation.clearWatch(this._watchId);
        }
        this._watchId = navigator.geolocation.watchPosition(position => {
          // do_something(position.coords.latitude, position.coords.longitude);
          this.setState({
            curLocation: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
        });
      }
    };

    Actions.unsubscribeLocationWatcher = () => {
      if ('geolocation' in navigator) {
        this._watchId && navigator.geolocation.clearWatch(this._watchId);
      }
    };
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

class Consumer extends React.Component {
  render() {
    return (
      <Context.Consumer>{state => this.props.children(state)}</Context.Consumer>
    );
  }
}

function withConsumer(Comp) {
  class WrappedComp extends React.Component {
    render() {
      return (
        <Consumer>
          {state => <Comp geolocation={state} {...this.props} />}
        </Consumer>
      );
    }
  }

  WrappedComp.displayName = `WithConfig-${Comp.displayName}`;
  return WrappedComp;
}

export {Provider, Consumer, withConsumer, Actions};
