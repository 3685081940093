import React, {Component, Fragment} from 'react';
import './index.css';
import styled from 'styled-components';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Delighters from './Components/ReactDelighters';
import {Helmet} from 'react-helmet';
import queryString from 'query-string';
import Toast from './Components/Toast';
import Modal from './Components/Modal/Modal';
import favicon from './images/logos/tungrp.ico';
import SlideDown from './Components/SlideDown';
import BottomNavBar from './Components/BottomNavBar';
import ScrollingTextsBar from './Components/ScrollingTextsBar';
import {Actions} from './Contexts/AppContext';
import {DropUp} from './Components/SvgIcon';
import logo from './images/tungrp.jpeg';

const WidgetWrapper = styled.div`
  position: fixed;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  opacity: ${props => (props.visible ? 1 : 0)};
  z-index: 1000;
`;

const Widget = props => {
  const {visible, onClick, icon = null, style = {}} = props;
  return (
    <WidgetWrapper style={style} visible={visible} onClick={onClick}>
      {icon}
    </WidgetWrapper>
  );
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBackToTop: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this._onScroll);
    this.initNav();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onScroll);
  }

  initNav = () => {
    if (this.props.path.indexOf('home') > -1) {
      Actions.setNavType('solid');
    } else if (this.props.path.indexOf('series') > -1) {
      Actions.setNavType('revert');
    } else {
      Actions.setNavType('solid');
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.path !== this.props.path) {
      this.initNav();
    }
  }

  render() {
    let {
      location: {pathname, search},
      app,
      rwd,
    } = this.props;

    pathname = this._normalizePath(pathname);

    let isApp = false;

    try {
      isApp = queryString.parse(window.location.search).app === '1';
    } catch (err) {}

    let withPaddingTop = true;
    let showNavbar = true;
    let showFooter = true;
    let allowBottomNav = false;
    let showScrollingText = app.scrollingTexts.length > 0; // default enable or not based on data result

    if (this.props.rwd.rwd === 0 || this.props.rwd.rwd === 1) {
      showFooter = false;
      showNavbar = false;
    }

    if (pathname === '/') {
      showNavbar = false;
      withPaddingTop = false;
      showFooter = false;
      showScrollingText = false;
    } else if (['/home/'].indexOf(pathname) > -1) {
      showNavbar = true;
      withPaddingTop = true;
      allowBottomNav = true;
    } else if (['/login/'].indexOf(pathname) > -1) {
      withPaddingTop = false;
      showFooter = false;
    } else if (['/search/'].indexOf(pathname) > -1) {
    } else if (['/cart/'].indexOf(pathname) > -1) {
      showNavbar = false;
      withPaddingTop = true;
      allowBottomNav = false;
      showFooter = false;
    } else if (['/checkout/'].indexOf(pathname) > -1) {
      showNavbar = false;
      withPaddingTop = true;
      allowBottomNav = false;
    } else if (['/app-product-reserve/'].indexOf(pathname) > -1) {
      showFooter = false;
      showNavbar = false;
      withPaddingTop = false;
    } else if (['/profile/'].indexOf(pathname) > -1 && search === '') {
      allowBottomNav = true;
    } else if (
      ['/profile/'].indexOf(pathname) > -1 &&
      search.indexOf('maintain_apply') > -1
    ) {
      showNavbar = false;
      withPaddingTop = true;
    } else if (
      ['/profile/'].indexOf(pathname) > -1 &&
      (search.indexOf('action=return') > -1 ||
        search.indexOf('action=return_detail') > -1 ||
        search.indexOf('action=re_checkout') > -1)
    ) {
      showFooter = false;
    } else if (['/news/'].indexOf(pathname) !== -1) {
    } else if (['/news/detail/'].indexOf(pathname) > -1) {
    } else if (['/products/'].indexOf(pathname) > -1) {
    } else if (['/product/'].indexOf(pathname) > -1) {
      if (this.props.rwd.rwd === 0 || this.props.rwd.rwd === 1) {
        withPaddingTop = false;
      }
    } else if (['/stores/'].indexOf(pathname) > -1) {
    } else if (['/series/'].indexOf(pathname) > -1) {
      withPaddingTop = false;
    } else if (['/event/'].indexOf(pathname) > -1) {
      if (this.props.rwd.rwd === 0 || this.props.rwd.rwd === 1) {
        withPaddingTop = false;
      }
    }

    if (isApp) {
      showScrollingText = false;
      showFooter = false;
      allowBottomNav = false;
      showNavbar = false;
      withPaddingTop = false;
    }

    return (
      <Delighters>
        <Wrapper
          ref={this.wrapperRef}
          breakpoints={app.breakpoints}
          withPaddingTop={withPaddingTop}
          showScrollingText={showScrollingText}
          allowBottomNav={allowBottomNav}>
          <Helmet>
            <title>Tungrp Web</title>

            <meta name="description" content="Tungrp | 網站" />
            <link rel="shortcut icon" href={favicon} />

            <meta property="og:title" content="惇聚國際股份有限公司" />
            <meta property="og:description" content="www.tungrp.com" />
            <meta property="og:image" content={logo} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="1024" />
            <meta property="og:image:height" content="1024" />
          </Helmet>

          <div id="google-map" />
          <ScrollingTextsBar isShown={showScrollingText} />

          <Navbar
            showScrollingText={showScrollingText}
            showNavbar={showNavbar}
          />

          <Content>
            {this.props.children}
            <Footer
              breakpoints={app.breakpoints}
              showFooter={showFooter}
              pathname={pathname}
            />
          </Content>

          {/*<EnvelopeMask />*/}
          <Toast />
          <Modal />
          <SlideDown />
          {allowBottomNav && <BottomNavBar />}

          {!(this.props.rwd.rwd === 1) &&
            !(this.props.rwd.rwd === 0) &&
            !isApp && (
              <Fragment>
                {/*FIXME: @guychienll future feature comment first*/}
                {/*<Widget*/}
                {/*  style={{backgroundColor: Color.mainDark}}*/}
                {/*  icon={<Message size={30} color={Color.mainWhite} />}*/}
                {/*  onClick={() => {}}*/}
                {/*  visible={true}*/}
                {/*/>*/}
                <Widget
                  style={{bottom: 88}}
                  icon={<DropUp size={30} />}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }}
                  visible={this.state.showBackToTop}
                />
              </Fragment>
            )}
        </Wrapper>
      </Delighters>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === '//') {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== '/') {
      path += '/';
    }

    return path;
  };

  _onScroll = e => {
    if (window.scrollY > 0) {
      this.setState({showBackToTop: true});
    } else {
      this.setState({showBackToTop: false});
    }

    let navType = 'solid';
    let navNode = document.querySelector('[data-nav-anchor-id="nav"]');
    if (!navNode) {
      return;
    }
    let navTop = navNode.getBoundingClientRect().top;
    let nodes = document.querySelectorAll('[data-nav-type]'); // returns an iterator
    // convert iterator to array
    nodes = Array.from(nodes, n => ({
      position: n.getBoundingClientRect().top,
      type: n.dataset.navType,
    }));
    let breakpointsWithAnchor = [
      ...nodes,
      {position: navTop, type: 'anchor'},
    ].sort((a, b) => a.position - b.position);
    let anchorIdx = breakpointsWithAnchor.findIndex(b => b.type === 'anchor');
    navType =
      anchorIdx > 0 ? breakpointsWithAnchor[anchorIdx - 1].type : navType;

    if (this.props.app.navType !== navType) {
      Actions.setNavType(navType);
    }
  };
}

const Wrapper = styled.div`
  --navbar-height: 80px;
  --bottom-navbar-height: 50px;
  --scroll-bar-height: ${props => (props.showScrollingText ? '32px' : '0')};

  overflow-x: hidden;

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    --navbar-height: 52px; /* --navbar-height comes from Layout comp: this will effect all children's --navbar-height */
    padding-bottom: ${props =>
      props.allowBottomNav ? 'var(--bottom-navbar-height)' : '0'};
  }

  padding-top: ${props => {
    let result = '0px';
    if (props.withPaddingTop && props.showScrollingText) {
      result = 'calc(var(--navbar-height) + var(--scroll-bar-height))';
    } else if (props.showScrollingText) {
      result = 'var(--scroll-bar-height)';
    } else if (props.withPaddingTop) {
      result = 'var(--navbar-height)';
    } else {
      result = '0px';
    }

    return result;
  }};
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export default Layout;
