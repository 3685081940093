import {useDimension} from '../Hooks/AppHooks';

function useCalcProductGridLayout(layoutWidth = null) {
  const {mobile, tablet, dimension} = useDimension();
  const _layoutWidth = layoutWidth ? layoutWidth : dimension.innerWidth;

  const config = {
    desktop: {
      gutter: 20,
      margin: 40,
    },
    mobile: {
      gutter: 20,
      margin: 20,
    },
  }[mobile || tablet ? 'mobile' : 'desktop'];

  const itemPerRow = mobile || tablet ? 2 : 4;
  const extraSpace = 2 * config.margin + (itemPerRow - 1) * config.gutter;
  const itemWidth =
    _layoutWidth >= 1440
      ? (1440 - extraSpace) / itemPerRow
      : (_layoutWidth - extraSpace) / itemPerRow;

  return {
    itemPerRow,
    itemWidth,
    gutter: config.gutter,
  };
}

export default useCalcProductGridLayout;
