// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-js": () => import("../src/Templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-home-page-js": () => import("../src/Templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-login-page-js": () => import("../src/Templates/LoginPage.js" /* webpackChunkName: "component---src-templates-login-page-js" */),
  "component---src-templates-stores-page-js": () => import("../src/Templates/StoresPage.js" /* webpackChunkName: "component---src-templates-stores-page-js" */),
  "component---src-templates-product-list-page-js": () => import("../src/Templates/ProductListPage.js" /* webpackChunkName: "component---src-templates-product-list-page-js" */),
  "component---src-templates-series-page-js": () => import("../src/Templates/SeriesPage.js" /* webpackChunkName: "component---src-templates-series-page-js" */),
  "component---src-templates-search-page-js": () => import("../src/Templates/SearchPage.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-product-page-js": () => import("../src/Templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-app-product-reserve-page-js": () => import("../src/Templates/AppProductReservePage.js" /* webpackChunkName: "component---src-templates-app-product-reserve-page-js" */),
  "component---src-templates-profile-page-index-js": () => import("../src/Templates/ProfilePage/index.js" /* webpackChunkName: "component---src-templates-profile-page-index-js" */),
  "component---src-templates-checkout-cart-page-js": () => import("../src/Templates/Checkout/CartPage.js" /* webpackChunkName: "component---src-templates-checkout-cart-page-js" */),
  "component---src-templates-checkout-checkout-page-js": () => import("../src/Templates/Checkout/CheckoutPage.js" /* webpackChunkName: "component---src-templates-checkout-checkout-page-js" */),
  "component---src-templates-news-list-page-js": () => import("../src/Templates/News/ListPage.js" /* webpackChunkName: "component---src-templates-news-list-page-js" */),
  "component---src-templates-event-page-js": () => import("../src/Templates/EventPage.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-news-detail-page-js": () => import("../src/Templates/News/DetailPage.js" /* webpackChunkName: "component---src-templates-news-detail-page-js" */),
  "component---src-templates-checkout-select-cvst-store-return-page-js": () => import("../src/Templates/Checkout/SelectCvstStoreReturnPage.js" /* webpackChunkName: "component---src-templates-checkout-select-cvst-store-return-page-js" */),
  "component---src-templates-help-common-questions-page-js": () => import("../src/Templates/Help/CommonQuestionsPage.js" /* webpackChunkName: "component---src-templates-help-common-questions-page-js" */),
  "component---src-templates-help-contact-page-js": () => import("../src/Templates/Help/ContactPage.js" /* webpackChunkName: "component---src-templates-help-contact-page-js" */),
  "component---src-templates-help-maintain-service-intro-page-js": () => import("../src/Templates/Help/MaintainServiceIntroPage.js" /* webpackChunkName: "component---src-templates-help-maintain-service-intro-page-js" */),
  "component---src-templates-help-member-service-page-js": () => import("../src/Templates/Help/MemberServicePage.js" /* webpackChunkName: "component---src-templates-help-member-service-page-js" */),
  "component---src-templates-help-privacy-policy-page-js": () => import("../src/Templates/Help/PrivacyPolicyPage.js" /* webpackChunkName: "component---src-templates-help-privacy-policy-page-js" */),
  "component---src-templates-help-terms-of-online-transaction-page-js": () => import("../src/Templates/Help/TermsOfOnlineTransactionPage.js" /* webpackChunkName: "component---src-templates-help-terms-of-online-transaction-page-js" */),
  "component---src-templates-help-cookies-policy-page-js": () => import("../src/Templates/Help/CookiesPolicyPage.js" /* webpackChunkName: "component---src-templates-help-cookies-policy-page-js" */),
  "component---src-templates-help-site-map-page-js": () => import("../src/Templates/Help/SiteMapPage.js" /* webpackChunkName: "component---src-templates-help-site-map-page-js" */),
  "component---src-templates-about-about-the-brand-page-js": () => import("../src/Templates/About/AboutTheBrandPage.js" /* webpackChunkName: "component---src-templates-about-about-the-brand-page-js" */),
  "component---src-templates-about-about-the-tun-page-js": () => import("../src/Templates/About/AboutTheTunPage.js" /* webpackChunkName: "component---src-templates-about-about-the-tun-page-js" */),
  "component---src-templates-about-about-the-service-page-js": () => import("../src/Templates/About/AboutTheServicePage.js" /* webpackChunkName: "component---src-templates-about-about-the-service-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

