const {
  RETURN_INVOICE_STATUS,
  APPROVE_STATUS,
  RETURN_STATUS,
} = require('./Domain/Return');

const {ORDER_STATUS, SUBORDER_STATUS} = require('../src/Domain/Order');

const brands = {
  kenzo: 'kenzo',
  selfPortrait: 'self-portrait',
  agete: 'agete',
  isabelMarant: 'isabel-marant',
  lesNereides: 'les-nereides',
};

const categories = {
  [brands.kenzo]: [
    {
      id: '0',
      title: 'Women',
      collections: [
        {
          id: 5,
          title: 'WOMEN',
          sub_collections: [
            {
              id: '1-1',
              title: 'women-1',
            },
            {
              id: '1-2',
              title: 'women-2',
            },
          ],
        },
        {
          id: 6,
          title: 'MEN',
          sub_collections: [
            {
              id: '2-1',
              title: 'men-1',
            },
            {
              id: '2-2',
              title: 'men-2',
            },
            {
              id: '2-3',
              title: 'men-3',
            },
            {
              id: '2-4',
              title: 'men-4',
            },
            {
              id: '2-5',
              title: 'men-5',
            },
          ],
        },
        {
          id: 7,
          title: 'SHOES',
          sub_collections: [
            {
              id: '3-1',
              title: 'shoes-1',
            },
            {
              id: '3-2',
              title: 'shoes-2',
            },
          ],
        },
        {
          id: 8,
          title: 'BAGS',
          sub_collections: [
            {
              id: '4-1',
              title: 'bags-1',
            },
            {
              id: '4-2',
              title: 'bags-2',
            },
          ],
        },
        {
          id: 9,
          title: 'ACCESSORIES',
          sub_collections: [
            {
              id: '5-1',
              title: 'accessories-1',
            },
          ],
        },
      ],
    },
    {
      id: '1',
      title: 'Men',
      collections: [
        {
          id: 10,
          title: 'BAGS',
          sub_collections: [
            {
              id: '4-1',
              title: 'bags-1',
            },
            {
              id: '4-2',
              title: 'bags-2',
            },
          ],
        },
        {
          id: 11,
          title: 'ACCESSORIES',
          sub_collections: [
            {
              id: '5-1',
              title: 'accessories-1',
            },
          ],
        },
      ],
    },
  ],
  [brands.selfPortrait]: [],
  [brands.agete]: [],
  [brands.isabelMarant]: [],
  [brands.lesNereides]: [],
};

const homePage = {
  [brands.kenzo]: {
    promotion: {
      image: {
        original:
          'https://elt.rti.org.tw/wp-content/uploads/2019/10/960x540_learning-english-media-english-the-future-of-britain39s-high-streets-imagesgetty.jpg',
        mobile:
          'https://elt.rti.org.tw/wp-content/uploads/2019/10/960x540_learning-english-media-english-the-future-of-britain39s-high-streets-imagesgetty.jpg',
      },
      link_to: {
        value: 1,
        type: 'event_page',
      },
    },
    banners: [
      {
        title: 'NEW ARRIVAL',
        image: 'https://placeimg.com/1000/480/people',
        image_tablet: 'https://placeimg.com/1000/480/people',
        buttons: [
          {content: '全部造型', link_to: {value: '1', type: 'product'}},
          {content: '男裝', link_to: {value: '2', type: 'product'}},
          {content: '女裝', link_to: {value: '3', type: 'product'}},
        ],
      },
      {
        title: 'NEW ARRIVAL 2',
        image: 'https://placeimg.com/1000/480/people',
        image_tablet: 'https://placeimg.com/1000/480/people',
        buttons: [
          {content: '全部造型', link_to: {value: '1', type: 'product'}},
          {content: '男裝', link_to: {value: '2', type: 'product'}},
          {content: '女裝', link_to: {value: '3', type: 'product'}},
        ],
      },
    ],
    shop_items: [
      {
        id: 9,
        images: [
          'https://placeimg.com/1000/480/people',
          'https://placeimg.com/1000/480/people',
          'https://placeimg.com/1000/480/people',
        ],
        buttons: [
          {
            content: '前往探索',
            link_to: {
              value: 9,
              type: 'product',
            },
          },
        ],
      },
      {
        id: 15,
        images: [
          'https://placeimg.com/1000/480/people',
          'https://placeimg.com/1000/480/people',
          'https://placeimg.com/1000/480/people',
        ],
        buttons: [
          {
            content: '前往探索',
            link_to: {
              value: 15,
              type: 'product',
            },
          },
        ],
      },
    ],
    categories: categories[brands.kenzo],
    collection: {
      image: 'https://placeimg.com/1000/480/people',
      image_m: 'https://placeimg.com/1000/480/people',
      image_s: 'https://placeimg.com/1000/480/people',
    },
    sections: [
      {
        title: 'ACCESSORIES',
        image: 'https://placeimg.com/640/480/arch/sepia',
        image_m: 'https://placeimg.com/640/480/arch/sepia',
        image_s: 'https://placeimg.com/640/480/arch/sepia',
        buttons: [
          {content: '全部造型', link_to: {value: '1', type: 'collection'}},
          {content: '男裝', link_to: {value: '2', type: 'collection'}},
          {content: '女裝', link_to: {value: '3', type: 'collection'}},
        ],
      },
      {
        title: 'ESSENTIALS',
        image: 'https://placeimg.com/640/480/nature/grayscale',
        image_m: 'https://placeimg.com/640/480/nature/grayscale',
        image_s: 'https://placeimg.com/640/480/nature/grayscale',
        buttons: [{content: '全部造型', url: ''}],
      },
    ],
    news: [
      {
        id: 1,
        banner:
          'https://elt.rti.org.tw/wp-content/uploads/2019/10/960x540_learning-english-media-english-the-future-of-britain39s-high-streets-imagesgetty.jpg',
        title: 'Fall/Winter 2020 Show',
        description:
          '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
        date: '2020-10-22T05:10:33.275Z',
        buttons: [],
      },
      {
        id: 2,
        banner: 'https://placeimg.com/640/480/arch/grayscale',
        title: 'Fall/Winter 2019 Show',
        description:
          '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
        date: '2020-07-26T05:10:33.275Z',
        buttons: [],
      },
      {
        id: 3,
        banner:
          'https://elt.rti.org.tw/wp-content/uploads/2019/10/960x540_learning-english-media-english-the-future-of-britain39s-high-streets-imagesgetty.jpg',
        title: 'Fall/Winter 2018 Show',
        description:
          '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
        date: '2020-05-10T05:10:33.275Z',
        buttons: [],
      },
    ],
    about: {
      banners: [
        {
          title: 'ABOUT BRAND',
          image: 'https://placeimg.com/640/480/arch/grayscale',
          image_tablet: 'https://placeimg.com/640/480/arch/grayscale',
          buttons: [
            {
              content: '關於品牌',
              link_to: {
                type: 'url',
                value: 'https://www.facebook.com/TUNGRP/',
              },
            },
          ],
        },
        {
          title: 'ABOUT TUNGRP',
          image: 'https://placeimg.com/640/480/arch/grayscale',
          image_tablet: 'https://placeimg.com/640/480/arch/grayscale',
          buttons: [
            {
              content: '關於敦聚',
              link_to: {
                type: 'url',
                value: 'https://www.facebook.com/TUNGRP/',
              },
            },
          ],
        },
        {
          title: 'SERVICE',
          image: 'https://placeimg.com/640/480/arch/grayscale',
          image_tablet: 'https://placeimg.com/640/480/arch/grayscale',
          buttons: [
            {
              content: '服務',
              link_to: {
                type: 'url',
                value: 'https://www.facebook.com/TUNGRP/',
              },
            },
          ],
        },
      ],
    },
  },

  [brands.selfPortrait]: {
    promotion: {
      image: '/images/demo-promotion.png',
      image_m: '/images/demo-promotion_m.png',
      image_s: '/images/demo-promotion_m.png',
      url: '',
    },
    banners: [
      {
        title: 'SELF PORTRAIT NEW ARRIVAL',
        image: 'https://placeimg.com/1000/480/people',
        image_tablet: 'https://placeimg.com/1000/480/people',
        buttons: [
          {content: '全部造型', url: ''},
          {content: '男裝', url: ''},
          {content: '女裝', url: ''},
        ],
      },
      {
        title: 'NEW ARRIVAL 2',
        image: 'https://placeimg.com/1000/480/people',
        image_tablet: 'https://placeimg.com/1000/480/people',
        buttons: [
          {content: '全部造型', url: ''},
          {content: '男裝', url: ''},
          {content: '女裝', url: ''},
        ],
      },
    ],
    shop_items: [
      {
        id: 1,
        title: '不會用到',
        images: [
          {
            image: 'https://placeimg.com/640/480/animals',
          },
          {
            image: 'https://placeimg.com/640/480/nature',
          },
          {
            image: 'https://placeimg.com/640/480/animals',
          },
        ],
      },
      {
        id: 2,
        title: '不會用到',
        images: [
          {
            image: 'https://placeimg.com/640/480/animals',
          },
          {
            image: 'https://placeimg.com/640/480/nature',
          },
          {
            image: 'https://placeimg.com/640/480/animals',
          },
        ],
      },
      {
        id: 3,
        title: '不會用到',
        images: [
          {
            image: 'https://placeimg.com/640/480/animals',
          },
          {
            image: 'https://placeimg.com/640/480/nature',
          },
          {
            image: 'https://placeimg.com/640/480/animals',
          },
        ],
      },
    ], // max: 10
    categories: categories[brands.kenzo],
    collection: {
      image: 'https://placeimg.com/1000/480/people',
      image_m: 'https://placeimg.com/1000/480/people',
      image_s: 'https://placeimg.com/1000/480/people',
    },
    sections: [
      {
        title: 'ACCESSORIES',
        image: 'https://placeimg.com/640/480/arch/sepia',
        image_m: 'https://placeimg.com/640/480/arch/sepia',
        image_s: 'https://placeimg.com/640/480/arch/sepia',
        buttons: [
          {content: '全部造型', url: ''},
          {content: '男裝', url: ''},
          {content: '女裝', url: ''},
        ],
      },
      {
        title: 'ESSENTIALS',
        image: 'https://placeimg.com/640/480/nature/grayscale',
        image_m: 'https://placeimg.com/640/480/nature/grayscale',
        image_s: 'https://placeimg.com/640/480/nature/grayscale',
        buttons: [{content: '全部造型', url: ''}],
      },
    ],
    news: [
      {
        banner:
          'https://elt.rti.org.tw/wp-content/uploads/2019/10/960x540_learning-english-media-english-the-future-of-britain39s-high-streets-imagesgetty.jpg',
        title: 'Fall/Winter 2020 Show',
        description:
          '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
        date: '2020-10-22T05:10:33.275Z',
      },
      {
        banner: 'https://placeimg.com/640/480/arch/grayscale',
        title: 'Fall/Winter 2019 Show',
        description:
          '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
        date: '2020-07-26T05:10:33.275Z',
      },
      {
        banner:
          'https://elt.rti.org.tw/wp-content/uploads/2019/10/960x540_learning-english-media-english-the-future-of-britain39s-high-streets-imagesgetty.jpg',
        title: 'Fall/Winter 2018 Show',
        description:
          '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
        date: '2020-05-10T05:10:33.275Z',
      },
    ],
    about: {
      banners: [
        {
          title: 'ABOUT BRAND',
          image: 'https://placeimg.com/640/480/arch/grayscale',
          image_tablet: 'https://placeimg.com/640/480/arch/grayscale',
          buttons: [
            {
              content: '關於品牌',
              link_to: {
                type: 'url',
                value: 'https://www.facebook.com/TUNGRP/',
              },
            },
          ],
        },
        {
          title: 'ABOUT TUNGRP',
          image: 'https://placeimg.com/640/480/arch/grayscale',
          image_tablet: 'https://placeimg.com/640/480/arch/grayscale',
          buttons: [
            {
              content: '關於敦聚',
              link_to: {
                type: 'url',
                value: 'https://www.facebook.com/TUNGRP/',
              },
            },
          ],
        },
        {
          title: 'SERVICE',
          image: 'https://placeimg.com/640/480/arch/grayscale',
          image_tablet: 'https://placeimg.com/640/480/arch/grayscale',
          buttons: [
            {
              content: '服務',
              link_to: {
                type: 'url',
                value: 'https://www.facebook.com/TUNGRP/',
              },
            },
          ],
        },
      ],
    },
  },
};

const stores = {
  [brands.kenzo]: [
    {
      id: 1,
      name: {
        zh: '微風信義店',
        en: 'name EN',
      },
      address: {
        zh: '台北市信義區忠孝東路五段68號2樓',
        en: 'English address',
      },
      tel: '+886 12345678',
      lat: 25.050792,
      lng: 121.515799,
      liveChatUrl: '',
      district: 'north', // "north" | "center" | "south",
      openTime: [
        ['11:00', '22:00'],
        ['11:00', '22:00'],
      ],
    },
    {
      id: 2,
      name: {
        zh: '微風復興店',
        en: 'Name en',
      },
      lat: 25.050792,
      lng: 121.515799,
      address: {
        zh: '台北市松山區復興南路一段39號G樓',
        en: 'English address',
      },
      tel: '0212345678',
      liveChatUrl: '',
      district: 'south',
      openTime: [
        ['11:00', '22:00'],
        ['11:00', '22:00'],
      ],
    },
    {
      id: 3,
      name: {
        zh: '敦化 SOGO 店',
        en: 'Name en',
      },
      lat: 25.123551,
      lng: 121.535369,
      address: {
        zh: '台北市大安區敦化南路一段246號',
        en: 'English address',
      },
      tel: '0212345678',
      liveChatUrl: '',
      district: 'north',
      openTime: [
        ['11:00', '22:00'],
        ['11:00', '22:00'],
      ],
    },
    {
      id: 4,
      name: {
        zh: '忠孝 SOGO 店',
        en: 'Name en',
      },
      lat: 24.165884,
      lng: 120.643644,
      address: {
        zh: '台北市大安區忠孝東路四段45號7樓',
        en: 'English address',
      },
      tel: '0212345678',
      liveChatUrl: '',
      district: 'center',
      openTime: [
        ['11:00', '22:00'],
        ['11:00', '22:00'],
      ],
    },
    {
      id: 5,
      name: {
        zh: '台北 101 店',
        en: 'Name en',
      },
      lat: 25.042705,
      lng: 121.567298,
      address: {
        zh: '台北市信義區市府路45號2樓',
        en: 'English address',
      },
      tel: '0212345678',
      liveChatUrl: '',
      district: 'center',
      openTime: [
        ['11:00', '22:00'],
        ['11:00', '22:00'],
      ],
    },
    {
      id: 6,
      name: {
        zh: '台中店',
        en: 'Name en',
      },
      lat: 25.050792,
      lng: 121.515799,
      address: {
        zh: '台北市信義區市府路45號2樓',
        en: 'English address',
      },
      tel: '0212345678',
      liveChatUrl: '',
      district: 'center',
      openTime: [
        ['11:00', '22:00'],
        ['11:00', '22:00'],
      ],
    },
    {
      id: 7,
      name: {
        zh: '台南店',
        en: 'Name en',
      },
      lat: 25.123551,
      lng: 121.535369,
      address: {
        zh: '台北市信義區市府路45號2樓',
        en: 'English address',
      },
      tel: '0212345678',
      liveChatUrl: '',
      district: 'south',
      openTime: [
        ['11:00', '22:00'],
        ['11:00', '22:00'],
      ],
    },
  ],
  [brands.selfPortrait]: [],
  [brands.agete]: [],
  [brands.isabelMarant]: [],
  [brands.lesNereides]: [],
};

const productList = [
  // in detail page
  {
    brand_id: 1,
    title: {
      zh: 'Kenzo 領帶',
      en: 'Kenzo Tie',
    },
    images: [
      {image: 'https://placeimg.com/640/1000/people'},
      {image: 'https://placeimg.com/640/1000/people'},
      {image: 'https://placeimg.com/640/1000/people'},
      {image: 'https://placeimg.com/640/1000/people'},
    ],
    id: 13,
    collection: {
      id: 6,
      title: {
        zh: '領帶',
        en: 'Ties',
      },
    },
    sub_collection: null,
    info_detail: {
      zh:
        '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
      en:
        "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
    },
    size_img: {
      zh: 'KEN_02zh.jpg',
      en: 'KEN_02.jpg',
    },
    promotion: null,
    member_price: 5800,
    promote_price: 5800,
    price: 5800,
    series: {
      zh: 'KENZO Sport',
      en: 'KENZO Sport',
    },
    recommend_products: [
      {
        id: '12344',
        title: {
          zh: 'Kenzo 針織軟帽',
          en: 'Kenzo Knit Cap',
        },
        price: 5800,
        promote_price: 5800,
        member_price: 5800,
        image: 'https://placeimg.com/640/1000/people',
      },
      {
        id: '12344',
        title: {
          zh: 'Kenzo 針織軟帽',
          en: 'Kenzo Knit Cap',
        },
        price: 5800,
        promote_price: 5800,
        member_price: 5800,
        image: 'https://placeimg.com/640/1000/people',
      },
      {
        id: '12344',
        title: {
          zh: 'Kenzo 針織軟帽',
          en: 'Kenzo Knit Cap',
        },
        price: 5800,
        promote_price: 5800,
        member_price: 5800,
        image: 'https://placeimg.com/640/1000/people',
      },
      {
        id: '12344',
        title: {
          zh: 'Kenzo 針織軟帽',
          en: 'Kenzo Knit Cap',
        },
        price: 5800,
        promote_price: 5800,
        member_price: 5800,
        image: 'https://placeimg.com/640/1000/people',
      },
    ],
    style_with_products: [
      {
        id: '12344',
        title: {
          zh: 'Kenzo 針織軟帽',
          en: 'Kenzo Knit Cap',
        },
        price: 5800,
        promote_price: 5800,
        member_price: 5800,
        image: 'https://placeimg.com/640/1000/people',
      },
      {
        id: '12344',
        title: {
          zh: 'Kenzo 針織軟帽',
          en: 'Kenzo Knit Cap',
        },
        price: 5800,
        promote_price: 5800,
        member_price: 5800,
        image: 'https://placeimg.com/640/1000/people',
      },
      {
        id: '12344',
        title: {
          zh: 'Kenzo 針織軟帽',
          en: 'Kenzo Knit Cap',
        },
        price: 5800,
        promote_price: 5800,
        member_price: 5800,
        image: 'https://placeimg.com/640/1000/people',
      },
      {
        id: '12344',
        title: {
          zh: 'Kenzo 針織軟帽',
          en: 'Kenzo Knit Cap',
        },
        price: 5800,
        promote_price: 5800,
        member_price: 5800,
        image: 'https://placeimg.com/640/1000/people',
      },
    ],
    variants: [
      {
        id: 'blue-1',
        stock: 100,
        price: 5800,
        member_price: 5800,
        promote_price: 5800,
        color: '#4F63BF',
        colorDisplay: {
          zh: '米色',
          en: 'Beige',
        },
        material: {
          zh: null,
          en: null,
        },
        material_2: {
          zh: null,
          en: null,
        },
        color_img: null,
        size: {
          zh: '35.3',
          en: '35.3',
        },
        images: [
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/135/01.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/136/02.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/134/03.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/137/Icon_',
        ],
      },
      {
        id: 'green-1',
        stock: 0,
        price: 5800,
        member_price: 5800,
        promote_price: 5800,
        color: 'green',
        colorDisplay: {
          zh: '綠色',
          en: 'green',
        },
        material: {
          zh: null,
          en: null,
        },
        material_2: {
          zh: null,
          en: null,
        },
        color_img: null,
        size: {
          zh: '35.3',
          en: '35.3',
        },
        images: [
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/135/01.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/136/02.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/134/03.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/137/Icon_',
        ],
      },
      {
        id: 'red-1',
        stock: 3,
        price: 5800,
        member_price: 5800,
        promote_price: 5800,
        color: 'red',
        colorDisplay: {
          zh: '紅色',
          en: 'red',
        },
        material: {
          zh: null,
          en: null,
        },
        material_2: {
          zh: null,
          en: null,
        },
        color_img: null,
        size: {
          zh: '32.3',
          en: '32.3',
        },
        images: [
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/135/01.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/136/02.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/134/03.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/137/Icon_',
        ],
      },
    ],
  },
];

const productInCollection = {
  [brands.kenzo]: {
    title: 'KENZO Sport',
    description: '具有強烈的圖形設計，採用技術材料將優雅與舒適結合在一起',
    banner: {
      original: '',
      mobile: '',
    },
    products: [],
    collections: [
      {
        copywriting_title: 'ISABEL MARANT WEARING',
        copywriting_description:
          'KENZO Sport offers a wardrobe with a strong graphic design, where technical materials are worked to combine elegance and comfort. It is created as a distinct style, with functional and unisex accents, inspired by movement and protection. The KENZO logo, transformed into an X, reveals its unique identity.',
        scenario_img: {
          vertical: '',
          horizontal: '',
        },
        products: [
          {
            id: '1',
            title: 'T-shirts-1',
            img: '',
            price: 2300,
            options: [
              {display: 'S', value: 'S'},
              {display: 'M', value: 'M'},
              {display: 'L', value: 'L'},
            ],
          },
          {
            id: '2',
            title: 'T-shirts-2',
            img: {
              original: '',
              mobile: '',
            },
            price: 2300,
            options: [
              {display: 'S', value: 'S'},
              {display: 'M', value: 'M'},
              {display: 'L', value: 'L'},
            ],
          },
        ],
      },
    ],
  },
};

const searchData = {
  brands: [
    {
      title: 'KENZO',
      collections: [
        {
          id: 3,
          title: {
            zh: '包包',
            en: 'Bags',
          },
          collection_products_count: 2,
          category: {
            id: 4,
            title: {
              zh: '女款',
              en: 'Woman',
            },
          },
        },
        {
          id: 4,
          title: {
            zh: '包包',
            en: 'Bags',
          },
          collection_products_count: 4,
          category: {
            id: 5,
            title: {
              zh: '男款',
              en: 'Man',
            },
          },
        },
      ],
      brand_products_count: 6,
    },
    {
      title: 'agete',
      collections: [
        {
          id: 5,
          title: {
            zh: '包包',
            en: 'Bags',
          },
          collection_products_count: 2,
          category: {
            id: 6,
            title: {
              zh: '女款',
              en: 'Woman',
            },
          },
        },
        {
          id: 6,
          title: {
            zh: '包包',
            en: 'Bags',
          },
          collection_products_count: 4,
          category: {
            id: 7,
            title: {
              zh: '男款',
              en: 'Man',
            },
          },
        },
      ],
      brand_products_count: 6,
    },
  ],
  all_brands_products_count: 6,
};

const me = {
  phone: '0987654322',
  email: 'test2@gmail.com',
  name: 'test2',
  vip_level: 'silver',
  birth_gift_points: [
    {
      expiration_date: '2021-02-28',
      point: 1500,
    },
  ],
  rebate_points: [
    {
      expiration_date: '2021-02-27',
      point: 1500,
    },
    {
      expiration_date: '2021-04-30',
      point: 3000,
    },
    {
      expiration_date: '2021-07-31',
      point: 1000,
    },
  ],
};

const series = {
  brand: {
    image: 'https://dummyimage.com/1024/333/fff&text=Collection',
    title: 'kenzo',
    description: `kenzo offers a wardrobe with a strong graphic design, where
          technical materials are worked to combine elegance and comfort. It
          is created as a distinct style, with functional and unisex accents,
          inspired by movement and protection. The KENZO logo, transformed
          into an X, reveals its unique identity.`,
  },
  filterOptions: [
    {filter_size: ['S', 'M', 'L']},
    {filter_color: ['red', 'blue', 'green', 'white']},
  ],
  sortOptions: ['name', 'price', 'hot'],
  collections: [
    {
      title: 'First Cut',
      description: 'First Cut Description',
      image: 'https://dummyimage.com/512/000/fff&text=C1',
      products: Array.from({length: 12}).map((_, idx) => ({
        id: idx,
        name: `Cut1 Product${idx + 1}`,
        image: `https://dummyimage.com/512/aaa/fff&text=${idx + 1}`,
        price: (idx + 1) * 100,
        variants: [
          {
            id: 'blue-1',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'S',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'blue-2',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'green-1',
            color: 'green',
            colorDisplay: {
              zh: '綠色',
              en: 'green',
            },
            size: 'S',
            stock: 0,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-1',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'S',
            stock: 100,
            promote_price: 40,
            price: 1000,
          },
          {
            id: 'red-2',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-3',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'L',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-4',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'XL',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
        ],
        color: 'blue',
      })),
    },
    {
      title: 'Second Cut',
      description: 'Second Cut Description',
      image: 'https://dummyimage.com/512/000/fff&text=C2',
      products: Array.from({length: 12}).map((_, idx) => ({
        id: idx,
        name: `Cut2 Product${idx + 1}`,
        image: `https://dummyimage.com/512/aaa/fff&text=${idx + 1}`,
        price: (idx + 1) * 100,
        variants: [
          {
            id: 'blue-1',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'S',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'blue-2',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'green-1',
            color: 'green',
            colorDisplay: {
              zh: '綠色',
              en: 'green',
            },
            size: 'S',
            stock: 0,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-1',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'S',
            stock: 100,
            promote_price: 40,
            price: 1000,
          },
          {
            id: 'red-2',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-3',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'L',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-4',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'XL',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
        ],
        color: 'blue',
      })),
    },
    {
      title: 'Third Cut',
      description: 'Third Cut Description',
      image: 'https://dummyimage.com/512/000/fff&text=C3',
      products: Array.from({length: 12}).map((_, idx) => ({
        id: idx,
        name: `Cut3 Product${idx + 1}`,
        image: `https://dummyimage.com/512/aaa/fff&text=${idx + 1}`,
        price: (idx + 1) * 100,
        variants: [
          {
            id: 'blue-1',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'S',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'blue-2',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'green-1',
            color: 'green',
            colorDisplay: {
              zh: '綠色',
              en: 'green',
            },
            size: 'S',
            stock: 0,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-1',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'S',
            stock: 100,
            promote_price: 40,
            price: 1000,
          },
          {
            id: 'red-2',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-3',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'L',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-4',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'XL',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
        ],
        color: 'blue',
      })),
    },
    {
      title: 'Fourth Cut',
      description: 'Fourth Cut Description',
      image: 'https://dummyimage.com/512/000/fff&text=C4',
      products: Array.from({length: 12}).map((_, idx) => ({
        id: idx,
        name: `Cut4 Product${idx + 1}`,
        image: `https://dummyimage.com/512/aaa/fff&text=${idx + 1}`,
        price: (idx + 1) * 100,
        variants: [
          {
            id: 'blue-1',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'S',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'blue-2',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'green-1',
            color: 'green',
            colorDisplay: {
              zh: '綠色',
              en: 'green',
            },
            size: 'S',
            stock: 0,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-1',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'S',
            stock: 100,
            promote_price: 40,
            price: 1000,
          },
          {
            id: 'red-2',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-3',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'L',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-4',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'XL',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
        ],
        color: 'blue',
      })),
    },
    {
      title: 'Fifth Cut',
      description: 'Fifth Cut Description',
      image: 'https://dummyimage.com/512/000/fff&text=C5',
      products: Array.from({length: 12}).map((_, idx) => ({
        id: idx,
        name: `Cut5 Product${idx + 1}`,
        image: `https://dummyimage.com/512/aaa/fff&text=${idx + 1}`,
        price: (idx + 1) * 100,
        variants: [
          {
            id: 'blue-1',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'S',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'blue-2',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'green-1',
            color: 'green',
            colorDisplay: {
              zh: '綠色',
              en: 'green',
            },
            size: 'S',
            stock: 0,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-1',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'S',
            stock: 100,
            promote_price: 40,
            price: 1000,
          },
          {
            id: 'red-2',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-3',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'L',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-4',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'XL',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
        ],
        color: 'blue',
      })),
    },
    {
      title: 'Sixth Cut',
      description: 'Sixth Cut Description',
      image: 'https://dummyimage.com/512/000/fff&text=C6',
      products: Array.from({length: 12}).map((_, idx) => ({
        id: idx,
        name: `Cut6 Product${idx + 1}`,
        image: `https://dummyimage.com/512/aaa/fff&text=${idx + 1}`,
        price: (idx + 1) * 100,
        variants: [
          {
            id: 'blue-1',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'S',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'blue-2',
            color: 'blue',
            colorDisplay: {
              zh: '藍色',
              en: 'blue',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'green-1',
            color: 'green',
            colorDisplay: {
              zh: '綠色',
              en: 'green',
            },
            size: 'S',
            stock: 0,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-1',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'S',
            stock: 100,
            promote_price: 40,
            price: 1000,
          },
          {
            id: 'red-2',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'M',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-3',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'L',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
          {
            id: 'red-4',
            color: 'red',
            colorDisplay: {
              zh: 'red',
              en: 'red',
            },
            size: 'XL',
            stock: 100,
            promote_price: 1955,
            price: 2300,
          },
        ],
        color: 'blue',
      })),
    },
  ],
};

const seriesList = [
  {
    image: {
      original:
        'https://storage.googleapis.com/tungrp-test-bucket-public/series/image_original/1/ITFMidN.jpg',
      mobile:
        'https://storage.googleapis.com/tungrp-test-bucket-public/series/image_mobile/1/RCb6Z4v.jpg',
    },
    id: 1,
    title: {
      zh: 'KENZO Sport',
      en: 'KENZO Sport',
    },
    subtitle: {
      zh: '這是一個測試的系列',
      en: 'To Be or Not To Be That Is The Question',
    },
  },
];

const orderDetail = {
  id: 1,
  order_no: 'AB-12345678', // 訂單編號
  created: '2021-01-01T09:56:54.779Z',
  note: '', // 備註
  status: 'processing', // "processing" 處理中 | "pending_for_receiving" 待收貨 | "complete" 已完成 | "canceled" 已取消 | "refund" 退貨/退款 | "pending_payment" 未完成付款
  config: {
    payment: {
      type: 'credit_card', // 信用卡
      sub_type: 'visa',
      last_4_digits: '1234',
      first_6_digits: '123123',
      credit_card_type: 'visa',
      installment: 0, // 分 _ 期付款
      // installment_fee: 0, // 每期 _ 利率
    },
    invoice: {
      type: 'person', // 個人 (二聯式) 電子發票
      subtype: 'ecpay', //  ecpay 會員載具 | mobile 手機條碼載具 | b2b 統編
      invoice_account: '/RT+1234',
      invoice_tax: '12345678',
    },
    shipping: {
      receiver: '王小明',
      receiver_phone: '0912341123',
      pick_up_address: {
        type: 'cvs', // "cvs" 超商取貨 | "home" 宅配
        name: '7-11 士東門市',
        cvs_number: '',
        cvs_store_type: 'UNIMARTC2C',
        city: '台北市',
        district: '信義區',
        address: '台北市士林區士東路 48 號',
      },
      // pick_up_address: {
      //   type: "home",
      //   name: "",
      //   address: "台北市大安區仁愛路 382 號 109 巷 201 弄 2049號 102 戶 4 號仁愛路 382 號 109 巷"
      // }
    },
  },
  calculations: {
    total: 36880,
    subtotal: 38080,
    reward: 762, // 消費回饋
    redeem_credit: 200, // 購物金折抵 -200
    shipping_cost: 0,
    extra_discounts: {
      title: {zh: '全館滿 30,000 折 1,000', en: "'全館滿 30,000 折 1,000'"},
      amount: 1000,
    }, // -1000
    membership_reward_percent: 0.02,
    membership_level: 'G',
  },
  sub_orders: [
    {
      status: SUBORDER_STATUS.returned,
      brand_name: 'kenzo', // align with frontend brand key
      shippingStatus: 'failed',
      return_order_id: 1,
      store: {
        lat: 25.04115,
        lng: 121.542943,
        title: {
          zh: '復興 SOGO',
          en: '復興 SOGO',
        },
        address: {
          zh: '台北市大安區忠孝東路三段300號3樓',
          en: '台北市大安區忠孝東路三段300號3樓',
        },
        phone: '02-8772-6184',
        instant_messaging_token: '272407',
        opening_hours: [
          ['11:00', '21:30'],
          ['11:00', '21:30'],
          ['11:00', '21:30'],
          ['11:00', '21:30'],
          ['11:00', '21:30'],
          ['11:00', '22:00'],
          ['11:00', '22:00'],
        ],
        id: 7,
        area: 'north',
        default: true,
      },
      shippingStatusHistory: {
        preparing: '2021-01-04T08:59:54.779Z',
        shipped: '2021-01-08T09:56:54.779Z',
        delivered: '2021-01-08T09:56:54.779Z',
        received: '2021-01-08T09:56:54.779Z',
        failed: '2021-01-08T09:56:54.779Z',
      },
      logistic: {
        logistic_id: 'SF1043439867809',
      },
      items: [
        // same as cart item
        {
          variant: {
            uid: 'KEN144-F461SA110B04.19.TU',
            id: 7,
            price: 11800,
            member_price: 11800,
            promote_price: 11800,
            color: null,
            colorDisplay: {
              zh: null,
              en: null,
            },
            material: {
              zh: '尼龍 85%, 喀斯米亞羊毛 10%, 純羊毛 5%',
              en: 'Nylon 85%, Cashmere 10%, Lamswool 5%',
            },
            color_img: null,
            size: {
              zh: 'x',
              en: 'x',
            },
            images: [
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/99/01.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/98/02.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/100/03.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/101/Icon_',
            ],
            stock: 2,
            filter_br: null,
            filter_metal: null,
            filter_gem: ' ',
            filter_size: 'x',
            filter_cat: '包包',
            filter_pattern: null,
            filter_color: 'T0',
            filter_theme: null,
            filter_series: null,
            product: {
              brand_id: 1,
              title: {
                zh: 'Kenzo 手拿包',
                en: 'Kenzo Clutch',
              },
              id: 3,
              collection: {
                id: 3,
                title: {
                  zh: '包包',
                  en: 'Bags',
                },
              },
              sub_collection: null,
              info_detail: {
                zh:
                  '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
                en:
                  "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
              },
              size_img: {
                zh: 'KEN_01zh.jpg',
                en: 'KEN_01.jpg',
              },
              series: {
                zh: null,
                en: null,
              },
              promotion: null,
              member_price: 11800,
              promote_price: 11800,
              price: 11800,
              specified_variant_id: null,
              published: true,
            },
          },
          quantity: 2,
        },
        {
          variant: {
            uid: 'KEN144-F461SA903L24.19.TU',
            id: 8,
            price: 11800,
            member_price: 11800,
            promote_price: 11800,
            color: '#FFFFFF',
            colorDisplay: {
              zh: '白',
              en: 'White',
            },
            material: {
              zh: '純羊毛 100%',
              en: 'Lamswool 100%',
            },
            color_img: null,
            size: {
              zh: '35',
              en: '35',
            },
            images: [
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/96/01.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/94/02.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/95/03.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/97/Icon_',
            ],
            stock: 3,
            filter_br: null,
            filter_metal: null,
            filter_gem: null,
            filter_size: '35',
            filter_cat: '包包',
            filter_pattern: null,
            filter_color: '01',
            filter_theme: null,
            filter_series: null,
            product: {
              brand_id: 1,
              title: {
                zh: 'Kenzo 手拿包',
                en: 'Kenzo Clutch',
              },
              id: 3,
              collection: {
                id: 3,
                title: {
                  zh: '包包',
                  en: 'Bags',
                },
              },
              sub_collection: null,
              info_detail: {
                zh:
                  '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
                en:
                  "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
              },
              size_img: {
                zh: 'KEN_01zh.jpg',
                en: 'KEN_01.jpg',
              },
              series: {
                zh: null,
                en: null,
              },
              promotion: null,
              member_price: 11800,
              promote_price: 11800,
              price: 11800,
              specified_variant_id: null,
              published: true,
            },
          },
          quantity: 1,
        },
      ],
    },
    {
      status: SUBORDER_STATUS.returned,
      brand_name: 'agete', // align with frontend brand key
      shippingStatus: 'failed',
      return_order_id: 1,
      store: {
        lat: 25.04115,
        lng: 121.542943,
        title: {
          zh: '復興 SOGO',
          en: '復興 SOGO',
        },
        address: {
          zh: '台北市大安區忠孝東路三段300號3樓',
          en: '台北市大安區忠孝東路三段300號3樓',
        },
        phone: '02-8772-6184',
        instant_messaging_token: '272407',
        opening_hours: [
          ['11:00', '21:30'],
          ['11:00', '21:30'],
          ['11:00', '21:30'],
          ['11:00', '21:30'],
          ['11:00', '21:30'],
          ['11:00', '22:00'],
          ['11:00', '22:00'],
        ],
        id: 7,
        area: 'north',
        default: true,
      },
      shippingStatusHistory: {
        preparing: '2021-01-04T08:59:54.779Z',
        shipped: '2021-01-08T09:56:54.779Z',
        delivered: '2021-01-08T09:56:54.779Z',
        received: '2021-01-08T09:56:54.779Z',
        failed: '2021-01-08T09:56:54.779Z',
      },
      logistic: {
        logistic_id: 'SF1043439867809',
      },
      items: [
        // same as cart item
        {
          variant: {
            uid: 'KEN144-F461SA110B04.19.TU',
            id: 7,
            price: 11800,
            member_price: 11800,
            promote_price: 11800,
            color: null,
            colorDisplay: {
              zh: null,
              en: null,
            },
            material: {
              zh: '尼龍 85%, 喀斯米亞羊毛 10%, 純羊毛 5%',
              en: 'Nylon 85%, Cashmere 10%, Lamswool 5%',
            },
            color_img: null,
            size: {
              zh: 'x',
              en: 'x',
            },
            images: [
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/99/01.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/98/02.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/100/03.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/101/Icon_',
            ],
            stock: 2,
            filter_br: null,
            filter_metal: null,
            filter_gem: ' ',
            filter_size: 'x',
            filter_cat: '包包',
            filter_pattern: null,
            filter_color: 'T0',
            filter_theme: null,
            filter_series: null,
            product: {
              brand_id: 1,
              title: {
                zh: 'Kenzo 手拿包',
                en: 'Kenzo Clutch',
              },
              id: 3,
              collection: {
                id: 3,
                title: {
                  zh: '包包',
                  en: 'Bags',
                },
              },
              sub_collection: null,
              info_detail: {
                zh:
                  '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
                en:
                  "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
              },
              size_img: {
                zh: 'KEN_01zh.jpg',
                en: 'KEN_01.jpg',
              },
              series: {
                zh: null,
                en: null,
              },
              promotion: null,
              member_price: 11800,
              promote_price: 11800,
              price: 11800,
              specified_variant_id: null,
              published: true,
            },
          },
          quantity: 2,
        },
        {
          variant: {
            uid: 'KEN144-F461SA903L24.19.TU',
            id: 8,
            price: 11800,
            member_price: 11800,
            promote_price: 11800,
            color: '#FFFFFF',
            colorDisplay: {
              zh: '白',
              en: 'White',
            },
            material: {
              zh: '純羊毛 100%',
              en: 'Lamswool 100%',
            },
            color_img: null,
            size: {
              zh: '35',
              en: '35',
            },
            images: [
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/96/01.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/94/02.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/95/03.jpg',
              'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/97/Icon_',
            ],
            stock: 3,
            filter_br: null,
            filter_metal: null,
            filter_gem: null,
            filter_size: '35',
            filter_cat: '包包',
            filter_pattern: null,
            filter_color: '01',
            filter_theme: null,
            filter_series: null,
            product: {
              brand_id: 1,
              title: {
                zh: 'Kenzo 手拿包',
                en: 'Kenzo Clutch',
              },
              id: 3,
              collection: {
                id: 3,
                title: {
                  zh: '包包',
                  en: 'Bags',
                },
              },
              sub_collection: null,
              info_detail: {
                zh:
                  '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
                en:
                  "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
              },
              size_img: {
                zh: 'KEN_01zh.jpg',
                en: 'KEN_01.jpg',
              },
              series: {
                zh: null,
                en: null,
              },
              promotion: null,
              member_price: 11800,
              promote_price: 11800,
              price: 11800,
              specified_variant_id: null,
              published: true,
            },
          },
          quantity: 1,
        },
      ],
    },
  ],
};

const orderList = {
  order_status_count: {
    [ORDER_STATUS.canceled]: 0,
    [ORDER_STATUS.done]: 0,
    [ORDER_STATUS.waiting]: 2,
    [ORDER_STATUS.unpaid]: 0,
    [ORDER_STATUS.processing]: 3,
  },
  orders: [orderDetail, orderDetail, orderDetail],
};

const cart = {
  subtotal: 100000,
  price_detail: {
    price: 100000,
    subtotal: 100000,
  },
  items: [
    {
      variant: {
        uid: 'KEN144-F465HA000AWT.12.712',
        id: 26,
        price: 3800,
        member_price: 3800,
        promote_price: 3800,
        color: null,
        colorDisplay: {
          zh: '豹紋',
          en: 'Leopard Print',
        },
        material: {
          zh: '麻 100%',
          en: 'Linen 100%',
        },
        color_img: 'XXX.jpg',
        size: {
          zh: '1號',
          en: 'Num 1',
        },
        images: [
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/83/01.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/84/02.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/82/03.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/85/Icon_',
        ],
        stock: 1,
        filter_br: null,
        filter_metal: null,
        filter_gem: null,
        filter_size: '001',
        filter_cat: '帽子',
        filter_pattern: null,
        filter_color: '03',
        filter_theme: null,
        filter_series: null,
        product: {
          title: {
            zh: 'Kenzo 一般帽',
            en: 'Kenzo Hat',
          },
          id: 16,
          collection: {
            id: 8,
            title: {
              zh: '帽子',
              en: 'Hats',
            },
          },
          info_detail: {
            zh:
              '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
            en:
              "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
          },
          size_img: {
            zh: 'KEN_02zh.jpg',
            en: 'KEN_02.jpg',
          },
          series: {
            zh: null,
            en: null,
          },
          promotion: {
            title: '折扣優惠',
            ratio: 15,
          },
          member_price: 3800,
          promote_price: 3800,
          price: 3800,
          specified_variant_id: null,
          published: false,
        },
      },
      quantity: 1,
    },
    {
      variant: {
        uid: 'KEN144-M46N31100BB0.L3.TU',
        id: 34,
        price: 8800,
        member_price: 8800,
        promote_price: 8800,
        color: null,
        colorDisplay: {
          zh: '豹紋',
          en: 'Leopard Print',
        },
        material: {
          zh: '天絲棉 5%, 蠶絲 95%',
          en: 'Tencel 5%, Silk 95%',
        },
        color_img: 'XXX.jpg',
        size: {
          zh: '1號',
          en: 'Num 1',
        },
        images: [
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/43/01.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/42/02.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/44/03.jpeg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/45/04.jpeg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/46/05.jpeg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/47/06.jpeg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/48/07.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/49/Icon_',
        ],
        stock: 4,
        filter_br: null,
        filter_metal: null,
        filter_gem: null,
        filter_size: '001',
        filter_cat: '圍巾',
        filter_pattern: null,
        filter_color: '03',
        filter_theme: null,
        filter_series: null,
        product: {
          title: {
            zh: 'Kenzo 圍巾',
            en: 'Kenzo Scarf',
          },
          id: 21,
          collection: {
            id: 7,
            title: {
              zh: '圍巾',
              en: 'Scarf',
            },
          },
          info_detail: {
            zh:
              '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
            en:
              "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
          },
          size_img: {
            zh: 'KEN_02zh.jpg',
            en: 'KEN_02.jpg',
          },
          series: {
            zh: null,
            en: null,
          },
          promotion: {
            title: '折扣優惠',
            ratio: 15,
          },
          member_price: 8800,
          promote_price: 8800,
          price: 8800,
          specified_variant_id: null,
          published: true,
        },
      },
      quantity: 1,
    },
  ],
};

const myCreditCards = [
  {
    id: 1,
    first_six: '431195',
    last_four: '2222',
  },
  {
    id: 2,
    first_six: '431195',
    last_four: '2222',
  },
];

const newsList = [
  {
    title: 'Winter 2020 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Winter-20',
    date: '2020/05/10',
  },
  {
    title: 'Fall 2020 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Fall-20',
    date: '2020/05/10',
  },
  {
    title: 'Summer 2020 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Summer-20',
    date: '2020/05/10',
  },
  {
    title: 'Spring 2020 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Spring-20',
    date: '2020/05/10',
  },
  {
    title: 'Winter 2019 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Winter-19',
    date: '2020/05/10',
  },
  {
    title: 'Fall 2019 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Fall-19',
    date: '2020/05/10',
  },
  {
    title: 'Summer 2019 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Summer-19',
    date: '2020/05/10',
  },
  {
    title: 'Spring 2019 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Spring-19',
    date: '2020/05/10',
  },
];

const news = {
  title: 'Fall 2020 Show',
  description:
    '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
  image: 'https://dummyimage.com/512/000/fff&text=Fall-20',
  date: '2020/05/10',
  buttons: [],
  prev: {
    id: 1,
    title: 'Summer 2020 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Summer-20',
    date: '2020/05/10',
  },
  next: {
    id: 3,
    title: 'Winter 2020 Show',
    description:
      '秋冬系列帶我們重回最美好的 70 與 80 時尚年代，特別是在輪廓與剪裁上，可以一眼望穿解構主義與極簡主義的激辯與融合，使得本季的長大衣與外套看起來更俱優雅知性',
    image: 'https://dummyimage.com/512/000/fff&text=Winter-20',
    date: '2020/05/10',
  },
};

const pointHistory = [
  {
    date: '2021/02/14',
    detail: '會員回饋：電商消費累計',
    point: 4000,
  },
  {
    date: '2021/02/14',
    detail: '會員回饋：電商消費累計',
    point: -20000,
  },
  {
    date: '2021/02/14',
    detail: '會員回饋：電商消費累計',
    point: 15000,
  },
  {
    date: '2021/02/14',
    detail: '會員回饋：電商消費累計',
    point: 20000,
  },
  {
    date: '2021/02/14',
    detail: '會員回饋：電商消費累計',
    point: -4000,
  },
];
const promotions = [
  {
    threshold: 3000,
    discount: 100,
    title: {zh: '滿3000折100', en: 'Get 100 discount'},
  },
  {
    threshold: 8000,
    discount: 300,
    title: {zh: '滿8000折300', en: 'Get 300 discount'},
  },
  {
    threshold: 10000,
    discount: 500,
    title: {zh: '滿10000折500', en: 'Get 500 discount '},
  },
  {
    threshold: 120000,
    discount: 1000,
    title: {zh: '滿120000折1000', en: 'Get 1000 discount '},
  },
];

const favStores = [
  {
    id: null,
    brand: 'KENZO',
    storeName: '',
    storeAddr: '',
  },
  {
    id: 2,
    brand: 'self-portrait',
    storeName: '微風信義店',
    storeAddr: '186 台北市松山區南京東路四段186號 7 樓',
  },
  {
    id: 3,
    brand: 'agete',
    storeName: '微風信義店',
    storeAddr: '186 台北市松山區南京東路四段186號 7 樓',
  },
  {
    id: 4,
    brand: 'Isabel Marant',
    storeName: '微風信義店',
    storeAddr: '186 台北市松山區南京東路四段186號 7 樓',
  },
  {
    id: 5,
    brand: 'Les Néréides',
    storeName: '微風信義店',
    storeAddr: '186 台北市松山區南京東路四段186號 7 樓',
  },
];

const addrs = [
  {
    id: 1,
    primary: true,
    receiverName: '王又云',
    receiverPhone: '0912345678',
    receiverAddr: '台北市士林區士東路48號',
    storeName: '',
    storeAddr: '',
    storeType: '',
  },
  {
    id: 2,
    primary: false,
    receiverName: '王珍妮',
    receiverPhone: '0987654321',
    receiverAddr: '台北市士林區士東路48號',
    storeName: '',
    storeAddr: '',
    storeType: '',
  },
  {
    id: 3,
    primary: true,
    receiverName: '王珍妮',
    receiverPhone: '0987654321',
    receiverAddr: '',
    storeName: '7-11 南京門市',
    storeAddr: '186 台北市松山區南京東路四段186號 7 樓',
    storeType: '7-11',
  },
  {
    id: 4,
    primary: false,
    receiverName: '王又云',
    receiverPhone: '0912345678',
    receiverAddr: '',
    storeName: '萊爾富 小巨蛋門市',
    storeAddr: '186 台北市松山區南京東路四段168號 8 樓',
    storeType: 'hilife',
  },
];

const event = {
  image: {
    original: 'https://fakeimg.pl/1080x1920/?text=event_desktop',
    mobile: 'https://fakeimg.pl/1080x1920/?text=event_mobile',
  },
  id: 1,
  title: {
    zh: '滿額折扣的促銷頁面',
    en: 'Page for discount',
  },
  description: {
    zh: '滿10000就折500',
    en: 'Get 500 Discount',
  },
  target_price_discount: {
    id: 1,
    start_date: null,
    end_date: null,
    title_zh: '滿10000折500',
    title_en: 'Get 500 discount ',
    threshold: 10000,
    discount: 500,
    user_id: 6,
    created_at: '2021-01-20T01:28:53.838+08:00',
    updated_at: '2021-02-17T15:15:46.665+08:00',
  },
  promotion: null,
  event_type: 'target_price_discount',
};

const scrollingTexts = [
  {
    title: {zh: '滿10000折500的跑馬燈文字', en: 'GET 500 DISCOUNT '},
    link_to: {value: 1, type: 'event_page'},
  },
  {title: {zh: '大肆促銷中', en: 'GET DISCOUNT '}, link_to: null},
];

const subOrder = {
  receiver_name: 'Guy Chien',
  receiver_phone: '0986555555',
  address: ' 臺北市大安區內湖里內湖路二段265號八樓之2',
  brand_name: 'KENZO',
  return_order_id: 2,
  store_name: {
    zh: '復興 SOGO',
    en: '復興 SOGO',
  },
  order_items: [
    {
      quantity: 1,
      price: 5800,
      variant: {
        uid: 'KEN144-F465CR200AHG.39.TU',
        id: 22,
        price: 5800,
        member_price: 5800,
        promote_price: 5800,
        color: null,
        colorDisplay: {
          zh: '03',
          en: '03',
        },
        material: {
          zh: '精梳棉 100%',
          en: 'Combed cotton 100%',
        },
        material_2: {
          zh: null,
          en: null,
        },
        color_img: 'XXX.jpg',
        size: {
          zh: '戒圍001',
          en: '001',
        },
        images: [
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/78/02.png',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/79/01.jpeg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/80/03.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/81/Icon_',
        ],
        filter_br: null,
        filter_metal: null,
        filter_gem: null,
        filter_size: '001',
        filter_cat: '領帶',
        filter_pattern: null,
        filter_color: '03',
        filter_theme: null,
        filter_series: null,
        product: {
          brand_id: 1,
          title: {
            zh: 'Kenzo 領帶',
            en: 'Kenzo Tie',
          },
          id: 13,
          collection: {
            id: 16,
            title: {
              zh: '領帶',
              en: 'Ties',
            },
          },
          sub_collection: {
            id: 26,
            title: {
              zh: '領帶',
              en: 'Ties',
            },
          },
          info_detail: {
            zh:
              '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
            en:
              "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
          },
          size_img: {
            zh: 'KEN_02zh.jpg',
            en: 'KEN_02.jpg',
          },
          series: {
            zh: null,
            en: null,
          },
          promotion: null,
          member_price: 5800,
          promote_price: 5800,
          price: 5800,
          specified_variant_id: null,
          published: true,
        },
      },
    },
  ],
};

const returnPreCalculateDetail = {
  return_amount: 0,
  return_cash_amount: 1000,
  return_rebate_amount: 2000,
};

const returnOrderDetail = {
  status: RETURN_STATUS.return_failed,
  status_history: {
    applied: '2021-01-20T01:28:53.838+08:00',
    processing: '2021-01-20T01:28:53.838+08:00',
    refund_failed: '2021-01-20T01:28:53.838+08:00',
    return_failed: '2021-01-20T01:28:53.838+08:00',
    done: '2021-01-20T01:28:53.838+08:00',
  },
  receiver_name: 'Guy Chien',
  receiver_phone: '0912121212',
  zip: '',
  city: '',
  district: '',
  address: ' 臺北市中正區內湖里內湖路二段265號八樓之2',
  invoice_status: RETURN_INVOICE_STATUS.allowance_failed,
  invoice_status_history: {
    unissued_at: '2021-01-20T01:28:53.838+08:00',
    issued_at: '2021-01-20T01:28:53.838+08:00',
    allowance_at: '2021-01-20T01:28:53.838+08:00',
    allowance_failed_at: '2021-01-20T01:28:53.838+08:00',
  },
  failed_reason: 'hello world',
  partial_failed: true,
  return_actual_cash_amount: 0,
  return_actual_rebate_amount: 0,
  return_applied_cash_amount: 0,
  return_applied_rebate_amount: 0,
  return_actual_amount: 0,
  return_applied_amount: 0,
  brand_name: 'KENZO',
  store_name: {
    zh: '復興 SOGO',
    en: '復興 SOGO',
  },
  order_items: [
    {
      quantity: 1,
      price: 9800,
      variant: {
        uid: 'KEN144-M46J659M0LL2.L2.TU',
        id: 30,
        price: 9800,
        member_price: 9800,
        promote_price: 9800,
        color: null,
        colorDisplay: {
          zh: '03',
          en: '03',
        },
        material: {
          zh: '精梳棉 100%',
          en: 'Combed cotton 100%',
        },
        material_2: {
          zh: null,
          en: null,
        },
        color_img: 'XXX.jpg',
        size: {
          zh: '戒圍001',
          en: '001',
        },
        images: [
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/24/01.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/25/02.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/23/03.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/26/04.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/27/Icon_',
        ],
        filter_br: null,
        filter_metal: null,
        filter_gem: null,
        filter_size: '001',
        filter_cat: '圍巾',
        filter_pattern: null,
        filter_color: '03',
        filter_theme: null,
        filter_series: null,
        product: {
          brand_id: 1,
          title: {
            zh: 'Kenzo 圍巾',
            en: 'Kenzo Scarf',
          },
          id: 18,
          collection: {
            id: 17,
            title: {
              zh: '圍巾',
              en: 'Scarf',
            },
          },
          sub_collection: {
            id: 27,
            title: {
              zh: '圍巾',
              en: 'Scarf',
            },
          },
          info_detail: {
            zh:
              '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
            en:
              "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
          },
          size_img: {
            zh: 'KEN_02zh.jpg',
            en: 'KEN_02.jpg',
          },
          series: {
            zh: null,
            en: null,
          },
          promotion: null,
          member_price: 9800,
          promote_price: 9800,
          price: 9800,
          specified_variant_id: null,
          published: true,
        },
      },
      approve: APPROVE_STATUS.failure,
      disapprove_reason: 'hello world',
      return_reason: '123',
    },
    {
      quantity: 1,
      price: 8800,
      variant: {
        uid: 'KEN144-M46N31100BB0.L3.TU',
        id: 34,
        price: 8800,
        member_price: 8800,
        promote_price: 8800,
        color: null,
        colorDisplay: {
          zh: '03',
          en: '03',
        },
        material: {
          zh: '天絲 5%, 蠶絲 95%',
          en: 'TENCEL 5%, Silk 95%',
        },
        material_2: {
          zh: null,
          en: null,
        },
        color_img: 'XXX.jpg',
        size: {
          zh: '戒圍001',
          en: '001',
        },
        images: [
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/43/01.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/42/02.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/44/03.jpeg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/45/04.jpeg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/46/05.jpeg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/47/06.jpeg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/48/07.jpg',
          'https://storage.googleapis.com/tungrp-test-bucket-public/variant_photo/photo/49/Icon_',
        ],
        filter_br: null,
        filter_metal: null,
        filter_gem: null,
        filter_size: '001',
        filter_cat: '圍巾',
        filter_pattern: null,
        filter_color: '03',
        filter_theme: null,
        filter_series: null,
        product: {
          brand_id: 1,
          title: {
            zh: 'Kenzo 圍巾',
            en: 'Kenzo Scarf',
          },
          id: 21,
          collection: {
            id: 17,
            title: {
              zh: '圍巾',
              en: 'Scarf',
            },
          },
          sub_collection: {
            id: 27,
            title: {
              zh: '圍巾',
              en: 'Scarf',
            },
          },
          info_detail: {
            zh:
              '強調深刻記憶概念，運用鮮明色彩圖騰設計，給予獨一無二的視覺意涵， 隨意穿搭就很有型，魅力無限的新象徵',
            en:
              "KENZO Sport nylon backpack.\nContrasting 'Big X' print on the front.\n'Big X' print on the back and vertical KENZO print on the strap under the mesh.\nAdjustable mesh-lined straps.\nOne zipped pocket, one card pocket, one exterior zipped pocket and two exterior mesh side pockets.\nZip fastenings with KENZO signature.",
          },
          size_img: {
            zh: 'KEN_02zh.jpg',
            en: 'KEN_02.jpg',
          },
          series: {
            zh: null,
            en: null,
          },
          promotion: null,
          member_price: 8800,
          promote_price: 8800,
          price: 8800,
          specified_variant_id: null,
          published: true,
        },
      },
      approve: 'unreviewed',
      disapprove_reason: null,
      return_reason: '站著穿',
    },
  ],
  upload_images: [],
};

module.exports = {
  categories,
  homePage,
  stores,
  productInCollection,
  productList,
  searchData,
  me,
  series,
  seriesList,
  orderDetail,
  orderList,
  cart,
  myCreditCards,
  newsList,
  news,
  pointHistory,
  promotions,
  favStores,
  addrs,
  event,
  scrollingTexts,
  subOrder,
  returnPreCalculateDetail,
  returnOrderDetail,
};
