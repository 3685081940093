import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import * as SvgIcon from './SvgIcon';
import * as AppContext from '../Contexts/AppContext';
import {Actions, NavActions} from '../Contexts/AppContext';
import MenuPanel from './MenuPanel';
import BrandPanel from './BrandPanel';
import {Color, FlexColCenter} from './Widget';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from './RectButton';
import {brandLogos} from '../Domain/Brand';
import MiniCart from './MiniCart';
import {SearchArea} from './search/SearchArea';

const textColor = '#000';
const revertTextColor = '#fff';

const Navbar = props => {
  const {
    showNavbar,
    showScrollingText,
    app: {breakpoints, activeBrand, navType},
  } = props;
  const app = useContext(AppContext.Context);
  const {cart, showMiniCart} = app;
  const menuPanelRef = useRef();
  const brandPanelRef = useRef();
  const [mounted, setMounted] = useState(false);
  const [hovered, setHovered] = useState(false);
  const floatWindowRefs = useRef({
    miniCart: null,
    profile: null,
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  const passBreakpoint = navType !== 'normal';

  if (!mounted || !showNavbar) {
    return null;
  }

  const activeBrandLogoIcon = brandLogos[activeBrand] || (
    <SvgIcon.LogoTungrp color={'inherit'} w={28} h={38} />
  );

  const buttons = [
    {
      name: '會員等級/權益',
      onClick: () => {
        floatWindowRefs.current.profile.style.display = 'none';
        NavActions.navigate('/profile?active=member_level');
      },
    },
    {
      name: '購物金',
      onClick: () => {
        floatWindowRefs.current.profile.style.display = 'none';
        NavActions.navigate('/profile?active=credits');
      },
    },
    {
      name: '購買記錄',
      onClick: () => {
        floatWindowRefs.current.profile.style.display = 'none';
        NavActions.navigate('/profile?active=orders');
      },
    },
    {
      name: '帳戶設定',
      onClick: () => {
        floatWindowRefs.current.profile.style.display = 'none';
        NavActions.navigate('/profile?active=personal_info');
      },
    },
  ];

  return (
    <div>
      <BrandPanel
        getInstance={inst => {
          brandPanelRef.current = inst;
        }}
      />
      <Style.Wrapper
        breakpoints={breakpoints}
        showBackground={navType === 'solid' || hovered}
        passBreakpoint={passBreakpoint}
        showScrollingText={showScrollingText}
        data-nav-anchor-id="nav"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <Style.Content breakpoints={breakpoints} showMiniCart={showMiniCart}>
          <div className="container">
            <div className="left">
              <div
                className="menu-icon"
                onClick={() =>
                  menuPanelRef.current.open({logoIcon: activeBrandLogoIcon})
                }>
                <SvgIcon.Menu
                  color={
                    !passBreakpoint || hovered || navType === 'solid'
                      ? textColor
                      : revertTextColor
                  }
                  size={40}
                />
              </div>
              <div
                className="brand-logo"
                onClick={() => NavActions.navigate('/home')}>
                <div
                  style={{
                    color:
                      !passBreakpoint || hovered || navType === 'solid'
                        ? textColor
                        : revertTextColor,
                  }}>
                  {activeBrandLogoIcon}
                </div>
              </div>
            </div>
            <div
              className="center"
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (brandPanelRef.current.isOn) {
                  brandPanelRef.current.close();
                } else {
                  brandPanelRef.current.open();
                }
              }}
              onMouseEnter={() => brandPanelRef.current.open()}>
              <SvgIcon.LogoTungrp
                color={
                  !passBreakpoint || hovered || navType === 'solid'
                    ? textColor
                    : revertTextColor
                }
                w={28}
                h={38}
              />
            </div>

            <div className="right">
              <div className="right-item">
                <SvgIcon.Search
                  color={
                    !passBreakpoint || hovered || navType === 'solid'
                      ? textColor
                      : revertTextColor
                  }
                  onClick={() =>
                    Actions.setGlobalSlideDownContent(<SearchArea />)
                  }
                />
              </div>
              <div className="right-item">
                <div className="profile">
                  <SvgIcon.User
                    color={
                      !passBreakpoint || hovered || navType === 'solid'
                        ? textColor
                        : revertTextColor
                    }
                    onMouseEnter={() => {
                      floatWindowRefs.current.profile.style.display = 'flex';
                    }}
                    className="icon"
                  />
                  <div
                    ref={node => {
                      floatWindowRefs.current.profile = node;
                    }}
                    onMouseLeave={() => {
                      floatWindowRefs.current.profile.style.display = 'none';
                    }}
                    className="floatingWindow">
                    {!app.currentUser && (
                      <Fragment>
                        <RectButton
                          style={{margin: '12px', width: '136px'}}
                          size={BUTTON_SIZE.SMALL}
                          text={'登入/註冊'}
                          skin={BUTTON_SKIN.PRIMARY}
                          onClick={() => {
                            NavActions.navigate('/login');
                          }}
                        />
                        <div
                          style={{
                            height: '1px',
                            width: '100%',
                            background: Color.mainDark_30,
                          }}
                        />
                      </Fragment>
                    )}
                    {buttons.map((b, index) => (
                      <RectButton
                        onClick={b.onClick}
                        key={index}
                        text={b.name}
                        size={BUTTON_SIZE.SMALL}
                        skin={BUTTON_SKIN.DEFAULT}
                        style={{margin: '12px', width: '136px', border: 'none'}}
                      />
                    ))}
                    {app.currentUser && (
                      <RectButton
                        text={`登出 ${app.currentUser.name}`}
                        size={BUTTON_SIZE.SMALL}
                        skin={BUTTON_SKIN.DEFAULT}
                        style={{margin: '12px', width: '136px', border: 'none'}}
                        onClick={() => {
                          Actions.logout();
                          window.location.reload();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="right-item"
                onClick={() => {
                  NavActions.navigate('/cart');
                }}>
                <div className="cart">
                  <SvgIcon.Cart
                    color={
                      !passBreakpoint || hovered || navType === 'solid'
                        ? textColor
                        : revertTextColor
                    }
                    onMouseEnter={() => {
                      floatWindowRefs.current.miniCart.style.display = 'flex';
                    }}
                  />
                  <div
                    className="cartItemsLength"
                    style={{
                      color:
                        !passBreakpoint || hovered || navType === 'solid'
                          ? textColor
                          : revertTextColor,
                    }}>
                    {cart?.items?.length}
                  </div>
                  <div
                    ref={node => {
                      floatWindowRefs.current.miniCart = node;
                    }}
                    onMouseLeave={() => {
                      floatWindowRefs.current.miniCart.style.display = 'none';
                    }}
                    className="floatingWindow">
                    <MiniCart cart={cart} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Style.Content>

        <MenuPanel
          getInstance={inst => {
            menuPanelRef.current = inst;
          }}
        />
      </Style.Wrapper>
    </div>
  );
};

const Style = {
  Wrapper: styled.div`
    --wrapper-padding: 0px 50px;

    @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
      --wrapper-padding: 0px 35px;
    }
    @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
      --wrapper-padding: 0px 16px;
    }

    position: fixed;
    display: flex;
    align-items: center;
    top: ${props =>
      props.showScrollingText ? 'calc(var(--scroll-bar-height))' : '0'};
    left: 0;
    width: 100vw;
    padding: var(--wrapper-padding);
    height: var(--navbar-height); /* --navbar-height comes from Layout comp */
    z-index: 10;
    background: ${props => (props.showBackground ? '#fff' : 'transparent')};
    transition: background 200ms ease-in-out;
  `,

  Content: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;

    & > .container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      & > .left {
        display: flex;
        align-items: center;
        cursor: pointer;

        & > .menu-icon {
          display: flex;
          align-items: center;
        }

        & > .brand-logo {
          height: 56px;
          width: 200px;
        }
      }

      & > .center {
        width: 28px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      & > .right {
        display: flex;
        align-items: center;
        & > .right-item {
          height: 24px;
          cursor: pointer;
          margin-left: 36px;
          & > .profile,
          .cart {
            position: relative;
            display: flex;
            align-items: center;
            & > .cartItemsLength {
              width: 24px;
              height: 24px;
              margin-left: 4px;
            }
            & > .floatingWindow {
              display: none;
              flex-direction: column;
              align-items: center;
              position: absolute;
              top: 30px;
              right: 0;
              min-width: 160px;
              box-sizing: border-box;
              background-color: #fff;
              justify-content: flex-start;
              box-shadow: 0 0 5px rgba(20, 20, 20, 0.15);
              &::after {
                content: '';
                background-color: transparent;
                width: 100%;
                opacity: 0;
                height: 50px;
                position: absolute;
                top: -30px;
                left: 0;
              }
            }
          }

          & > .cart {
            & > .floatingWindow {
              ${props => (props.showMiniCart ? 'display: flex;' : '')}
            }
          }
        }
      }
    }

    @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
      & > .container {
        & > .center {
          visibility: hidden;
        }
        & > .right {
          visibility: hidden;
          & > .right-item {
          }
        }
      }
    }
  `,
};

export default AppContext.withConsumer(Navbar);
