import React, {useContext} from 'react';
import styled from 'styled-components';
import {Context} from '../Contexts/AppContext';
import {Color} from './Widget';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${Color.mainDark};
  color: ${Color.mainWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: -100%;
  transition: 1s ease bottom;
`;

const Toast = () => {
  const context = useContext(Context);

  return (
    <Wrapper style={{bottom: context.globalToastContent ? 0 : '-100%'}}>
      {context.globalToastContent}
    </Wrapper>
  );
};

export default Toast;
